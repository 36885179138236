import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.15331 1.52003C3.27654 1.05757 3.74926 0.783128 4.20917 0.907042L13.6465 3.44989C14.1064 3.5738 14.3793 4.04914 14.2561 4.5116L11.1324 16.2345C11.0092 16.697 10.5365 16.9715 10.0765 16.8475L6.224 15.8095H0.857142C0.383757 15.8095 0 15.4257 0 14.9523V2.95234C0 2.47896 0.379888 2.0952 0.853285 2.0952H1.42857C1.58637 2.0952 1.71428 2.22312 1.71428 2.38092C1.71428 2.53871 1.58637 2.66663 1.42857 2.66663H0.857142C0.699342 2.66663 0.571428 2.79454 0.571428 2.95234V14.9523C0.571428 15.1101 0.699342 15.2381 0.857142 15.2381H10.5714C10.7292 15.2381 10.8571 15.1101 10.8571 14.9523V5.23806H9.14285C8.66945 5.23806 8.28571 4.85431 8.28571 4.38091V2.66663H3.235C3.0556 2.66663 2.9206 2.50325 2.9544 2.32708L2.99888 2.0952L3.15331 1.52003Z"
      fill="currentColor"
    />
    <path
      d="M2.2857 6.66663C2.2857 6.50883 2.41362 6.38092 2.57142 6.38092H6.57142C6.72922 6.38092 6.85713 6.50883 6.85713 6.66663C6.85713 6.82443 6.72922 6.95234 6.57142 6.95234H2.57142C2.41362 6.95234 2.2857 6.82443 2.2857 6.66663Z"
      fill="currentColor"
    />
    <path
      d="M3.42856 8.95234C3.42856 8.79454 3.55647 8.66663 3.71427 8.66663H8.85713C9.01493 8.66663 9.14284 8.79454 9.14284 8.95234C9.14284 9.11014 9.01493 9.23806 8.85713 9.23806H3.71427C3.55647 9.23806 3.42856 9.11014 3.42856 8.95234Z"
      fill="currentColor"
    />
    <path
      d="M4.57141 11.2381C4.57141 11.0803 4.69932 10.9523 4.85712 10.9523H8.85712C9.01492 10.9523 9.14283 11.0803 9.14283 11.2381C9.14283 11.3959 9.01492 11.5238 8.85712 11.5238H4.85712C4.69932 11.5238 4.57141 11.3959 4.57141 11.2381Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
