import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2858 0H4.38085C3.92638 0.000537701 3.49069 0.181311 3.16933 0.502667C2.84798 0.824022 2.6672 1.25972 2.66667 1.71418V12.9524H0.394362C0.302661 12.9524 0.214041 12.9855 0.144771 13.0456C0.0755011 13.1056 0.0302302 13.1887 0.0172698 13.2795C0.00584299 13.3603 7.23237e-05 13.4417 0 13.5233C0.000376383 13.9779 0.181079 14.4137 0.502451 14.7352C0.823824 15.0567 1.25961 15.2375 1.71418 15.2381H11.6191C12.0737 15.2376 12.5094 15.0568 12.8308 14.7354C13.1522 14.414 13.3329 13.9782 13.3333 13.5237V2.28571H15.6054C15.6971 2.28575 15.7857 2.25271 15.855 2.19266C15.9243 2.1326 15.9696 2.04957 15.9825 1.95881C16.0175 1.71554 15.9998 1.4676 15.9306 1.23178C15.8614 0.995956 15.7423 0.777767 15.5814 0.592001C15.4205 0.406234 15.2215 0.25723 14.998 0.155089C14.7745 0.0529485 14.5316 5.70383e-05 14.2858 0ZM0.781003 13.7143H9.91553C9.94579 13.987 10.0414 14.2483 10.1943 14.4762H1.71418C1.49478 14.4757 1.28223 14.3997 1.11228 14.261C0.942335 14.1222 0.825349 13.9292 0.781003 13.7143ZM12.5714 1.71418V13.5237C12.5714 13.7763 12.4711 14.0185 12.2925 14.1971C12.1139 14.3757 11.8717 14.476 11.6191 14.476C11.3666 14.476 11.1244 14.3757 10.9458 14.1971C10.7672 14.0185 10.6669 13.7763 10.6669 13.5237C10.6669 13.4781 10.6701 13.4325 10.6764 13.3874C10.6842 13.3333 10.6802 13.2783 10.6648 13.2259C10.6494 13.1735 10.6229 13.125 10.5872 13.0838C10.5514 13.0425 10.5072 13.0095 10.4575 12.9868C10.4079 12.9641 10.3539 12.9524 10.2993 12.9524H3.42857V1.71418C3.42884 1.46171 3.52925 1.21965 3.70778 1.04112C3.88631 0.862589 4.12837 0.762173 4.38085 0.761904H12.8609C12.672 1.04351 12.5712 1.37504 12.5714 1.71418ZM13.3524 1.52381C13.3963 1.30865 13.5131 1.11526 13.6832 0.976369C13.8533 0.837475 14.0661 0.761607 14.2857 0.761607C14.5053 0.761607 14.7181 0.837475 14.8882 0.976369C15.0583 1.11526 15.1751 1.30865 15.219 1.52381H13.3524Z"
      fill="currentColor"
    />
    <path
      d="M6.73016 6.51418C6.86498 6.59308 7.01838 6.63467 7.1746 6.63467C7.33082 6.63467 7.48422 6.59308 7.61904 6.51418L9.65079 5.34126C9.78588 5.2632 9.89805 5.15097 9.97604 5.01584C10.054 4.88072 10.0951 4.72744 10.0951 4.57143C10.0951 4.41541 10.054 4.26214 9.97604 4.12701C9.89805 3.99188 9.78588 3.87965 9.65079 3.8016L7.61904 2.62867C7.48392 2.55068 7.33064 2.50963 7.17462 2.50964C7.0186 2.50966 6.86533 2.55074 6.73022 2.62875C6.59511 2.70677 6.48291 2.81897 6.4049 2.95409C6.32689 3.08921 6.28582 3.24248 6.28581 3.3985V5.74435C6.28482 5.90057 6.32546 6.05424 6.40355 6.18954C6.48165 6.32485 6.59438 6.4369 6.73016 6.51418ZM7.04772 3.3985C7.04725 3.37611 7.05291 3.35402 7.06408 3.33461C7.07525 3.31521 7.09151 3.29922 7.11111 3.28838C7.13046 3.27701 7.15246 3.27092 7.1749 3.2707C7.19716 3.27087 7.21898 3.27697 7.23809 3.28838L9.26984 4.46151C9.28911 4.47266 9.30512 4.48869 9.31625 4.50798C9.32738 4.52727 9.33324 4.54915 9.33324 4.57143C9.33324 4.5937 9.32738 4.61558 9.31625 4.63487C9.30512 4.65417 9.28911 4.67019 9.26984 4.68134L7.23809 5.85447C7.21878 5.8656 7.19688 5.87145 7.17459 5.87143C7.1523 5.87141 7.13041 5.86552 7.11112 5.85436C7.09183 5.8432 7.07581 5.82716 7.06468 5.80785C7.05355 5.78854 7.0477 5.76664 7.04772 5.74435V3.3985Z"
      fill="currentColor"
    />
    <path
      d="M5.33332 8.38095H10.6667C10.7677 8.38095 10.8646 8.34081 10.936 8.26937C11.0075 8.19793 11.0476 8.10103 11.0476 8C11.0476 7.89896 11.0075 7.80207 10.936 7.73062C10.8646 7.65918 10.7677 7.61905 10.6667 7.61905H5.33332C5.23229 7.61905 5.13539 7.65918 5.06395 7.73062C4.99251 7.80207 4.95237 7.89896 4.95237 8C4.95237 8.10103 4.99251 8.19793 5.06395 8.26937C5.13539 8.34081 5.23229 8.38095 5.33332 8.38095Z"
      fill="currentColor"
    />
    <path
      d="M5.33332 9.90476H10.6667C10.7677 9.90476 10.8646 9.86462 10.936 9.79318C11.0075 9.72174 11.0476 9.62484 11.0476 9.5238C11.0476 9.42277 11.0075 9.32587 10.936 9.25443C10.8646 9.18299 10.7677 9.14285 10.6667 9.14285H5.33332C5.23229 9.14285 5.13539 9.18299 5.06395 9.25443C4.99251 9.32587 4.95237 9.42277 4.95237 9.5238C4.95237 9.62484 4.99251 9.72174 5.06395 9.79318C5.13539 9.86462 5.23229 9.90476 5.33332 9.90476Z"
      fill="currentColor"
    />
    <path
      d="M5.33332 11.4286H10.6667C10.7677 11.4286 10.8646 11.3884 10.936 11.317C11.0075 11.2455 11.0476 11.1486 11.0476 11.0476C11.0476 10.9466 11.0075 10.8497 10.936 10.7782C10.8646 10.7068 10.7677 10.6667 10.6667 10.6667H5.33332C5.23229 10.6667 5.13539 10.7068 5.06395 10.7782C4.99251 10.8497 4.95237 10.9466 4.95237 11.0476C4.95237 11.1486 4.99251 11.2455 5.06395 11.317C5.13539 11.3884 5.23229 11.4286 5.33332 11.4286Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
