import React, { useEffect } from "react";
import * as d3 from "d3";

const GanttChart = ({ stylingMap, taskArray }) => {
  const w = 1000;
  const h = 500;

  const timeFormat = d3.timeParse("%H:%M");
  const timeFormatDisplay = d3.timeFormat("%H:%M");
  const timeTicks = [
    ...new Set(taskArray.flatMap((d) => [d.startTime, d.endTime])),
  ].map(timeFormat);
  const timeScale = d3
    .scaleTime()
    .domain([d3.min(timeTicks), d3.max(timeTicks)])
    .range([0, w - 150]);

  useEffect(() => {
    // Clear any existing SVG content in the container before creating a new one
    const svgContainer = d3.select(".svg");
    svgContainer.selectAll("*").remove(); // This will remove all children of the .svg div

    const svg = svgContainer
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .attr("class", "svg");

    const topPadding = 75;
    const sidePadding = 75;
    const barHeight = 20;
    const gap = barHeight + 2;

    makeGrid(svg, sidePadding, topPadding, w, h);
    drawRects(
      svg,
      taskArray,
      gap,
      topPadding,
      sidePadding,
      barHeight,
      stylingMap,
      w,
      h
    );

    // Create the vertical line that follows the mouse
    const verticalLine = svg
      .append("line")
      .attr("stroke", "#8a8a8a")
      .attr("stroke-width", 0.15)
      .attr("y1", 0)
      .attr("y2", h)
      .style("visibility", "hidden"); // Initially hidden

    // Create text for displaying the time
    const timeText = svg
      .append("text")
      .attr("x", 0) // Initially set x to 0
      .attr("y", 10) // Set y slightly above the line
      .attr("font-size", 12)
      .attr("fill", "#686868FF")
      .style("visibility", "hidden"); // Initially hidden

    // Mousemove event to update the vertical line position
    svg.on("mousemove", function (event) {
      const mouseX = d3.pointer(event)[0]; // Get the X position of the mouse
      if (mouseX >= sidePadding && mouseX <= w - 50) {
        // Check if the mouse is within chart bounds
        verticalLine
          .attr("x1", mouseX)
          .attr("x2", mouseX)
          .style("visibility", "visible"); // Show the vertical line when hovering within bounds

        // Update the time text next to the vertical line
        const timeAtMouse = timeScale.invert(mouseX - sidePadding); // Get the time corresponding to mouse X position
        timeText
          .text(timeFormatDisplay(timeAtMouse)) // Set the text to the corresponding time
          .attr("x", mouseX + 10) // Position text slightly to the right of the line
          .attr("y", 10) // Keep text just above the line
          .style("visibility", "visible"); // Show the time text
      } else {
        verticalLine.style("visibility", "hidden"); // Hide the line if outside bounds
        timeText.style("visibility", "hidden"); // Hide the time text if outside bounds
      }
    });
  }, [stylingMap, taskArray]);

  const makeGrid = (svg, sidePadding, topPadding, w, h) => {
    const xAxis = d3
      .axisBottom(timeScale)
      .tickValues(timeTicks)
      .tickSize(-h + topPadding + 20)
      .tickFormat(d3.timeFormat("%H:%M"));

    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${sidePadding}, ${h - 50})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "middle")
      .attr("fill", "#767676")
      .attr("stroke", "none")
      .attr("font-size", 10)
      .attr("dy", "1em");

    svg.selectAll(".grid line").attr("stroke", "#eee");
  };

  const drawRects = (
    svg,
    taskArray,
    gap,
    topPadding,
    sidePadding,
    barHeight,
    stylingMap,
    w,
    h
  ) => {
    const rectangles = svg
      .append("g")
      .selectAll("rect")
      .data(taskArray)
      .enter();

    // Inner bars
    rectangles
      .append("rect")
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("x", (d) => timeScale(timeFormat(d.startTime)) + sidePadding)
      .attr("y", (d, i) => i * gap + topPadding)
      .attr(
        "width",
        (d) =>
          timeScale(timeFormat(d.endTime)) - timeScale(timeFormat(d.startTime))
      )
      .attr("height", barHeight)
      .attr("fill", (d) => stylingMap[d.type]["background"]) // Use color based on type
      .attr("stroke", (d) => stylingMap[d.type]["stroke"] || "none") // Set stroke color (if available)
      .attr("stroke-width", 1) // Set stroke width
      .attr("stroke-dasharray", "3,2"); // Define dashed stroke pattern

    // Task labels
    rectangles
      .append("text")
      .text((d) => d.label)
      .attr(
        "x",
        (d) =>
          (timeScale(timeFormat(d.endTime)) -
            timeScale(timeFormat(d.startTime))) /
            2 +
          timeScale(timeFormat(d.startTime)) +
          sidePadding
      )
      .attr("y", (d, i) => i * gap + 14 + topPadding)
      .attr("font-size", 11)
      .attr("text-anchor", "middle")
      .attr("fill", (d) => stylingMap[d.type]["color"]);
  };

  return <div className="svg"></div>;
};

export default GanttChart;
