import React from "react";
import * as Styled from "./styled";
import EventItem from "../EventItem";
import { findParentByClass } from "../../../../../utility/common";
import EventPopover from "../EventPopover";
import { useCalendar } from "../../hooks/useCalendar";

// const data = [
//   {
//     title: "Fine cut 02",
//     start: new Date(2024, 2, 12, 10, 30, 0),
//     end: new Date(2024, 2, 12, 13, 0, 0),
//   },
//   {
//     title: "Fine cut 03",
//     start: new Date(2024, 3, 3, 18, 30, 0),
//     end: new Date(2024, 3, 6, 8, 30, 0),
//   },
// ];

function TimeBlocks(props) {
  const { currentHours, isScrolling, eventBodyRef } = props;
  const containerRef = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    currentSelectDate,
    isDisabled,
    currentTimeSlots,
    onEventSelect,
    selectTimeHandler,
  } = useCalendar();

  // Hide Popover
  // React.useEffect(() => {
  //   const onClick = (e) => {
  //     const eventElement = findParentByClass(e.target, "event__item");
  //     const eventPopoverElement = findParentByClass(e.target, "event__popover");

  //     if (!eventElement && !eventPopoverElement) {
  //       setAnchorEl(null);
  //     }
  //   };

  //   document.addEventListener("click", onClick);

  //   return () => {
  //     document.removeEventListener("click", onClick);
  //   };
  // }, []);

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // Temporarily disable

  React.useEffect(() => {
    if (!eventBodyRef || !currentHours) return;

    const element = eventBodyRef?.querySelector(
      `[data-index='${currentHours}']`
    );

    const h = eventBodyRef.getBoundingClientRect().height;

    if (h > element?.offsetTop) {
      return;
    }

    const targetPosition = element.offsetTop - h * 0.4;

    eventBodyRef.scroll({
      top: targetPosition,
    });
  }, [currentHours, currentSelectDate, eventBodyRef]);

  React.useEffect(() => {
    if (isScrolling) {
      setAnchorEl(null);
    }
  }, [isScrolling]);

  const events = React.useMemo(() => {
    if (!currentTimeSlots?.timeSlots?.length) {
      return [];
    }

    return currentTimeSlots?.timeSlots?.map((d) => {
      const start = new Date(`${d?.startDate} ${d?.startTime}`);
      const end = new Date(`${d?.endDate} ${d?.endTime}`);
      return {
        ...d,
        start,
        end,
      };
    });
  }, [currentTimeSlots?.timeSlots]);

  return (
    <Styled.TimeBlockWrapper
      className="time__container"
      ref={containerRef}
      $isDisabled={isDisabled}
    >
      <div className="time__blocks">
        {new Array(24).fill("").map((_, i) => {
          let time = `${String(i).padStart(2, "0")}:00`;

          return (
            <Styled.TimeBlock
              className="time__block"
              key={i}
              $isCurrentTime={currentHours === i}
              data-index={i}
              $isDisabled={isDisabled}
              onClick={(e) => {
                if (isDisabled) return;
                selectTimeHandler({ time, date: currentSelectDate });
              }}
            >
              <span>{time}</span>
            </Styled.TimeBlock>
          );
        })}
      </div>

      <Styled.EventItems className="event__items">
        {events?.map((d, i) => {
          return (
            <EventItem
              key={i}
              data={d}
              onClick={(e) => {
                if (isDisabled) return;
                onEventSelect(d);
                setAnchorEl(findParentByClass(e.target, "event__item"));
              }}
            />
          );
        })}
      </Styled.EventItems>

      {/* Event detail popover */}
      <EventPopover
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          onEventSelect(null);
        }}
      />
    </Styled.TimeBlockWrapper>
  );
}

export default TimeBlocks;
