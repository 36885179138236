import React from "react";
import { useCalendar } from "../hooks/useCalendar";
import { format } from "date-fns";
import { Wrapper } from "./styled";

const Preview = () => {
  const { calendarDates } = useCalendar();
  console.log(calendarDates);
  return (
    <Wrapper>
      <div className="blocks">
        {calendarDates?.map((d, i) => {
          return (
            <div className="blocks__item" key={i}>
              <div className="blocks__title">
                {format(new Date(d?.startDate), "dd MMM yyyy")}
              </div>
              <div className="blocks__slots">
                {d?.timeSlots.map((t, key) => {
                  return (
                    <div className="slot-item" key={key}>
                      <div className="slot-item__title">{t?.title}</div>
                      <div className="slot-item__time">
                        <span>
                          {format(
                            new Date(`${t?.startDate} ${t?.startTime}`),
                            "hh:mmaaa"
                          )}
                        </span>

                        <span>
                          {format(
                            new Date(`${t?.endDate} ${t?.endTime}`),
                            "hh:mmaaa"
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Preview;
