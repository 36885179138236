import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";

export const Wrapper = styled.div`
  .draggable {
    &__list {
      display: grid;
      position: relative;
      height: 350px;
      overflow-y: auto;
      grid-template-rows: 0fr;
      align-items: flex-end;
      align-content: flex-start;
    }

    &__item {
      display: flex;
      gap: var(--layout-spacing-16);
      border-radius: var(--layout-border-radius-4);
      background-color: var(--font-default-white);
      padding: var(--layout-spacing-12) var(--layout-spacing-8);
      margin-bottom: var(--layout-spacing-8);

      &__order {
        width: var(--layout-size-28);
        height: var(--layout-size-28);
        min-width: var(--layout-size-28);
        min-height: var(--layout-size-28);

        .order-number {
          border-radius: 999px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--font-default-white);
          background-color: var(--color-secondary-200);
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          height: inherit;
          width: inherit;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-16);
        ${fontStyle("base-medium")};
        color: var(--color-neutral-900);
        flex: 1;
        font-weight: 500;

        .icon__button__element {
          --icon-button-icon-color: var(--color-neutral-900);
        }
      }

      &__handler {
        .icon__button__element {
          --icon-button-icon-color: var(--font-tint-04);
        }
      }
    }
  }
`;
