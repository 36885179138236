import React from "react";
import { useDayPicker } from "react-day-picker";
import { setYear, startOfMonth, startOfYear } from "date-fns";

import * as Styled from "../styled";

const Years = (props) => {
  const { displayMonth } = props;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const swiperRef = React.useRef();

  const {
    fromDate,
    toDate,
    locale,
    today,
    formatters: { formatYearCaption },
  } = useDayPicker();

  const [currentYear, setCurrentDate] = React.useState(displayMonth || today);

  const onSwiper = (swipe) => {
    swiperRef.current = swipe;

    var element = document.querySelector(
      `div[data-value='${currentYear.getFullYear()}']`
    );

    if (element) {
      setActiveIndex(element.getAttribute("data-index") || 0);
    }
  };

  if (!fromDate) return <></>;

  if (!toDate) return <></>;

  let years = [];

  const fromYear = fromDate.getFullYear();
  const toYear = toDate.getFullYear();

  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year));
  }

  const onChange = (value) => {
    const newMonth = setYear(startOfMonth(displayMonth), Number(value));
    props.onChange(newMonth);
    setCurrentDate(newMonth);

    setTimeout(() => {
      swiperRef.current.update();
    }, 1);
  };

  return (
    <Styled.YearSlider
      centeredSlides={false}
      onSwiperCallback={onSwiper}
      slideTo={activeIndex}
      slidesPerView={4}
      slidesPerGroupAuto={true}
      slidesPerGroup={2}
    >
      {years.map((year, i) => {
        return (
          <Styled.YearSlider.Item
            key={year.getFullYear()}
            data-value={year.getFullYear()}
            data-index={i}
          >
            <div
              className={[
                "slide__item year__item",
                currentYear.getFullYear() === year.getFullYear()
                  ? "selected"
                  : null,
              ].join(" ")}
              role="button"
              onClick={() => onChange(year.getFullYear())}
            >
              {formatYearCaption(year, { locale })}
            </div>
          </Styled.YearSlider.Item>
        );
      })}
    </Styled.YearSlider>
  );
};

export default Years;
