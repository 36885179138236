import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.000189209 13.0519C0.000189209 14.6751 1.32609 16 2.94831 16H5.11974C5.30277 16 5.43937 15.8625 5.43937 15.6804C5.43937 15.4973 5.30187 15.3607 5.11974 15.3607H2.94831C1.66794 15.3607 0.639276 14.3322 0.639276 13.0517L0.640168 2.94815C0.640168 1.66778 1.66874 0.639117 2.9492 0.639117H8.29732V3.10792C8.29732 3.79363 8.86875 4.36506 9.55446 4.36506L12.0454 4.36596V6.39988C12.0454 6.58291 12.1829 6.71951 12.3651 6.71951C12.5481 6.71951 12.6847 6.58201 12.6847 6.39988L12.6856 4.06868C12.6856 3.9776 12.6401 3.90885 12.5945 3.8401L8.86858 0.0910722C8.79983 0.0223224 8.73108 0 8.64001 0H2.94812C1.32489 0 0 1.3259 0 2.94812L0.000189209 13.0519Z"
      fill="currentColor"
    />
    <path
      d="M11.8404 8.59473L6.92614 13.4857C6.85739 13.5545 6.83507 13.6232 6.83507 13.7143L6.81186 15.5429C6.81186 15.6339 6.85739 15.7259 6.90293 15.7714C6.97168 15.8402 7.04043 15.8625 7.1315 15.8625L8.93685 15.8393C9.02792 15.8393 9.09667 15.7937 9.16542 15.7482L14.103 10.8339L12.32 9.05088L11.8404 8.59473Z"
      fill="currentColor"
    />
    <path
      d="M14.3314 10.1259L14.56 10.3545L15.4055 9.50898C16.0225 8.89202 16.0225 7.88575 15.4055 7.26879C14.7885 6.65183 13.7823 6.65183 13.143 7.26879L12.2975 8.11433L14.0805 9.89737L14.3314 10.1259Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
