import API_ENDPOINTS from "../../../../services/api-endpoints";
import API_Research from "../../../../services/api-research";
import { TagModel } from "../../models/TagModel/TagModel";

export const tagSearch = (payload) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    const searchValue = payload.searchValue || "";

    API_Research.get(
      `${endpoints.RESEARCH_TAG_SEARCH}?searchText=${searchValue}`
    )
      .then((res) => {
        console.log("search data ", res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
};

export const getTagTypes = () => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.get(`${endpoints.GET_TAG_TYPES}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve([]);
          return;
        }

        let _result = res?.data?.result.map((tag) => {
          const _tag = new TagModel();
          _tag.id(tag.tagTypeId);
          _tag.name(tag.tagTypeName);
          _tag.pluralName(tag.tagTypePluralName);
          _tag.order(tag.order);
          _tag.icon(tag.icon);
          return _tag;
        });

        resolve(_result);
      })
      .catch((err) => {
        resolve([]);
      });
  });
};

export const getTagDataByTagId = (tagId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.get(`${endpoints.GET_TAG_FORM_DATA_BY_TAG_ID}?tagId=${tagId}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve(null);
          return;
        }

        const result = res?.data?.result || null;

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTagRelationshipByParentId = (parentId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.get(`${endpoints.GET_TAG_RELATIONSHIPS_BY_PARENT_ID}/${parentId}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve(null);
          return;
        }

        const result = res?.data?.result || null;

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTagsByProjectId = (projectId) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.get(`${endpoints.GET_TAGS_BY_PROJECT}/${projectId}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve([]);
          return;
        }

        const result = res?.data?.result || [];
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSeedsDataBySeedIds = (tagIds) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    API_Research.post(`${endpoints.GET_TAGS_BY_IDS}`, tagIds)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve([]);
          return;
        }

        const result = res?.data?.result || [];

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSeedsSearchData = (projectId, tagTypeId, text) => {
  const endpoints = new API_ENDPOINTS();

  return new Promise((resolve, reject) => {
    let uri = `${endpoints.GET_TAGS_SEARCH}`;

    uri = `${uri}?projectId=${projectId}&tagTypeId=${tagTypeId}&searchText=${text}`;

    API_Research.get(`${uri}`)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          resolve(undefined);
          return;
        }

        const result = res?.data?.result;

        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
