import {
  differenceInMinutes,
  format,
  isSameDay,
  isValid,
  isWithinInterval,
} from "date-fns";

const minutesFor24H = 60 * 24;

const timeConverter = (date = new Date(), h = 0, m = 0) => {
  const today = new Date(date);
  today.setHours(h);
  today.setMinutes(m);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return new Date(today);
};

function getStyles(start, end) {
  const top = Math.abs((start / minutesFor24H) * 100);
  const height = Math.abs((end / minutesFor24H) * 100);
  return { top, height };
}

export function getEventPotions(start, end, currentDate) {
  //   const today = timeConverter();

  const isStartDateSameToCurrent = isSameDay(start, currentDate);
  const isEndDateSameToCurrent = isSameDay(end, currentDate);

  // Both dates are matched to current selected date
  if (isStartDateSameToCurrent && isEndDateSameToCurrent) {
    const currentStartTime = timeConverter(currentDate);
    const diffFromCurrentDate = differenceInMinutes(start, currentStartTime);
    const minDiffStartAndEnd = differenceInMinutes(end, start);

    return getStyles(diffFromCurrentDate, minDiffStartAndEnd);
  }

  // Only start date match to current selected date
  if (isStartDateSameToCurrent && !isEndDateSameToCurrent) {
    const currentStartTime = timeConverter(currentDate);
    const currentDateTimeEnd = timeConverter(currentDate, 23, 59);

    const diffFromCurrentDate = differenceInMinutes(start, currentStartTime);
    const minDiffStartAndEnd = differenceInMinutes(currentDateTimeEnd, start);

    return getStyles(diffFromCurrentDate, minDiffStartAndEnd);
  }

  // Only end date match to current selected date
  if (!isStartDateSameToCurrent && isEndDateSameToCurrent) {
    const currentStartTime = timeConverter(currentDate, 0, 0);

    const minDiffStartAndEnd = differenceInMinutes(end, currentStartTime);

    return getStyles(0, minDiffStartAndEnd);
  }

  // Both dates not match to current selected date, it's a middle date
  if (isWithinInterval(currentDate, { start, end })) {
    const currentTimeStart = timeConverter(currentDate, 0, 0);
    const currentTimeEnd = timeConverter(currentDate, 23, 59);
    const minDiffStartAndEnd = differenceInMinutes(
      currentTimeEnd,
      currentTimeStart
    );
    return getStyles(0, minDiffStartAndEnd);
  }

  console.log("isStartDateSameToCurrent", isStartDateSameToCurrent);
  console.log("isEndDateSameToCurrent", isEndDateSameToCurrent);
}

export const displayTime = (start, end) => {
  if (!start || !end || !isValid(start) || !isValid(end)) return null;

  const f = (fType = "hh:mmaaa") => {
    return `${format(start, fType)}  - ${format(end, fType)}`;
  };

  let times = f();

  if (!isSameDay(start, end)) {
    times = f("dd(hh:mmaaa)");
  }

  return times;
};
