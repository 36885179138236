export const MemberType = {
  Unassigned: 0,
  Crew: 1,
  ProductionOffice: 2,
  Talent: 3,
};
export const TravelMode = [
  { label: "None", value: 0 },
  { label: "Airplane", value: 1 },
  { label: "Ship", value: 2 },
];
export const YesNo = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];
export const MapActions = {
  None: 0,
  Select: 1,
  Deselect: 2,
};
