import styled from "styled-components";
import DatePicker from "../../../../common/components/DatePicker";

export const DayPicker = styled(DatePicker).attrs({ className: "event-cld" })`
  &.event-cld {
    .rdp-day {
      border-radius: 0;
    }

    .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
      color: var(--color-primary-300) !important;
    }
  }
`;
