import React from "react";

import * as Styled from "./styled";
import useFiles from "../../../../hooks/useFiles";
import { IconBlock } from "../../../../styled/Common/Icons";
import { Image } from "../../icons/Line";
import { uploadImage } from "../../../../Actions/Common";
import Typography from "../../Typography";
import CropModal from "../CropModal";
import IconButton from "../../Buttons/IconButton";
import DeleteIcon from "../../icons/Line/Delete/01";

export const ConfirmationTypes = {
  Confirm: 1,
  Cancel: 0,
};

const FileDropZone = ({
  outerClassName,
  name,
  allowTypes = ["image/png", "image/jpeg", "image/tiff", "image/x-tiff"],
  allowedTypesText = "SVG, PNG, JPG, TIFF, GIF (max 800px x 800px)",
  onFileUpload,
  autoServerUpload = false,
  containerName,
  disabled,
  onUploadWithConfirmation,
}) => {
  const inputRef = React.useRef(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(undefined);
  const [cropSrc, setCropSrc] = React.useState(null);
  const [showCropModal, setShowCropModal] = React.useState(false);
  const inputName = "file__input";
  const onServerUpload = React.useCallback(
    async (files = []) => {
      if (disabled) return;

      if (!files || files.length === 0) return;
      const allowedImgTypes = [
        "image/png",
        "image/jpeg",
        "image/tiff",
        "image/x-tiff",
      ];
      const invalidImgFiles = files.filter(
        (file) => !allowedImgTypes.includes(file.file.type)
      );

      const fileData = files?.map((f) => f.fileData);
      const fileName = files?.map((f) => f.file?.name);
      const fileType = files?.map((f) => f.file?.type);

      const file = {
        containerName: containerName
          ? containerName
          : process.env.REACT_APP_DOCUMENTS_CONTAINER,
        imageData: fileData,
        imageName: fileName,
        imageType: fileType,
      };

      try {
        const config = {
          onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setProgress(percentCompleted);
          },
        };

        setIsUploading(true);
        const uploadRes = await uploadImage(file, config);

        if (uploadRes.isSuccess) {
          const filePaths = uploadRes?.result.map((f) => f?.filePath);

          if (invalidImgFiles.length > 0) {
            onFileUpload && onFileUpload(uploadRes?.result, filePaths);
          } else {
            setCropSrc(filePaths[0]);
            setShowCropModal(true);
          }
        }
      } catch (error) {
        setIsUploading(false);
        setProgress(undefined);
        console.error("File Upload ", error);
      }
    },
    [containerName, disabled, onFileUpload]
  );

  React.useEffect(() => {
    if (progress === 100) {
      let time = setTimeout(() => {
        setIsUploading(false);
        setProgress(undefined);
      }, 500);

      return () => {
        clearTimeout(time);
      };
    }
  }, [progress]);

  const onUpload = (files) => {
    if (disabled) return;

    if (!autoServerUpload) {
      onFileUpload && onFileUpload(files);
      return;
    }

    onServerUpload(files);
  };

  const croppedBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleSaveCroppedImage = async (croppedBlob) => {
    if (!(croppedBlob instanceof Blob)) {
      console.error("Cropped data is not a Blob");
      return;
    }
    const croppedFile = new File([croppedBlob], "processed_image.jpg", {
      type: "image/jpeg",
    });

    const croppedFileData = {
      containerName: "loop-processed-images",
      imageData: [await croppedBlobToBase64(croppedBlob)], // cnvrt blob to base64
      imageName: [croppedFile.name],
      imageType: [croppedFile.type],
    };

    const configs = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    };

    try {
      const uploadRes = await uploadImage(croppedFileData, configs);
      const filePaths = uploadRes?.result.map((f) => f?.filePath);

      console.log("Processed Image filePath : ", filePaths);
      onFileUpload && onFileUpload(uploadRes?.result, filePaths);
    } catch (error) {
      console.error("Error during cropped image upload:", error);
    }
  };

  const closeModal = () => {
    setShowCropModal(false);
    setCropSrc(null);
  };

  const uploader = useFiles({
    name: name,
    onFileUpload: onUpload,
    allowTypes,
    isUploading,
  });

  const {
    // uploadedFiles,
    error,
    handleChange,
    handleDrag,
    handleDrop,
    isDragOver,
    isDragStart,
    isDrop,
  } = uploader;

  const onButtonClick = () => {
    if (disabled || isUploading) return;

    inputRef.current.click();
  };

  return (
    <>
      <Styled.UploaderOuter className={outerClassName}>
        {showCropModal && cropSrc && (
          <CropModal
            src={cropSrc}
            isOpen={showCropModal}
            onSave={handleSaveCroppedImage}
            onClose={closeModal}
          />
        )}

        <Styled.Uploader
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          isDragStart={isDragStart}
          isDragOver={isDragOver}
          isDrop={isDrop}
          onClick={onButtonClick}
          isDisabled={disabled}
          isUploading={isUploading}
        >
          {/* Loading indicator */}
          {isUploading ? (
            <div className="loader">
              {/* <AnimateLoader /> */}
              <div className="text">Uploading...</div>
              {progress ? (
                <div className="progress__bar">
                  <div
                    className="progress__value"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="icon__wrapper">
            <IconBlock className="icon__block">
              <Image />
            </IconBlock>
          </div>

          <Typography
            className="text"
            variant={Typography.font_variant.xSmall}
            fontWeight={Typography.font_weight.bold}
          >
            Click to upload&nbsp;
            <Typography
              as="span"
              variant={Typography.font_variant.xSmall}
              fontWeight={Typography.font_weight.regular}
              className="text__black"
            >
              or drag and drop
            </Typography>
          </Typography>

          <Typography
            className="text text__file__types"
            variant={Typography.font_variant.xSmall}
            fontWeight={Typography.font_weight.regular}
          >
            {allowedTypesText}
          </Typography>

          <input
            ref={inputRef}
            className="file__input"
            type="file"
            name={inputName}
            onChange={handleChange}
            accept={allowTypes.join(",")}
            disabled={disabled}
          />
        </Styled.Uploader>

        {error ? <Styled.Error>{error}</Styled.Error> : null}

        <div className="preview__files"></div>
      </Styled.UploaderOuter>
    </>
  );
};

FileDropZone.PreviewWrapper = function ({ title, children }) {
  const hasChild = React.Children.toArray(children).some(
    ({ props }) => !!props?.link
  );

  if (!hasChild) return null;

  return (
    <Styled.PreviewWrapper>
      <div className="heading">
        <div className="title">{title}</div>
      </div>
      <div className="content">{children}</div>
    </Styled.PreviewWrapper>
  );
};

FileDropZone.PreviewLink = function ({ link, label, onDelete }) {
  if (!link) return null;

  return (
    <Styled.LinkView>
      <div className="start">
        <a href={link} target="_blank" rel="noreferrer" title={label}>
          <span> {label}</span>
        </a>
      </div>
      {onDelete ? (
        <div className="end">
          <IconButton icon={<DeleteIcon />} btnSize={20} onClick={onDelete} />
        </div>
      ) : null}
    </Styled.LinkView>
  );
};

export default FileDropZone;
