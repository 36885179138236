import React from "react";
import * as Styled from "./table.styled";
import IconButton from "./../../../../../common/components/Buttons/IconButton";
import IconLock from "./../../../../../common/components/icons/Line/Locked/03";
import IconEdit from "./../../../../../common/components/icons/Line/Edit/01";
import IconRemove from "./../../../../../common/components/icons/Line/Cancel/01";
import IconDuplicate from "./../../../../../common/components/icons/Line/Page/02";
import IconUpdate from "./../../../../../common/components/icons/Line/Update/01";
import IconArrowDown from "./../../../../../common/components/icons/Fill/arrow-down";
import IconCorrect from "./../../../../../common/components/icons/Fill/correct";
import IconClose from "./../../../../../common/components/icons/Line/Close/01";
import IconChipClose from "./../../../../../common/components/icons/Line/Close/01";
import IconLineArrowDown from "./../../../../../common/components/icons/Line/arrow-down";
import IconLineArrowUp from "./../../../../../common/components/icons/Line/arrow-up";
import IconAdd01 from "./../../../../../common/components/icons/Line/add-1";
import IconView from "./../../../../../common/components/icons/Line/View/01";
import IconBin from "./../../../../../common/components/icons/Line/Delete/01";
import IconCancel from "./../../../../../common/components/icons/Line/Cancel/02";
import * as BaseTable from "../../../../../common/components/UI/BaseTable";

export const ElementStatusType = {
  NaN: -1,
  Active: 1,
  Deactivate: 0,
};

function Table(props) {
  const { children, wrapperClassName, isRowPacked, isLoading, ...otherProps } =
    props;

  return (
    <Styled.TableWrapper className={`table__wrapper ${wrapperClassName}`}>
      <Styled.Table $isRowPacked={isRowPacked} {...otherProps}>
        {children}
      </Styled.Table>
    </Styled.TableWrapper>
  );
}

Table.Head = BaseTable.Head;
Table.Body = BaseTable.Body;
Table.TR = BaseTable.TR;
Table.TH = BaseTable.TH;
Table.TD = BaseTable.TD;

const Status = ({ type = ElementStatusType.NaN }) => {
  const statusLabel = React.useMemo(() => {
    if (type === ElementStatusType.Active) return `Activated`;
    if (type === ElementStatusType.Deactivate) return `Deactivated`;

    return "Disabled";
  }, [type]);

  return (
    <Styled.Status $type={type}>
      <span></span>
      <span>{statusLabel}</span>
    </Styled.Status>
  );
};

const ActionButton = ({ type, iconSize = 12, boxSize = 16, ...otherProps }) => {
  const iconComponent = React.useMemo(() => {
    switch (type) {
      case "edit":
        return <IconEdit />;

      case "remove":
        return <IconRemove />;

      case "duplicate":
        return <IconDuplicate />;

      case "setting":
        return <IconLock />;

      case "update":
        return <IconUpdate />;

      case "delete":
        return <IconBin />;

      case "view":
        return <IconView />;

      case "add":
        return <IconAdd01 />;

      default:
        return null;
    }
  }, [type]);

  return (
    <IconButton
      type="button"
      btnSize={boxSize}
      btnIconSize={iconSize}
      icon={iconComponent}
      className="table__action__btn"
      {...otherProps}
    />
  );
};

const ToggleActionButton = ({ type, isActive, onClick, ...otherProps }) => {
  const iconComponent = React.useMemo(() => {
    if (type === "correct") {
      return <IconCorrect />;
    }

    if (type === "close") {
      return <IconClose />;
    }

    if (type === "cancel") {
      return <IconCancel />;
    }

    return null;
  }, [type]);

  return (
    <Styled.ToggleButton
      $noAction={!onClick}
      $active={isActive}
      $type={type}
      btnSize="16"
      btnIconSize="12"
      icon={iconComponent}
      className="table__toggle__btn"
      onClick={onClick}
      {...otherProps}
    />
  );
};

const Actions = ({ children, gap = 4 }) => {
  return (
    <Styled.Actions className="table__actions" $gap={gap}>
      {children}
    </Styled.Actions>
  );
};

const RowExpandButton = ({ isExpanded, ...otherProps }) => {
  const expandedClz = isExpanded ? "expanded" : "collapsed";

  return (
    <IconButton
      btnSize="16"
      btnIconSize="12"
      icon={<IconArrowDown />}
      className={`table__row__expander ${expandedClz}`}
      {...otherProps}
    />
  );
};

const Gap = ({ depth }) => {
  return <Styled.GapBlock $depth={depth} />;
};

const SortableIcon = ({ ...otherProps }) => {
  return (
    <span className="sort__icons" {...otherProps}>
      <IconButton
        as="span"
        btnSize="8"
        btnIconSize="8"
        icon={<IconLineArrowUp />}
        className={`arrow-up`}
      />
      <IconButton
        as="span"
        btnSize="8"
        btnIconSize="8"
        icon={<IconLineArrowDown />}
        className={`arrow-down`}
      />
    </span>
  );
};

const Chip = (props) => {
  const { label, onDelete, canRemove, ...otherProps } = props;
  return (
    <Styled.ChipItem {...otherProps}>
      <div className="val">{label}</div>
      {canRemove ? (
        <IconButton
          className="close__btn"
          icon={<IconChipClose />}
          btnIconSize={4}
          btnSize={16}
          onClick={onDelete}
        ></IconButton>
      ) : null}
    </Styled.ChipItem>
  );
};

const ChipItems = (props) => {
  const { children, ...otherProps } = props;
  return <Styled.ChipItems {...otherProps}>{children}</Styled.ChipItems>;
};

const TextButton = (props) => {
  const { label, type = "accent", ...otherProps } = props;

  const text = React.useMemo(() => {
    if (label) return label;

    if (type === "secondary") {
      return "Deny All";
    }

    if (type === "accent") {
      return "Grant All";
    }

    return "";
  }, [label, type]);

  return (
    <Styled.TextButton $type={type} {...otherProps}>
      {text}
    </Styled.TextButton>
  );
};

const Link = (props) => {
  const { label, href, ...otherProps } = props;

  return (
    <Styled.Link href={href} target="_blank" rel="noreferrer" {...otherProps}>
      <span>{label || href}</span>
    </Styled.Link>
  );
};

Table.Elements = {
  Status,
  StatusType: ElementStatusType,
  Actions,
  ActionButton,
  RowExpandButton,
  ToggleActionButton,
  SortableIcon,
  Depth: Gap,
  Chip,
  ChipItems,
  TextButton,
  Link,
};

export default Table;
