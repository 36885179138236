import React, { Component } from "react";
import * as ReactDOM from "react-dom";

import {
  HierarchicalTree,
  DataBinding,
  DiagramComponent,
  Inject,
  DiagramTools,
  NodeConstraints,
  ConnectorConstraints,
} from "@syncfusion/ej2-react-diagrams";
import { registerLicense } from "@syncfusion/ej2-base";

import "/node_modules/@syncfusion/ej2-diagrams/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-base/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-popups/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-lists/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-navigations/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css";
//import "/node_modules/@syncfusion/ej2-react-documenteditor/styles/fabric.css";

import "../../assets/css/fabric.css";
import "../../assets/css/diagram_node.css";

import { CrewPlannerBase } from "./crew-planner-base";

import crewImage from "../../assets/img/user.01937c96.png";

import { DataManager } from "@syncfusion/ej2-data";
import { species } from "./diagram-data";
import Container from "../../ui-components/Container";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { CREW_PERMISSION_MODULES } from "../../../constant/crew_permission_module";
import { Permission } from "../../../helpers/permission";
import withRouteLoadData from "../../../hoc/withRouteLoadData";

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

let diagramInstance;
var template = diagramTemplate;

function diagramTemplate(props) {
  console.log(props);

  return (
    <>
      <div className="node">
        <div className="node__container">
          <div className="node__iW">
            <div className="node__top__left">
              <a className="node__thumb__chat icon icon-lp-chat" href="#"></a>
              <div className="node__thumb">
                {" "}
                <img src={crewImage} alt="" />
              </div>
            </div>
            <div className="node__top__right">
              <ul>
                <li>
                  <a className="icon icon-lp-edit-doc" href="#"></a>
                </li>
                <li>
                  <a className="icon icon-lp-duplicate" href="#"></a>
                </li>
                <li>
                  <a className="icon icon-lp-swap" href="#">
                    {" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="node__iW">
            <div className="node__nickname">{props.data.NikName}</div>
            <div className="node__fullname">{props.data.Name}</div>
          </div>
          <div className="node__iW gap">
            {" "}
            <a
              className="node__profile__icon icon icon-lp-profile"
              href="#"
            ></a>
            <div className="node__city icon icon-lp-city icon-r5">
              Los Angelies{" "}
            </div>
            <div className="node__location icon icon-lp-location icon-r5">
              United State
            </div>
          </div>
        </div>
      </div>
    </>

    // <div>
    //     <div style={{backgroundColor:'white',height:'130px',width:'250px',color:'Black',textAlign:'center',
    //     borderWidth:'1px',borderStyle:'Solid', borderColor:'#E89F7A', borderRadius:'21px',
    //     boxShadow:'0px -7px 10px #E89F7A'}}>
    //         <div style={{fontSize:'20px',marginTop:'30px'}}>{props.annotations[0].content}</div>
    //     </div>
    // </div>
  );
}
class CrewPlanner extends CrewPlannerBase {
  rendereComplete() {
    diagramInstance.fitToPage();
  }

  componentDidMount() {}

  render() {
    const { routerLoadData } = this.props;

    const moduleType = CREW_PERMISSION_MODULES.CrewPlanner.actions;
    const permissions = new Permission(routerLoadData?.permission || null);

    const createAction = permissions.findByName(moduleType.CreateNewAction.key);

    const saveAction = permissions.findByName(moduleType.SaveAction.key);

    const viewShortlistAction = permissions.findByName(
      moduleType.ViewShortlist.key
    );

    return (
      <Container withGutterY={false} pageContentClassName="onb_fullpage">
        <div className="onb__page__container top__info bottom_gap">
          <div className="onb__page__container__iW">
            <div className="onb__page__title">Project Crew Planner</div>
          </div>

          <div className="onb__page__container__iW extended">
            <div className="onb__page__actionbar auto_size">
              <div className="onb__page__actionbar__iW">
                <div className="onb__page__actionbar__left project_planner">
                  <div className="onb__page__actionbar__left__iW">
                    <div className="onb__page__actionbar__search gap">
                      <div className="input-group line-inputs">
                        <select
                          className="form-select line-input select"
                          aria-label="Select Project"
                        >
                          <option value="">Select Project</option>
                          <option value="1">Project 1</option>
                          <option value="2">Project 2</option>
                          <option value="3">Project 3</option>
                          <option value="4">Project 4</option>
                        </select>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__search">
                      <div className="input-group line-inputs">
                        <select
                          className="form-select line-input select"
                          aria-label="Select Project"
                        >
                          <option value="">Select Planner Template</option>
                          <option defaultValue={"1"} value="1">
                            Template 1
                          </option>
                          <option value="2">Template 2</option>
                          <option value="3">Template 3</option>
                          <option value="4">Template 4</option>
                        </select>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__buttonGroup">
                      <div className="onb__page__actionbar__buttonGroup__iW">
                        <button
                          className="btn btn-green-light text-light icon icon-lp-duplicate icon-19"
                          type="button"
                        ></button>

                        {createAction.hasPermission ? (
                          <>
                            <div className="or_separator">or </div>
                            <button
                              className="btn btn-green-light text-light icon icon-lp-plus"
                              type="button"
                            >
                              Create New
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="onb__page__global__save">
              <div className="onb__page__global__save__iW">
                {viewShortlistAction.hasPermission ? (
                  <button
                    className="btn btn-orange text-white btn-lg icon icon-lp-menu-2 gap"
                    type="button"
                  >
                    View Shortlisted
                  </button>
                ) : null}

                {saveAction.hasPermission ? (
                  <button
                    className="btn btn-green-light text-white btn-lg icon icon-lp-save-file"
                    type="button"
                  >
                    Save Planner
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="onb__page__container shortlisted_2">
          <div className="onb__page__container__iW">
            <div className="onb__page__shortlisted_2">
              Shortlisted Crew Members
            </div>
          </div>

          <div className="onb__page__container__iW">
            <div className="onb__page__shortlist_2__icon icon icon-lp-drag-drop">
              {" "}
            </div>
            <div className="onb__page__shortlisted_2 gap">
              <div className="onb__page__shortlisted_2__iW">
                <div className="onb__page__shortlisted_2__member">
                  <div className="onb__page__shortlisted_2__member__iW">
                    <ul className="member_hierarchy_status">
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#91e4c8" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#769fff" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#f7895e" }}
                      ></li>
                    </ul>
                    <div className="shortlisted_2__thumb">
                      {" "}
                      <img src={crewImage} />
                    </div>
                    <div className="shortlisted_2__detail__w">
                      <div className="shortlisted_2__detail__w__iW">
                        <div className="top">
                          <div className="top__iW">
                            <div className="shortName">Hetty</div>
                            <div className="fullName"> Hetty Brudenell</div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom__iW">
                            <div className="skills">
                              Costume Designer, Lead Chef, Key PA,{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__shortlisted_2__member">
                  <div className="onb__page__shortlisted_2__member__iW">
                    <ul className="member_hierarchy_status">
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#91e4c8" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#769fff" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#f7895e" }}
                      ></li>
                    </ul>
                    <div className="shortlisted_2__thumb">
                      {" "}
                      <img src={crewImage} />
                    </div>
                    <div className="shortlisted_2__detail__w">
                      <div className="shortlisted_2__detail__w__iW">
                        <div className="top">
                          <div className="top__iW">
                            <div className="shortName">Hetty</div>
                            <div className="fullName"> Hetty Brudenell</div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom__iW">
                            <div className="skills">
                              Costume Designer, Lead Chef, Key PA,{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__shortlisted_2__member">
                  <div className="onb__page__shortlisted_2__member__iW">
                    <ul className="member_hierarchy_status">
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#91e4c8" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#769fff" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#f7895e" }}
                      ></li>
                    </ul>
                    <div className="shortlisted_2__thumb">
                      {" "}
                      <img src={crewImage} />
                    </div>
                    <div className="shortlisted_2__detail__w">
                      <div className="shortlisted_2__detail__w__iW">
                        <div className="top">
                          <div className="top__iW">
                            <div className="shortName">Hetty</div>
                            <div className="fullName"> Hetty Brudenell</div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom__iW">
                            <div className="skills">
                              Costume Designer, Lead Chef, Key PA,{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__shortlisted_2__member">
                  <div className="onb__page__shortlisted_2__member__iW">
                    <ul className="member_hierarchy_status">
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#91e4c8" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#769fff" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#f7895e" }}
                      ></li>
                    </ul>
                    <div className="shortlisted_2__thumb">
                      {" "}
                      <img src={crewImage} />
                    </div>
                    <div className="shortlisted_2__detail__w">
                      <div className="shortlisted_2__detail__w__iW">
                        <div className="top">
                          <div className="top__iW">
                            <div className="shortName">Hetty</div>
                            <div className="fullName"> Hetty Brudenell</div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom__iW">
                            <div className="skills">
                              Costume Designer, Lead Chef, Key PA,{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__shortlisted_2__member">
                  <div className="onb__page__shortlisted_2__member__iW">
                    <ul className="member_hierarchy_status">
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#91e4c8" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#769fff" }}
                      ></li>
                      <li
                        className="icon icon-lp-dot"
                        style={{ color: "#f7895e" }}
                      ></li>
                    </ul>
                    <div className="shortlisted_2__thumb">
                      {" "}
                      <img src={crewImage} />
                    </div>
                    <div className="shortlisted_2__detail__w">
                      <div className="shortlisted_2__detail__w__iW">
                        <div className="top">
                          <div className="top__iW">
                            <div className="shortName">Hetty</div>
                            <div className="fullName"> Hetty Brudenell</div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom__iW">
                            <div className="skills">
                              Costume Designer, Lead Chef, Key PA,{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="onb__page__container">
          <DiagramComponent
            id="diagram"
            width={"100%"}
            height={"600px"}
            dataSourceSettings={{
              id: "Name",
              parentId: "Category",
              dataSource: new DataManager(species),
              //binds the external data with node
              doBinding: (nodeModel, data, diagram) => {
                nodeModel.constraints =
                  NodeConstraints.Default &
                  ~NodeConstraints.Resize &
                  ~NodeConstraints.Rotate &
                  ~NodeConstraints.Delete;
                nodeModel.annotations = [
                  {
                    content: data["Name"],
                    nikname: data["NikName"],
                    style: { color: "red" },
                  },
                ];
                nodeModel.style = {
                  fill: "#ffeec7",
                  strokeColor: "#f5d897",
                  strokeWidth: 1,
                };
              },
            }}
            //Configrues organizational chart layout
            layout={{
              type: "HierarchicalTree",
              horizontalSpacing: 50,
              verticalSpacing: 160,
              margin: { top: 10, left: 10, right: 10, bottom: 10 },
            }}
            //Sets the default values of nodes
            getNodeDefaults={(obj, diagram) => {
              //Initialize shape
              obj.shape = { type: "HTML" }; //{ type: "Basic", shape: "Rectangle" };
              obj.style = { strokeWidth: 1, strokeColor: "red" };
              obj.width = 280;
              obj.height = 160;
            }}
            //Sets the default values of connector
            getConnectorDefaults={(connector, diagram) => {
              connector.type = "Orthogonal";
              connector.style = {
                fill: "red",
                strokeColor: "gray",
                strokeWidth: 2,
                strokeDashArray: "5 5",
                opacity: 1.0,
              };
              connector.targetDecorator.shape = "None";
              connector.constraints =
                ~ConnectorConstraints.Delete &
                ~ConnectorConstraints.Drag &
                ~ConnectorConstraints.Select;
            }}
            ref={(diagram) => (diagramInstance = diagram)}
            nodeTemplate={template.bind(this)}
            //Disables all interactions except zoom/pan
            snapSettings={{ constraints: 0 }}
            //tool={DiagramTools.ZoomPan} snapSettings={{ constraints: 0 }}
          >
            <Inject services={[DataBinding, HierarchicalTree]} />
          </DiagramComponent>
        </div>
      </Container>
    );
  }
}

export default withRouteLoadData(
  ROUTE_OPTIONS.crew.children["crew-planner"].id,
  CrewPlanner
);
