import React from "react";
import { ColumnItem, ColumnWrapper } from "../styled";
import useIcon from "../../../../../../../common/components/IconSelector/hooks/useIcon";
import IconButton from "../../../../../../../common/components/Buttons/IconButton";
import Item from "./Item";
import produce from "immer";

const Column = ({ iconName, title, order, tags, onChange, type }) => {
  const [items, setItems] = React.useState([]);
  const genIcon = useIcon();

  const Icon = genIcon(iconName);

  React.useEffect(() => {
    setItems(tags);
  }, [tags]);

  const toggleHandler = React.useCallback(
    (id) => {
      if (!id) return;

      setItems(
        produce((draft) => {
          const item = draft.find((d) => String(d?.tagId) === String(id));

          let isSelected = true;

          if (Object(item).hasOwnProperty("isSelected")) {
            isSelected = !item.isSelected;
          }

          item.isSelected = isSelected;

          onChange &&
            onChange({
              type,
              data: JSON.parse(JSON.stringify(item)),
              isSelected,
            });
        })
      );
    },
    [onChange, type]
  );

  return (
    <ColumnWrapper>
      <div className="column-head">
        <div className="column-order">
          <div className="number">{order}</div>
        </div>
        {iconName ? (
          <div className="column-icon">
            <IconButton
              as="div"
              btnIconSize={28}
              btnSize={28}
              icon={<Icon />}
            />
          </div>
        ) : null}
        <div className="column-title">{title}</div>
      </div>

      <div className="column-body">
        {items.map((d, i) => {
          return (
            <Item
              key={i}
              data={{
                src: d?.src,
                title: d?.mainFieldValue,
                caption: d?.designation,
              }}
              isSelected={d?.isSelected}
              onClick={() => toggleHandler(d?.tagId)}
            />
          );
        })}
      </div>
    </ColumnWrapper>
  );
};

export default Column;
