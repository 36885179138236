import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled.div`
  ${fontStyle("label-medium")};
  font-weight: 500;
  color: var(--font-default-black);
`;

export const CaptionText = styled.div`
  ${fontStyle("x-small-regular")};
  color: var(--font-tint-04);
`;
