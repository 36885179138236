import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

import WizardModal from "../../../../common/components/UI/Modal/WizardModal";
import ListItem from "../../../../common/components/UI/ListItem";

export const TravelPackWizardModal = styled(WizardModal)`
  .grid {
    display: grid;
    gap: var(--layout-spacing-16);

    &.g2col {
      grid-template-columns: repeat(2, 1fr);
    }

    .g-col {
      display: grid;

      &.full-width {
        grid-column: -1/1;
      }
    }
  }

  &.step_05 {
    --modal-max-width: min(1280px, 90vw);
  }
`;

export const MemberItem = styled(ListItem)`
  gap: var(--layout-spacing-16);
  padding: var(--layout-spacing-2) var(--layout-spacing-16);

  &:hover {
    background-color: var(--color-neutral-10);
  }

  .start {
  }
  .center {
    flex: 1;
  }
  .start {
  }
`;

export const DurationBlock = styled.div`
  display: grid;
  justify-items: end;
  grid-template-rows: 0fr;

  .date-text {
    ${fontStyle("large-semi-bold")};
    font-weight: 600;
    color: #061216;
  }

  .date {
    ${fontStyle("x-small-regular")};
    color: #061216;
  }
`;
