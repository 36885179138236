export const FieldTypes = {
  TextInput: 1, //String
  Currency: 2,
  Email: 3,
  Telephone: 4,
  WebAddress: 5,
  Select: 6, //List
  File: 8, //Attachment
  Int: 11, //Integer
  Decimal: 12, //Float
  TextArea: 7,
  Address:10,
  Time: 13,
  Date: 14,
  Duration: 15, // 
  Reference:9, // Text 
  Schedule:16, // Schedule ,
  TelephoneList: 17, //Array

  // Only use for Front-end side.
  FileUploadManager: 1010,
  GroupFields: 1011,
  Relationship: 100
};

// 1	String
// 2	Currency
// 3	EmailAddress
// 4	Phone
// 5	Web URL
// 6	List
// 7	Text Area
// 8	Attachment
// 9	Reference
// 10	Address
// 11	Integer
// 12	Float
// 13	Time
// 14	Date
// 15	Duration
