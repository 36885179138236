import styled from "styled-components";
import { fontStyle } from "../../../../styled/utility";

export const Wrapper = styled.div`
  .blocks {
    display: grid;
    gap: var(--layout-spacing-12);

    &__item {
    }

    &__title {
      ${fontStyle("x-small-medium")};
      font-weight: 600;
      color: var(--font-tint-04);
    }

    &__slots {
      display: grid;
      gap: var(--layout-spacing-4);

      .slot-item {
        display: grid;
        padding: var(--layout-spacing-4);
        border-radius: var(--layout-border-radius-4);
        background-color: var(--neutral-n20);

        &__title {
          ${fontStyle("x-small-regular")};
          font-weight: 500;
          color: var(--color-primary-300);
        }

        &__time {
          ${fontStyle("x-small-regular")};
          display: flex;
          gap: var(--layout-spacing-4);
          align-items: center;
        }
      }
    }
  }
`;
