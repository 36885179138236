import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import "../../assets/css/onboarding.b2df17d8cd6cdf17a1d8fde4e23c2c97.css";
import API from "../../../services/api";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Container from "../../ui-components/Container";
import NavBar from "../../../pages/Auth/components/Common/Navbar";
import OnboardingItems from "../onBoarding/components/OnboardingList";
import ViewSwitcher from "../onBoarding/components/Toolbar/ViewSwitcher";
import { ROUTES_PATH } from "../../../routes/paths";

const AllMembersView = () => {
  var inputtext = "";
  let MemberList = [];
  const [allMembers, setAllMembers] = useState([]);
  const [allMembersCount, setAllMembersCount] = useState(0);
  let USER = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();


  useEffect(() => {
    getAllMembers();
    searchMembersByName("a").then(() => {
        searchMembersByName("");
      });
  }, []);

  //*************** Member Search ***************** */
  const searchInputKeyDown = async (e) => {
    inputtext = e.target.value;
    if (e.key.length === 1) {
      inputtext = inputtext + e.key;
    } else {
      if (e.key === "Backspace") {
        inputtext = inputtext.slice(0, -1);
      }
    }

    console.log(inputtext);
    searchMembersByName(inputtext);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchMembersByName = async (searchText) => {
    try {
      if (searchText.length === 0) {
        getAllMembers();
        return;
      }
      let tenantId = 0;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(
        endpoints.GETUSER_BYNAME + "/" + tenantId + "/" + searchText
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          MemberList = response.data.result;
          console.log("members", MemberList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          MemberList.forEach((member) => {
            let members = MemberList.filter(
              (m) => m.jobTitleId === member.jobTitleId
            );

            categorizedMembers.push({
              jobTitleId: member.jobTitleId,
              jobTitleName: member.jobTitleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const member_list = categorizedMembers.map((item) => {
            
            return null;
            
          });
          setAllMembers(categorizedMembers);
        } else {
          return;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllMembers = async () => {
    try {
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      
      const response = API.get(endpoints.GETALLUSER_BYTenant + "/" + 0).then(
        (response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            MemberList = response.data.result;
            setAllMembersCount(MemberList?.length);
            console.log("Allmembers", MemberList);
            ////////////////////////////////////////////
            let categorizedMembers = [];
            MemberList.forEach((member) => {
              let members = MemberList.filter(
                (m) => m.jobTitleId === member.jobTitleId
              );

              categorizedMembers.push({
                jobTitleId: member.jobTitleId,
                jobTitleName: member.jobTitleName,
                members: members,
              });
            });

            categorizedMembers = categorizedMembers.filter(
              (ele, ind) =>
                ind ===
                categorizedMembers.findIndex(
                  (elem) => elem.jobTitleId === ele.jobTitleId
                )
            );
            console.log("cat-Allmembers", categorizedMembers);
            
            setAllMembers(categorizedMembers);
          } else {
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  //*************** UI ***************** */
  return (
    <>
    <div style={{marginBottom: "35px",}}>
        <NavBar />
    </div>

      <Container withGutterY={false} pageContentClassName="onb_fullpage">
        <div className="onb__page__container top__info">
         <h1 style={{color: "#246474", fontWeight: "650",}}>Community Platform</h1>

          <div className="onb__page__container__iW extended">
            <div className="onb__page__actionbar" style={{marginLeft: "-30px", marginBottom: "-25px",}}>
              <div className="onb__page__actionbar__iW">
                <div className="onb__page__actionbar__left">
                  <div className="onb__page__actionbar__left__iW">
                    <div className="onb__page__actionbar__search">
                      <div className="input-group line-inputs icon icon-lp-search">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by name..."
                          aria-label="find member"
                          onKeyDown={searchInputKeyDown}
                        />
                        
                        
                      </div>
                    </div>
                    
                    <div className="onb__page__actionbar__search-filter">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-filter-1 icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">
                              Job Categories
                            </div>
                            <ul>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__actionbar__right">
                  <div className="onb__page__actionbar__right__iW">
                    <div className="onb__page__actionbar__sort">
                      
                    </div>
                    <div className="onb__page__actionbar__viewSwitch">
                      <ViewSwitcher />
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
           
          </div>

          <div className="onb__page__container__iW">
            <div className="onb__page__tabs">
              
            </div>
          </div>
        </div>

        

        <div className="onb__page__container top_bottom_gap">
          {/* Search result */}
          <OnboardingItems viewType="card" layoutType="group">
            {/* {allMembers box view} */}

            {allMembers.map((data, i) => {
              return (
                <OnboardingItems.ItemGroup
                  key={i}
                  title={data?.jobTitleName}
                  count={data?.members.length}
                >
                  {data.members.map((member, i) => {
                    const memberData = { ...member, statusId: 0 };
                    return (
                      <OnboardingItems.CommunityItem
                        key={i}
                        data={memberData}
                        menuItems={[
                          {
                            eventKey: "viewProfile",
                            label: "View Profile",
                            disabled: false,
                            onClick: () => {
                              navigate(ROUTES_PATH.PublicRoutePath.Login.path, { replace: true });
                              toast.error("You are not Logged in..");
                            },
                          },
                        ]}
                      />
                    );
                  })}
                </OnboardingItems.ItemGroup>
              );
            })}
          </OnboardingItems>
        </div>
      </Container>     
    </>
  );
};

export default AllMembersView;
