import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "../../assets/css/onboarding.b2df17d8cd6cdf17a1d8fde4e23c2c97.css";
import "../../../components/sass/layout/common.css";

import API from "../../../services/api";
import API_Project from "../../../services/api-project";
import API_Tenant from "../../../services/api-tenant";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Loader from "react-loader-spinner";
import MemberSearch from "./member-search";
import OnboardingList from "./onBoarding-list";
import { confirm } from "react-confirm-box";
import Modal from "../../controls/modals/modal";
import close_icon from "../../img/close-circle.png";
import Container from "../../ui-components/Container";

import HRList from "./hr-list";
import { Link, useRouteLoaderData } from "react-router-dom";
import { ROUTES_PATH } from "../../../routes/paths";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { PROJECT_PERMISSION_MODULES } from "../../../constant/project_permission_module";
import { Permission } from "../../../helpers/permission";
import TabMenuItems, { TabNames } from "./components/TabMenuItems";
import { useAppStore } from "../../../store/AppStore/AppStore";
import { TEMPLATE_TYPES } from "../../../constant/System";
import { createDocument, generateUserDoc } from "../../../Actions/Templates";
import Avatar from "../../ui-components/Avatar";
import OnboardingItems from "./components/OnboardingList";
import { OnboardingActionType } from "../../../constant/ActionTypes";
import ViewSwitcher from "./components/Toolbar/ViewSwitcher";
import { projectOnboardingCompletion } from "../../../Actions/Project";
import { createUserRole } from "../../../Actions/Setting/Permission";
import useDefaultTemplateDoc, {
  TEMPLATE_DOC_TYPES,
} from "../../../hooks/useDefaultTemplateDoc";

const ShortListSearch = () => {
  const { permission } = useRouteLoaderData(
    ROUTE_OPTIONS.projects.children.onboarding.children["shortlisted-list"].id
  );
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);

  const moduleType = PROJECT_PERMISSION_MODULES.Onboarding.ShortList.actions;

  const permissions = new Permission(permission);

  const addToProjectAction = permissions.findByName(
    moduleType.AddToProject.key
  );

  const removeFromShortListAction = permissions.findByName(
    moduleType.RemoveFromShortList.key
  );

  const getProjectAssignTemplates = useAppStore(
    (state) => state.getProjectAssignTemplates
  );

  const templateList = useAppStore((state) => state.projectAssignTemplates);

  const ndaDefaultDoc = useDefaultTemplateDoc({
    templateType: TEMPLATE_DOC_TYPES.NDA,
  });

  let ShortList = [];
  let MemberList = [];
  let OnBoardingList = [];
  let HRList = [];
  let JobTitleList = [];

  const [allMembers, setAllMembers] = useState([]);
  const [show, setShow] = useState(false); //Loading Indicator
  const [allShortLIst, setAllShortList] = useState([]);
  const [allShortListSearch, setAllShortListSearch] = useState([]);
  const [showAddToProjectDialog, setShowAddToProjectDialog] = useState(false);
  const [selectedProjectMember, setSelectedProjectMember] = useState([]);
  const [senderNote, setSenderNote] = useState("");
  const [allOnBoadingListSearch, setAllOnBoadingListSearch] = useState([]);
  const [allHRListSearch, setAllHRListSearch] = useState([]);
  const [jobTitleOptionList, setJobTitleOptionList] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const [jobTitle, setJobTitle] = useState(0);
  let USER = JSON.parse(localStorage.getItem("user"));
  let _PROJECT_ = JSON.parse(localStorage.getItem("selected_project"));
  var inputtext = "";
  const maxChars = 500;
  const [roleOptionList, setRoleOptionList] = useState([]);
  const [role, setRole] = useState(0);
  const [isOnboardingByPass, setIsOnboardingByPass] = useState(false);
  const [addToProjectBtnText, setAddToProjectBtnText] = useState(
    "Add & Send Invitation"
  );
  const [hideSenderNote, setHideSenderNote] = useState(false);
  const [hideRoles, setHideRoles] = useState(true);
  const hasMounted = useRef(false);

  useEffect(() => {
    console.log("selected_project", _PROJECT_);
    searchAllMembers();
    getShortList(_PROJECT_.projectId);
    getOnBoardingList(_PROJECT_.projectId);
    getHRList(_PROJECT_.projectId);
    getAndBindJobTitleList();
    getAndBindRoleList();
  }, []);

  React.useEffect(() => {
    if (hasMounted.current) {
      if (_PROJECT_.projectId) {
        getProjectAssignTemplates(_PROJECT_.projectId);
      }
    } else {
      hasMounted.current = true;
    }
  }, [getProjectAssignTemplates, _PROJECT_.projectId]);

  React.useEffect(() => {
    //console.log("isOnboardingByPass:", isOnboardingByPass);
    if (isOnboardingByPass) {
      setHideRoles(false);
      setHideSenderNote(true);
      setRole(0);
      //setAddToProjectBtnText("Add & Bypass Onboarding");
    } else {
      setHideRoles(true);
      setHideSenderNote(false);
      //setAddToProjectBtnText("Add & Send Invitaion");
      setRole(0);
    }
  }, [isOnboardingByPass]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchAllMembers = async () => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLUSER_BYTenant + "/" + 0).then(
        (response) => {
          console.log(response);
          if (response.data.isSuccess === true) {
            MemberList = response.data.result;
            console.log("Allmembers", MemberList);
            const member_list = MemberList.map((item) => {
              // let isAddedToShortList = containsObject(item, ShortList);
              return <div></div>;
            });
            setAllMembers(member_list);
            setShow(false);
          } else {
            setShow(false);
            return;
          }
        }
      );
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getShortList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_SHORTLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          ShortList = response.data.result;
          console.log("Short List", ShortList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          ShortList.forEach((member) => {
            let members = ShortList.filter(
              (m) => m.jobTitleId === member.jobTitleId
            );

            categorizedMembers.push({
              jobTitleId: member.jobTitleId,
              jobTitleName: member.jobTitleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          const short_list = ShortList.map((item) => {
            return (
              <div className="shortlisted__member">
                <div className="shortlisted__member__iW mb-2">
                  <Avatar size="size-60" src={item.profileImage} />
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__name">
                    {item.surName}
                  </div>
                </div>
                <div className="shortlisted__member__iW">
                  <div className="shortlisted__member__designation">
                    {item.jobTitleName}
                  </div>
                </div>
              </div>
            );
          });
          /////////////////////////////////////////////Search////////////////////////////////////////////////
          // const short_list_search = categorizedMembers.map((item) => {
          //   let skillList = item.skillList;
          //   let skillListString = "";
          //   console.log("skills", skillList);
          //   skillList?.forEach((skill) => {
          //     skillListString = skillListString + skill.skillName + ", ";
          //   });
          //   return (
          //     <div>
          //       <div className="onb__page__container__iW shrink_to_fit">
          //         <div class="onb__page__section__title">
          //           {item.jobTitleName}
          //           <span>({item.members.length})</span>
          //         </div>
          //       </div>
          //       <div class="onb__page__container__iW shrink_to_fit no_top_margin">
          //         {item.members.map((member) => {
          //           let skillList = member.skillList;
          //           let skillListString = "";
          //           // console.log("skills", skillList);
          //           skillList?.forEach((skill) => {
          //             skillListString =
          //               skillListString + skill.skillName + ", ";
          //           });
          //           return (
          //             <div className="onb__member">
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__pic_n_menu">
          //                   <div className="onb__member__pic_n_menu__iW">
          //                     <div className="onb__member__pic">
          //                       <a
          //                         className="icon icon-lp-star active"
          //                         href="#"
          //                       >
          //                         {" "}
          //                       </a>
          //                       <div className="onb__member__pic__iW">
          //                         {" "}
          //                         <img src={member.profileImage} />
          //                       </div>
          //                     </div>
          //                     <div className="onb__member__menu">
          //                       <a className="icon icon-lp-dot-menu" href="#" />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="onb__member__name">
          //                   <div className="onb__member__name__iW">
          //                     <div className="onb__member__nickname">
          //                       {member.displayName}
          //                     </div>
          //                     <div className="onb__member__fullname">
          //                       {member?.fullName}
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="onb__member__primary__skill">
          //                   {member.jobTitleName}
          //                 </div>
          //                 <div className="onb__member__other__skills">
          //                   {skillListString}{" "}
          //                 </div>
          //               </div>
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__other__info">
          //                   <div className="onb__member__other__info__iW">
          //                     {" "}
          //                     <a
          //                       className="icon icon-lp-profile profile"
          //                       href="#"
          //                     />
          //                     <div className="city_location">
          //                       <div className="city_location_iW">
          //                         {" "}
          //                         <a
          //                           className="icon icon-lp-city city"
          //                           href="#"
          //                         >
          //                           Węgliniec
          //                         </a>
          //                         <a
          //                           className="icon icon-lp-location location"
          //                           href="#"
          //                         >
          //                           Poland
          //                         </a>
          //                       </div>
          //                     </div>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__actions">
          //                   <div className="onb__member__actions__iW">
          //                     {" "}
          //                     <button className="btn-base onb__member__chat icon icon-lp-chat">
          //                       {" "}
          //                     </button>
          //                     <button
          //                       disabled={
          //                         !removeFromShortListAction.hasPermission
          //                       }
          //                       className="btn-base onb__member__remove icon icon-lp-delete-2"
          //                       onClick={
          //                         removeFromShortListAction.hasPermission
          //                           ? () => removeUserFromShortlist(member)
          //                           : null
          //                       }
          //                     >
          //                       {" "}
          //                     </button>
          //                     <button
          //                       disabled={!addToProjectAction.hasPermission}
          //                       className="btn-base onb__member__addToShortlist"
          //                       onClick={
          //                         addToProjectAction.hasPermission
          //                           ? () => ShowAddToProjectDialog(member)
          //                           : null
          //                       }
          //                     >
          //                       Add to Project
          //                     </button>
          //                   </div>
          //                 </div>
          //               </div>
          //             </div>
          //           );
          //         })}
          //       </div>
          //     </div>
          //   );
          // });
          setAllShortList(short_list);
          setAllShortListSearch(categorizedMembers);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getOnBoardingList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_MEMBER_ONBOARDINGLIST_BY_PROJECT +
          "/" +
          projectId
      ).then((response) => {
        console.log("onboarding list res: ", response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          OnBoardingList = response.data.result;
          console.log("Onboarding List", OnBoardingList);

          setAllOnBoadingListSearch(OnBoardingList);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const getHRList = async (projectId) => {
    try {
      setShow(true);
      let tenantId = USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_GET_PROJECT_HR_LIST + "/" + projectId
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          //toast.success(response.data.displayMessage);
          HRList = response.data.result;
          setAllHRListSearch(HRList);
          ////////////////////////////////////////////

          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const searchInputKeyDown = async (e) => {
    inputtext = e.target.value;
    if (e.key.length === 1) {
      inputtext = inputtext + e.key;
    } else {
      if (e.key === "Backspace") {
        inputtext = inputtext.slice(0, -1);
      }
    }

    console.log(inputtext);
    searchMembersByName(inputtext);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const searchMembersByName = async (searchText) => {
    try {
      if (searchText.length === 0) {
        getShortList(_PROJECT_.projectId);
        return;
      }
      setShow(true);
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.get(
        endpoints.PROJECT_SHORTLIST_GET_MEMBER_BY_NAME +
          "/" +
          _PROJECT_.projectId +
          "/" +
          searchText
      ).then((response) => {
        console.log(response);
        if (response.data.isSuccess === true) {
          ShortList = response.data.result;
          console.log("ShortListSearch", ShortList);
          ////////////////////////////////////////////
          let categorizedMembers = [];
          ShortList.forEach((member) => {
            let members = ShortList.filter(
              (m) => m.jobTitleId === member.jobTitleId
            );

            categorizedMembers.push({
              jobTitleId: member.jobTitleId,
              jobTitleName: member.jobTitleName,
              members: members,
            });
          });

          categorizedMembers = categorizedMembers.filter(
            (ele, ind) =>
              ind ===
              categorizedMembers.findIndex(
                (elem) => elem.jobTitleId === ele.jobTitleId
              )
          );
          console.log("cat-Allmembers", categorizedMembers);
          //////////////////////////////////////////////////////////////////////
          // const short_list_search = categorizedMembers.map((item) => {
          //   let skillList = item.skillList;
          //   let skillListString = "";
          //   console.log("skills", skillList);
          //   skillList?.forEach((skill) => {
          //     skillListString = skillListString + skill.skillName + ", ";
          //   });
          //   return (
          //     <div>
          //       <div className="onb__page__container__iW shrink_to_fit">
          //         <div class="onb__page__section__title">
          //           {item.jobTitleName}
          //           <span>({item.members.length})</span>
          //         </div>
          //       </div>
          //       <div class="onb__page__container__iW shrink_to_fit no_top_margin">
          //         {item.members.map((member) => {
          //           let skillList = member.skillList;
          //           let skillListString = "";
          //           // console.log("skills", skillList);
          //           skillList?.forEach((skill) => {
          //             skillListString =
          //               skillListString + skill.skillName + ", ";
          //           });
          //           return (
          //             <div className="onb__member">
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__pic_n_menu">
          //                   <div className="onb__member__pic_n_menu__iW">
          //                     <div className="onb__member__pic">
          //                       <a
          //                         className="icon icon-lp-star active"
          //                         href="#"
          //                       >
          //                         {" "}
          //                       </a>
          //                       <div className="onb__member__pic__iW">
          //                         {" "}
          //                         <img src={member.profileImage} />
          //                       </div>
          //                     </div>
          //                     <div className="onb__member__menu">
          //                       <a className="icon icon-lp-dot-menu" href="#" />
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="onb__member__name">
          //                   <div className="onb__member__name__iW">
          //                     <div className="onb__member__nickname">
          //                       {member.displayName}
          //                     </div>
          //                     <div className="onb__member__fullname">
          //                       {member?.fullName}
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="onb__member__primary__skill">
          //                   {member.jobTitleName}
          //                 </div>
          //                 <div className="onb__member__other__skills">
          //                   {skillListString}{" "}
          //                 </div>
          //               </div>
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__other__info">
          //                   <div className="onb__member__other__info__iW">
          //                     {" "}
          //                     <a
          //                       className="icon icon-lp-profile profile"
          //                       href="#"
          //                     />
          //                     <div className="city_location">
          //                       <div className="city_location_iW">
          //                         {" "}
          //                         <a
          //                           className="icon icon-lp-city city"
          //                           href="#"
          //                         >
          //                           Węgliniec
          //                         </a>
          //                         <a
          //                           className="icon icon-lp-location location"
          //                           href="#"
          //                         >
          //                           Poland
          //                         </a>
          //                       </div>
          //                     </div>
          //                   </div>
          //                 </div>
          //               </div>
          //               <div className="onb__member__iW">
          //                 <div className="onb__member__actions">
          //                   <div className="onb__member__actions__iW">
          //                     {" "}
          //                     <a
          //                       className="onb__member__chat icon icon-lp-chat"
          //                       href="#"
          //                     >
          //                       {" "}
          //                     </a>
          //                     <a
          //                       className="onb__member__remove icon icon-lp-delete-2"
          //                       href="#"
          //                       onClick={() => removeUserFromShortlist(member)}
          //                     >
          //                       {" "}
          //                     </a>
          //                     <a
          //                       className="onb__member__addToShortlist"
          //                       href="#"
          //                       onClick={() => ShowAddToProjectDialog(member)}
          //                     >
          //                       Add to Project
          //                     </a>
          //                   </div>
          //                 </div>
          //               </div>
          //             </div>
          //           );
          //         })}
          //       </div>
          //     </div>
          //   );
          // });

          setAllShortListSearch(categorizedMembers);
          setShow(false);
        } else {
          setShow(false);
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const removeUserFromShortlist = async (member) => {
    try {
      const confirmation = await confirm(
        "Are you sure you want to remove this member from the shortlist?"
      );

      if (!confirmation) {
        return;
      }

      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      const response = API_Project.delete(
        endpoints.PROJECT_REMOVE_USER_FROM_SHORTLIST +
          "/" +
          member.projectMemberShortListId,
        {}
      ).then((response) => {
        //console.log(response);
        if (response.data.isSuccess === true) {
          // toast.success("Member Removed successfully");
          getShortList(_PROJECT_.projectId);
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const AddMemberToProject = async () => {
    console.log("selectedProjectMember ", selectedProjectMember);
    try {
      if (jobTitle === 0) {
        toast.warning("Please select Project Role.");
        return;
      }

      if (isOnboardingByPass) {
        if (role === 0) {
          toast.warning("Please select User Role.");
          return;
        }
      }

      const confirmation = await confirm(
        "Do you sure you want to add this member to Project?"
      );

      if (!confirmation) {
        return;
      }

      if (!isOnboardingByPass && !ndaDefaultDoc?.templateId) {
        toast.error("The default template of the NDA was not found");
        return;
      }

      setShow(true); //Loading Indicator on

      const endpoints = new API_ENDPOINTS();

      const response = API_Project.post(endpoints.PROJECT_ADD_USER_TO_PROJECT, {
        memberUserId: selectedProjectMember.userId,
        memberName:
          selectedProjectMember.givenName + " " + selectedProjectMember.surName,
        projectId: _PROJECT_.projectId,
        projectMemberId: 0,
        ProjectRoleId: jobTitle,
      }).then((response) => {
        console.log("add to project", response);
        if (response.data.isSuccess === true) {
          // toast.success("Member Added to Project successfully");
          console.log("AddMemberToProject ", response.data.result);
          if (isOnboardingByPass === false) {
            createNDADocument(response.data.result.projectMemberId);
          }
          if (isOnboardingByPass === true) {
            onboardMemberAndChangeUserRole(response.data.result);
          }
        } else {
          console.log(response.data);
          toast.error(response.data.result.message);

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };
  const onboardMemberAndChangeUserRole = async (member) => {
    try {
      const payload = {
        bankDetails: "",
        companyEmail: "",
        healthDetails: null,
        password: "",
        projectMemberId: member?.projectMemberId,
        isComplete: true,
      };

      const _payload = {
        ...payload,
        isComplete: true,
      };

      showLoader();
      //Onboard User
      const res = await projectOnboardingCompletion(_payload);

      if (!res.isSuccess) {
        toast.error(res?.displayMessage);
        hideLoader();
        return;
      }

      if (res.isSuccess) {
        // toast.success("User Onboarded Successfully");
        //Assign User Role
        let userId = member?.memberUserId;
        if (!userId) return;

        createUserRole({ userId, roleId: role })
          .then((roleres) => {
            if (!roleres.isSuccess) {
              toast.error(res?.displayMessage);
              hideLoader();
              return;
            }
            if (roleres.isSuccess) {
              // toast.success("User Role Assigned Successfully");
              hideLoader();
              setShowAddToProjectDialog(false);
              return;
            }
          })
          .catch((err) => {
            console.error(err);
            hideLoader();
          });
      }
    } catch (err) {
      console.log(err);
      hideLoader(); //Loading Indicator off
    }
  };
  const getAndBindRoleList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API_Tenant.get(
        endpoints.PERMISSION_ROLE_GET_ALL + "/0"
      ).then((response) => {
        console.log("Roles :", response);
        if (response.data.isSuccess === true) {
          let RoleList = response.data.result;

          const role_list_dropdown = RoleList?.filter(
            (r) =>
              r.roleId === 3 ||
              r.roleId === 13 ||
              r.roleId === 14 ||
              r.roleId === 21
          ).map((item) => {
            return <option value={item.roleId}>{item.roleName}</option>;
          });

          setRoleOptionList(role_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };
  const createNDADocument = (projectMemberId) => {
    const userTenantId = selectedProjectMember?.userTenantId;
    // const projectMemberId = selectedProjectMember?.projectMemberId;
    const userId = selectedProjectMember?.userId;

    console.log("selectedProjectMember ", projectMemberId);

    // selectedTemplateId
    generateUserDoc(ndaDefaultDoc.templateId, userTenantId)
      .then((res) => {
        if (!res.isSuccess) {
          toast.error(res?.errorMessages);
          return;
        }

        const docURL = res?.result?.docURL;

        const createDocumentBody = {
          userDocumentId: 0,
          documentTypeId: TEMPLATE_TYPES.NDA,
          documentURL: docURL,
          projectId: _PROJECT_.projectId,
          senderNote: senderNote,
          templateId: 1,
          userId: userId,
          remarks: "",
        };

        createDocument(createDocumentBody)
          .then((res) => {
            if (!res.isSuccess) {
              toast.error(res.displayMessage);
              return;
            }

            const userDocumentId = res?.result?.userDocumentId;

            console.log(
              projectMemberId,
              "<- member id --- doc id => ",
              userDocumentId
            );
            // Send email
            SendInvitaion(
              selectedProjectMember,
              projectMemberId,
              userDocumentId
            );

            getShortList(_PROJECT_.projectId);
            setShow(false); //Loading Indicator off
            setShowAddToProjectDialog(false);
          })
          .catch(() => {
            toast.error(res.displayMessage);
            setShow(false); //Loading Indicator off
          });
      })
      .catch((err) => {
        setShow(false); //Loading Indicator off
      });
  };

  const SendInvitaion = async (member, __projectMemberId, userDocumentId) => {
    try {
      console.log("base urtl", window.Location);
      var base_url = window.location.origin;
      setShow(true); //Loading Indicator on
      const endpoints = new API_ENDPOINTS();
      API_Project.post(endpoints.PROJECT_SEND_PROJECT_ADDED_USER_INVITAION, {
        projectId: _PROJECT_.projectId,
        projectMemberId: __projectMemberId,
        invitationUrl: base_url + "/confirm-project-member/{invitationId}",
        senderNote: senderNote,
        //templateId: 0,
        userDocumentId: userDocumentId,
        //fromDate: new Date(),
        isProjectDocumentRequired: false,
      }).then((response) => {
        console.log("Send Invitaion: ", response);
        if (response.data.isSuccess === true) {
          // toast.success("Invitaion sent successfully");
          setSenderNote("");
          setShowAddToProjectDialog(false);
          setShow(false); //Loading Indicator off
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage + " in Project Invitaion");
          setShow(false); //Loading Indicator off

          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator off
    }
  };

  const ShowAddToProjectDialog = async (member) => {
    setShowAddToProjectDialog(true);
    console.log(member);
    setSelectedProjectMember(member);
    // setSelectedProjectMember((member) => {
    //   console.log("selectedProjectMember", selectedProjectMember);
    // });
  };

  const getAndBindJobTitleList = async (e) => {
    try {
      setShow(true); //Loading Indicator
      const endpoints = new API_ENDPOINTS();
      const response = API.get(endpoints.GETALLJOBTITLES).then((response) => {
        console.log("job titles:", response);
        if (response.data.isSuccess === true) {
          JobTitleList = response.data.result;

          const jobTitle_list_dropdown = JobTitleList.map((item) => {
            return <option value={item.jobTitleId}>{item.jobTitleName}</option>;
          });

          setJobTitleOptionList(jobTitle_list_dropdown);

          setShow(false); //Loading Indicator
        } else {
          console.log(response.data.displayMessage);
          toast.error(response.data.displayMessage);
          setShow(false); //Loading Indicator
          return;
        }
      });
    } catch (err) {
      console.log(err);
      setShow(false); //Loading Indicator
    }
  };

  const onActionHandler = (action) => {
    if (action.type === OnboardingActionType.ADD_TO_PROJECT) {
      ShowAddToProjectDialog(action.payload.member);
    }
  };

  return (
    <>
      <Container withGutterY={false} pageContentClassName="onb_fullpage">
        <div className="onb__page__container top__info">
          {/* <div className="onb__page__container__iW">
            <div className="onb__page__title">
              Shortlisted - {_PROJECT_.projectName}
            </div>
          </div> */}

          <div className="onb__page__container__iW extended">
            <div className="onb__page__actionbar">
              <div className="onb__page__actionbar__iW">
                <div className="onb__page__actionbar__left">
                  <div className="onb__page__actionbar__left__iW">
                    <div className="onb__page__actionbar__search">
                      <div className="input-group line-inputs icon icon-lp-search">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          aria-label="find member"
                          onKeyDown={searchInputKeyDown}
                        />
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          All Members
                        </button>
                        <div className="dropdown-menu dropdown-menu-end custom_dropdown_style">
                          <div className="dropdown_iW">
                            <ul>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__search-filter">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-filter-1 icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">
                              Job Categories
                            </div>
                            <ul>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Director of Photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="onb__page__actionbar__right">
                  <div className="onb__page__actionbar__right__iW">
                    <div className="onb__page__actionbar__sort">
                      <div className="dropdown icon_only_btn_dropdown">
                        {" "}
                        <button
                          className="btn dropdown-toggle icon icon-lp-sorting icon-20"
                          id="filter_search"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu custom_dropdown_style"
                          aria-labelledby="filter_search"
                        >
                          <div className="dropdown_iW">
                            <div className="dropdown__title">Sort by</div>
                            <ul>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Ascending
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Ascending
                                </a>
                              </li>
                              <li>
                                {" "}
                                <a className="dropdown-item" href="#">
                                  Ascending
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="onb__page__actionbar__viewSwitch">
                      <ViewSwitcher />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="onb__page__global__save me-2">
              <button
                className="btn btn-orange text-white btn-lg icon icon-lp-add-user lp-org-btn"
                type="button"
              ></button>
            </div>
            <div className="onb__page__global__save">
              <button
                className="btn btn-green-light text-white btn-lg icon icon-lp-add-user"
                type="button"
              >
                Add Shortlisted to Project
              </button>
            </div> */}
          </div>

          <div className="onb__page__container__iW">
            <div className="onb__page__tabs">
              <div className="onb__page__tabs__iW">
                <div className="onb__page__tabs__left">
                  <TabMenuItems
                    memberCount={allMembers.length}
                    shortListCount={allShortLIst.length}
                    onboardingListCount={allOnBoadingListSearch.length}
                    hrListCount={allHRListSearch.length}
                    activeTab={TabNames.shortlisted}
                  />
                </div>
                <div className="onb__page__tabs__right">
                  <div className="onb__page__tabs__right__iW">
                    <div className="onb__page__tabs__right__additional">
                      <ul className="onb__page__tabs__right__additional__iW">
                        <li>
                          <a href="#">
                            Request Sent<span>(2)</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Offer Rejected<span>(32)</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="onb__page__pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Previous
                            </a>
                          </li>
                          <li className="page-item active">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="onb__page__container shortlisted"
          style={{ display: "none" }}
        >
          <div className="onb__page__container__iW">
            <div className="onb__page__shortlisted">
              <div className="onb__page__shortlisted__iW">
                <div className="onb__page__shortlisted__description">
                  Shortlisted
                  <br />
                  <span>({allShortLIst.length})</span>
                </div>
                <div className="onb__page__shortlisted__list">
                  <div className="onb__page__shortlisted__list__iW">
                    {/* Shortlist */}
                    {allShortLIst}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search */}
        <div className="onb__page__container top_bottom_gap">
          <OnboardingItems viewType="card" layoutType="group">
            {/* {allMembers} */}

            {allShortListSearch.map((data, i) => {
              return (
                <OnboardingItems.ItemGroup
                  key={i}
                  title={data?.jobTitleName}
                  count={data?.members.length}
                >
                  {data.members.map((member, i) => {
                    const memberData = { ...member, statusId: 1 };
                    return (
                      <OnboardingItems.Item
                        key={i}
                        data={memberData}
                        hasActionPermission={addToProjectAction.hasPermission}
                        onActionClick={onActionHandler}
                        menuItems={[
                          {
                            eventKey: "delete",
                            label: "Remove from shortlist",
                            disabled: !removeFromShortListAction.hasPermission,
                            onClick: removeFromShortListAction.hasPermission
                              ? () => removeUserFromShortlist(member)
                              : null,
                          },
                        ]}
                      />
                    );
                  })}
                </OnboardingItems.ItemGroup>
              );
            })}
          </OnboardingItems>
        </div>
      </Container>

      <React.Fragment>
        <Modal
          show={showAddToProjectDialog}
          onClose={() => setShowAddToProjectDialog(false)}
        >
          <div className="content">
            <div class="menu__content" style={{ "min-height": "90vh" }}>
              <div class="menu__content__panel" style={{ width: "45%" }}>
                <div hidden={true}>
                  <span
                    style={{
                      float: "right",
                      width: "0px",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={() => setShowAddToProjectDialog(false)}
                  >
                    <img src={close_icon} height={30} width={30} />
                  </span>
                </div>
                <div
                  class="bigicon"
                  style={{ marginLeft: "calc(80%/2)", marginTop: "-33px" }}
                >
                  {" "}
                  <i
                    class="icon icon-lp-add-user-1 icon-35"
                    style={{
                      border: "0px",
                      borderRadius: "30px",
                      color: "#E89F7A",
                    }}
                  ></i>
                </div>
                <div
                  class="menu__content__panel__text"
                  style={{ marginTop: "0px" }}
                >
                  <h4
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontSize: "18px",
                      color: "#245A6C",
                    }}
                  >
                    Add to Project
                  </h4>
                  <form class="row g-3">
                    <div class="col-md-12">
                      <input
                        class="form-control"
                        type="text"
                        id="prject_name"
                        name="project_name"
                        placeholder="Name your project"
                        aria-label="project Name"
                        // onChange={(e) => setProjectName(e.target.value)}
                        value={_PROJECT_.projectName}
                        readOnly
                      />
                    </div>
                    <div class="col-md-12">
                      <input
                        class="form-control"
                        type="text"
                        id="member_name"
                        name="member_name"
                        placeholder="Name of selected member"
                        aria-label="Member Name"
                        value={
                          selectedProjectMember.givenName +
                          " " +
                          selectedProjectMember.surName
                        }
                        readOnly
                      />
                    </div>
                    <div class="col-md-12">
                      <select
                        className="form-select"
                        aria-label="Select JobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      >
                        <option selected value={0}>
                          - Select Person's Role in Project -
                        </option>
                        {jobTitleOptionList}
                      </select>
                    </div>

                    <div class="col-md-12">
                      <input
                        type="checkbox"
                        checked={isOnboardingByPass}
                        onChange={(e) =>
                          setIsOnboardingByPass(e.target.checked)
                        }
                      />{" "}
                      <label className="col-md-6">Contract not required</label>
                    </div>

                    <div class="col-md-12" hidden={hideRoles}>
                      <select
                        className="form-select"
                        aria-label="Select Role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option selected value={0}>
                          - Select Person's User Role -
                        </option>
                        {roleOptionList}
                      </select>
                    </div>

                    <div class="col-md-6" hidden={true}>
                      <select
                        className="form-select"
                        aria-label="Select Template"
                        value={selectedTemplateId}
                        onChange={(e) => setSelectedTemplateId(e.target.value)}
                      >
                        <option selected value={0}>
                          - Select NDA Template
                        </option>

                        {templateList
                          .filter(
                            (template) =>
                              template.templateTypeId.toString() ===
                              TEMPLATE_TYPES.NDA.toString()
                          )
                          .map((t, i) => (
                            <option value={t?.templateId}>
                              {t?.templateName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </form>
                  <form class="row g-3" hidden={hideSenderNote}>
                    <div class="col-md-12">
                      <textarea
                        style={{
                          color: "#6A7282",
                          width: "100%",
                          marginTop: "15px",
                          height: "200px",
                        }}
                        placeholder="You can add your message here."
                        onChange={(e) => setSenderNote(e.target.value)}
                        value={senderNote}
                      ></textarea>
                      {/* <div style={{ textAlign: "right", color: "#6A7282" }}>
                        {senderNote.length}/{maxChars} characters
                      </div> */}
                    </div>
                  </form>
                </div>
                <div
                  class="menu__content__panel__btns"
                  style={{ justifyContent: "center" }}
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setShowAddToProjectDialog(false)}
                  >
                    Cancel
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={AddMemberToProject}
                  >
                    {addToProjectBtnText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ShortListSearch;
