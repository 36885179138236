import React from "react";
import { useMutation, useQuery } from "react-query";
import { formatDistanceToNow } from "date-fns";
import {
  TAG_MODAL_MODE,
  useResearchBoardStore,
} from "../../../../store/ResearchBoardStore";
import { useToastMessage } from "../../../../../../context/ToastMessageProvider";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import { tagSaveOrUpdate, getFormFields } from "../../../../api/dynamic-form";
import { getTagDataByTagId } from "../../../../api/common/tags";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { LOCK_STATUS } from "../../../../constant";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import IconWarning from "../../../../../../common/components/icons/Line/Warning/01";
import useEntityLock from "../../../../../../hooks/useEntityLock";
import useTagTypes from "../../../../hooks/useTagTypes";
import * as Styled from "./styled";
import TagForm from "./TagForm";
import DraggableModal from "../../../../../../common/components/UI/Modal/DraggableModal";
import OptionButton from "../../../../../../common/components/Buttons/OptionButton";
import { RESEARCH_TAG_WHO } from "../../../research-data/TagCard";
import IconButton from "../../../../../../common/components/Buttons/IconButton";
import { useIcon } from "../../../../../../common/components/IconSelector";
import IconEdit from "../../../../../../common/components/icons/Line/Edit/01";
import IconView from "../../../../../../common/components/icons/Line/View/01";
import { DropdownArrowIcon } from "../../../../../../common/components/UI/PopoverDropdown";
import { getDateWithoutOffset, timeZone } from "../../../../../../utility";

export const RESEARCH_FILED_DISPLAY_TYPE = {
  NA: 0,
  TITLE: 1,
  SUBTITLE: 2,
  DISCRIPTION: 3,
  VISUAL: 4,
};
function getValue(value) {
  if (value === Object(value)) {
    return value.label;
  } else {
    return value;
  }
}
const defaultLockStateValues = {
  lockId: 1,
  lockTypeId: -1,
  entityID: -1,
  lockStatus: -1,
  lockStatusMessage: "",
  remainingTimeInSeconds: -1,
};

const TagItemModal = () => {
  const getIcon = useIcon();
  const timerRef = React.useRef(null);
  const [mainFieldData, setMainField] = React.useState();
  const [showMainField, setShowMainField] = React.useState(false);
  const [selectedTagType, setSelectedTagType] = React.useState(null);
  const [isLockTimeExpanded, setLockTimeExpand] = React.useState(false);
  const [isContentLoading, setIsContentLoading] = React.useState(false);
  const [showIndicateAnimation, setShowIndicateAnimation] =
    React.useState(false);
  const [who, setWho] = React.useState("");
  const [name, setNameWho] = React.useState("");
  const [currentFocusTab, setCurrentFocusTab] = React.useState(null);
  const [showAuthors, setShowAuthors] = React.useState(true);
  const [duration, setDuration] = React.useState("");
  const [shouldClose, setShouldClose] = React.useState(false);
  const [tagLockStatus, setTagLockStatus] = React.useState(
    defaultLockStateValues
  );
  const [sectionVisibilityStatus, setSectionVisibilityStatus] = React.useState(
    []
  );

  // const { fireToast } = useToastMessage();
  const showLoader = useAppStore((state) => state.showLoader);
  const hideLoader = useAppStore((state) => state.hideLoader);
  const isShow = useResearchBoardStore((state) => state.showTagModal);
  const onClose = useResearchBoardStore((state) => state.onHideTagModal);
  const onTagRevalidate = useResearchBoardStore((s) => s.onTagRevalidate);
  const projectId = useGlobalStore((state) => state.selectedProjectId);
  const { onLockRelease, onLockRequest } = useEntityLock({ lockTypeId: 5 });
  const tagModalOptions = useResearchBoardStore((s) => s.tagModalPayload);
  const onShowTagModal = useResearchBoardStore((s) => s.onShowTagModal);
  const onShowPreviewModel = useResearchBoardStore((s) => s.onShowPreviewModal);
  const previewModelPayload = useResearchBoardStore((s) => s.previewModalData);
  const { toast } = useToastMessage();

  //
  const mode = tagModalOptions.mode;
  const tagId = tagModalOptions.tagId || null;
  const tagTypeId = tagModalOptions.tagTypeId || null;
  const callbackFn = tagModalOptions?.callback || null;

  const enableTagTypes = isShow;

  const { tagTypeItems } = useTagTypes("tags-in-modal", {
    enabled: !!enableTagTypes,
  });

  const isFieldsQueryEnabled = isShow && selectedTagType?.value !== null;

  const fieldsQuery = useQuery(
    ["tag-fields", selectedTagType?.value, mode, tagId],
    () => getFormFields(selectedTagType?.value, mode, tagId),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: false,
      enabled: !!isFieldsQueryEnabled,
      cacheTime: Infinity,
    }
  );

  const getDuration = React.useCallback((date, withOffset = true) => {
    const distance = formatDistanceToNow(
      withOffset ? getDateWithoutOffset(date) : date
    );

    return `${distance} ago`;
  }, []);

  const tagCreateOrUpdateMutation = useMutation(
    ({ value, unsortedFiles }) => {
      let mainField = value.find((f) => f.displayTypeId === 1);

      if (typeof mainField?.value === "object") {
        mainField = mainField?.value?.label;
      } else {
        mainField = mainField?.value;
      }

      const customData = {
        sectionTabStatus:
          checkTabStatus(
            sectionVisibilityStatus,
            value,
            fieldsQuery?.data?.fields
          ) || [],
        fileUploadManager: {
          unsortedFiles: unsortedFiles,
        },
      };

      const body = {
        formData: JSON.stringify(value),
        customData: JSON.stringify(customData),
        mainFieldValue: String(mainField),
        projectId: projectId,
        tagTypeId: selectedTagType?.value,
      };
      // Call API
      return tagSaveOrUpdate(body, tagId);
    },
    {
      onMutate: () => {
        setIsContentLoading(true);
      },
      onError: (err) => {
        toast.error(err);
        setIsContentLoading(false);
      },
      onSuccess: (res) => {
        setIsContentLoading(false);
        onTagRevalidate && onTagRevalidate(false);

        if (!res.isSuccess) {
          toast.error(res.displayMessage);
          return;
        }

        // mainFieldValue
        callbackFn && callbackFn("", tagTypeId, res?.result);

        // Revalidate tags

        onTagRevalidate && onTagRevalidate();

        if (
          TAG_MODAL_MODE.Create === mode ||
          TAG_MODAL_MODE.Text_Selected === mode
        ) {
          const id = res?.result.tagId;
          const typeId = res?.result.tagTypeId;

          if (!id || !typeId) {
            // onCloseModal();
            return;
          }

          if (shouldClose) {
            onCloseModal();
            return;
          }

          onShowTagModal({
            mode: TAG_MODAL_MODE.Edit,
            tagId: id,
            tagTypeId: typeId,
            selectedText: "",
          });

          return;
        }

        setDuration(getDuration(new Date(), false));

        // Close modal after submitting
        if (shouldClose) {
          onCloseModal();
        }
        // toast.success(res.displayMessage || "Tag Created Successfully");
      },
    }
  );

  const tagOptions = React.useMemo(() => {
    let _tags = [];

    if (tagTypeItems) {
      _tags = tagTypeItems?.map((tag) => ({
        value: tag.id(),
        label: tag.name(),
      }));
    }

    return _tags || [];
  }, [tagTypeItems]);

  const tagTypeDetails = React.useMemo(() => {
    if (!tagTypeItems.length || !selectedTagType?.value) {
      return null;
    }

    return tagTypeItems?.find((tag) => tag.id() === selectedTagType?.value);
  }, [tagTypeItems, selectedTagType?.value]);

  const isLocked = React.useMemo(() => {
    if (
      tagLockStatus &&
      (tagLockStatus.lockStatus === LOCK_STATUS.Extended ||
        tagLockStatus.lockStatus === LOCK_STATUS.Locked)
    ) {
      return true;
    }

    return false;
  }, [tagLockStatus]);

  const onCloseModal = () => {
    setSelectedTagType(null);
    // tagTypesQuery.remove();
    fieldsQuery.remove();

    onClose();

    if (mode === TAG_MODAL_MODE.Edit && !isLocked) {
      onLockRelease(tagId);
    }
    // clear timeout
    timerRef?.current && clearTimeout(timerRef?.current);

    setCurrentFocusTab(null);
    setShouldClose(false);
    setShowMainField(false);
    setSelectedTagType(null);
  };

  const onPreview = async () => {
    if (mode === TAG_MODAL_MODE.Edit) {
      const openedTag_data = await getTagDataByTagId(
        previewModelPayload.tagData.tagId
      );

      const rawFormData = openedTag_data?.formData; /*updatedPayload.formData;*/
      let formData;
      const variant = previewModelPayload.variant || "";

      previewModelPayload.tagData.customData = openedTag_data?.customData;
      previewModelPayload.tagData.formData = openedTag_data?.formData;
      previewModelPayload.tagData.mainFieldValue =
        openedTag_data?.mainFieldValue;

      try {
        if (Array.isArray(rawFormData)) {
          formData = rawFormData;
        } else if (typeof rawFormData === "string") {
          // parse if JSON string
          formData = JSON.parse(rawFormData);
        } else {
          // Default to empty array if neither
          console.warn("formData is not an array or valid JSON:", rawFormData);
          formData = [];
        }
      } catch (error) {
        console.error("Failed to parse formData:", error);
        formData = [];
      }

      let subTagsCategorizedTagFileds = [];
      let data = formData;

      if (!Array.isArray(data)) return null;

      data?.forEach((data_element) => {
        let fileds = data.filter(
          (d) => d.tagSubTypeId === data_element.tagSubTypeId
        );

        subTagsCategorizedTagFileds.push({
          tagSubTypeId: data_element.tagSubTypeId,
          tagSubTypeName: data_element.tagSubTypeName,
          tagFileds: fileds,
          icon: data_element?.icon || "",
        });
      });
      subTagsCategorizedTagFileds = subTagsCategorizedTagFileds?.filter(
        (ele, ind) =>
          ind ===
          subTagsCategorizedTagFileds.findIndex(
            (elem) => elem.tagSubTypeId === ele.tagSubTypeId
          )
      );

      let title = getValue(
        formData?.filter(
          (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.TITLE
        )[0]?.value
      );
      let subTitle = getValue(
        formData?.filter(
          (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.SUBTITLE
        )[0]?.value
      );
      let visual = formData?.filter(
        (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
      )[0]?.value;
      let visualType = formData?.filter(
        (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
      )[0]?.visualTypeId;
      let visualTypeImages =
        formData
          ?.filter((f) => f?.visualTypeId === 1)
          ?.map((d) => d?.value)
          ?.filter((d) => d?.length > 0)
          ?.map((s) => s?.map((f) => f?.filePath)) || [];

      let previewPayload = {
        variant,
        visual,
        visualType,
        visualTypeImages,
        title,
        subTitle,
        showContent: visual === undefined ? false : true,
        tagData: openedTag_data,
        formData: formData,
        subTagsCategorizedTagFileds,
      };

      console.log("Preview Option clicked from edit popup: ", previewPayload);
      onCloseModal();
      onShowPreviewModel(previewPayload);
    }
  };

  const onTagChangeHandler = (selected) => {
    setSelectedTagType(selected || null);
  };

  const onSubmitHandler = (value, callback, unsortedFiles) => {
    if (mode === TAG_MODAL_MODE.Edit && isLocked) return;

    tagCreateOrUpdateMutation.mutate({
      value,
      unsortedFiles,
    });
  };

  const getFiledNames = React.useCallback((list, id) => {
    let name_list = "";

    const object = list;
    var keyNames = Object.keys(object || {});

    keyNames.forEach((propertyName) => {
      var obj = object[propertyName];

      if (obj?.__tagSubTypeId === id) {
        if (name_list === "") {
          name_list = obj?.__label;
        } else {
          name_list = name_list + ", " + obj?.__label;
        }
      }
    });

    return name_list;
  }, []);

  const onSubTagChangeHandler = React.useCallback(
    (selected) => {
      if (!selected) return;

      const updatedStatus = [...sectionVisibilityStatus].map((item) => {
        if (selected?.id === item.id) {
          setCurrentFocusTab(item.id);
          return {
            ...item,
            visibility: true,
          };
        }
        return item;
      });

      setSectionVisibilityStatus(updatedStatus);
    },
    [sectionVisibilityStatus]
  );

  const subTagOptions = React.useMemo(() => {
    if (!sectionVisibilityStatus) {
      return [];
    }

    return sectionVisibilityStatus?.map((tag) => ({
      label: tag?.name,
      description: getFiledNames(fieldsQuery?.data?.fields, tag?.id),
      onClick: () => {
        onSubTagChangeHandler(tag);
      },
    }));
  }, [
    sectionVisibilityStatus,
    getFiledNames,
    fieldsQuery?.data?.fields,
    onSubTagChangeHandler,
  ]);

  React.useEffect(() => {
    if (isShow) {
      if (fieldsQuery?.isLoading) {
        showLoader();
        return;
      }

      if (mode === TAG_MODAL_MODE.Edit) {
        if (fieldsQuery?.isFetched && !fieldsQuery?.isLoading) {
          setTagLockStatus(fieldsQuery?.data?.entityLock || null);
        }
      }

      hideLoader();
    }
  }, [fieldsQuery, mode, showLoader, hideLoader, isShow]);

  React.useEffect(() => {
    if (isShow) {
      if (!tagOptions) {
        return false;
      }

      if (!selectedTagType) {
        let defaultValue = null;

        if (!!tagTypeId) {
          defaultValue = tagOptions.find(
            (t) => String(t.value) === String(tagTypeId)
          );
        } else if (tagOptions.length > 0) {
          defaultValue = tagOptions[0];
        }

        setSelectedTagType(defaultValue);
        return;
      }

      if (tagId) {
        const selected = tagOptions.find(
          (t) => String(t.value) === String(tagTypeId)
        );

        setSelectedTagType(selected);
      }
    }
  }, [tagOptions, tagId, tagTypeId, selectedTagType, isShow]);

  React.useEffect(() => {
    if (isShow && mode !== TAG_MODAL_MODE.Edit) {
      return;
    }

    if (isShow && isLocked) {
      return;
    }

    if (tagLockStatus?.remainingTimeInSeconds > 0) {
      const time =
        (Math.floor(tagLockStatus.remainingTimeInSeconds) / 2) * 1000;

      timerRef.current = setTimeout(() => {
        setTagLockStatus((prev) => ({ ...prev, remainingTimeInSeconds: -1 }));
        setLockTimeExpand(true);
      }, time);
    }
  }, [
    mode,
    isShow,
    tagLockStatus?.remainingTimeInSeconds,
    isLocked,
    onLockRequest,
    tagId,
  ]);

  React.useEffect(() => {
    if (isLockTimeExpanded) {
      onLockRequest(tagId).then((res) => {
        setLockTimeExpand(false);

        setTagLockStatus({
          entityID: res?.entityID,
          lockId: res?.lockId,
          lockStatus: res?.lockStatus,
          lockStatusMessage: res?.lockStatusMessage,
          lockTypeId: res?.lockTypeId,
          remainingTimeInSeconds: res?.remainingTimeInSeconds,
        });
      });
    }
  }, [isLockTimeExpanded, onLockRequest, tagId]);

  React.useEffect(() => {
    if (tagModalOptions?.mode === 1) {
      setShowAuthors(true);
      return;
    } else {
      setShowAuthors(false);
    }

    if (
      tagModalOptions?.tagData?.sysCreatedBy !== "" &&
      tagModalOptions?.tagData?.sysUpdatedBy === ""
    ) {
      setWho(RESEARCH_TAG_WHO.AUTHOR);
      setNameWho(tagModalOptions?.tagData?.sysCreatedUserName);
      setDuration(getDuration(tagModalOptions?.tagData?.sysCreatedOn));
    } else if (
      tagModalOptions?.tagData?.sysCreatedBy === "" &&
      tagModalOptions?.tagData?.sysUpdatedBy !== ""
    ) {
      setWho(RESEARCH_TAG_WHO.EDITOR);
      setNameWho(tagModalOptions?.tagData?.sysUpdatedUserName);

      if (tagModalOptions?.tagData?.sysUpdatedOn) {
        setDuration(getDuration(tagModalOptions?.tagData?.sysUpdatedOn));
      }
    } else if (
      tagModalOptions?.tagData?.sysCreatedBy !== "" &&
      tagModalOptions?.tagData?.sysUpdatedBy !== ""
    ) {
      setWho(RESEARCH_TAG_WHO.EDITOR);
      setNameWho(tagModalOptions?.tagData?.sysUpdatedUserName);
      if (tagModalOptions?.tagData?.sysUpdatedOn) {
        setDuration(getDuration(tagModalOptions?.tagData?.sysUpdatedOn));
      }
    } else {
      setWho("");
      setNameWho("");
      setDuration("");
    }
  }, [getDuration, tagModalOptions?.mode, tagModalOptions?.tagData]);

  React.useEffect(() => {
    if (fieldsQuery?.data?.tabItems && isShow) {
      setSectionVisibilityStatus(fieldsQuery?.data?.tabItems);
    }
  }, [fieldsQuery?.data?.tabItems, isShow]);

  // Indicate animation start
  React.useEffect(() => {
    if (isShow && !fieldsQuery?.isLoading && !!fieldsQuery?.data) {
      const timer = setTimeout(() => {
        setShowIndicateAnimation(true);
      }, 1000);

      return () => {
        timer && clearTimeout(timer);
      };
    }
  }, [isShow, fieldsQuery?.data, fieldsQuery?.isLoading]);

  const modalVisibility = isShow;
  // const modalVisibility = isShow && !!fieldsQuery.data;

  const ModalHeader = React.useMemo(() => {
    const TagTypeIcon = getIcon(tagTypeDetails?.icon());

    const typeSelector = (
      <Styled.TagTypeSelector
        options={tagOptions}
        customDropdown={() => {
          return (
            <Styled.TagTypeHeader className="head">
              <span className="text">{selectedTagType?.label}</span>
              <IconButton
                className="icon"
                as="i"
                icon={<DropdownArrowIcon />}
                btnIconSize={12}
                btnSize={16}
              />
            </Styled.TagTypeHeader>
          );
        }}
        render={(item, { key, closeDropdown }) => {
          return (
            <Styled.TagTypeSelectorItem
              key={key}
              label={item?.label}
              $isSelected={selectedTagType?.value === item?.value}
              onClick={() => {
                onTagChangeHandler(item);
                closeDropdown();
              }}
            />
          );
        }}
      />
    );

    return (
      <Styled.ModalHeader>
        <div className="inner">
          <div className="top">
            {mode === TAG_MODAL_MODE.Edit && (
              <div
                className="top_preview_option"
                role="button"
                onClick={onPreview}
              >
                <IconBlock>
                  <IconView />
                </IconBlock>
              </div>
            )}

            {!!tagTypeDetails?.icon() && (
              <div className="top__left">
                <IconButton
                  as="div"
                  icon={<TagTypeIcon />}
                  className="tag__type__icon"
                />
              </div>
            )}

            <div className="top__right">
              <div className="start">
                <div className="modal__state">
                  <div className="state__label">
                    {mode === TAG_MODAL_MODE.Edit ? "Edit" : " Create"}
                  </div>
                  <div className="state__selector">
                    {mode === TAG_MODAL_MODE.Edit
                      ? `${selectedTagType?.label || ""}`
                      : typeSelector}
                  </div>
                </div>

                <div className="modal__default__value">
                  <div
                    className={`value ${
                      !mainFieldData?.val?.length ? "placeholder--text" : ""
                    }`}
                  >
                    {mainFieldData?.val ||
                      `${selectedTagType?.label || ""} Name` ||
                      ""}
                  </div>

                  {mode === TAG_MODAL_MODE.Edit &&
                    !!mainFieldData?.val?.length > 0 &&
                    !showMainField && (
                      <IconButton
                        className={"edit__btn"}
                        icon={<IconEdit />}
                        btnIconSize={16}
                        btnSize={24}
                        onClick={() => {
                          setShowMainField(true);
                          setTimeout(() => {
                            mainFieldData?.ref?.focus();
                          }, 100);
                        }}
                      />
                    )}
                </div>
              </div>

              <div className="end">
                <OptionButton
                  className="add__btn"
                  optionListClassName="add__option__list"
                  options={subTagOptions}
                  indicateAnimation={showIndicateAnimation}
                  afterIndicateAnimationStop={() =>
                    setShowIndicateAnimation(false)
                  }
                  renderItem={(o, i) => {
                    return (
                      <li key={i} className="section" onClick={o.onClick}>
                        <div className="section__title">{o.label}</div>
                        <div className="section__description">
                          {o.description}
                        </div>
                      </li>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {/* last edit */}
          {tagModalOptions.mode === TAG_MODAL_MODE.Edit && (
            <div className="content__editor">
              <span className="content__editor--title">{who}</span>

              <span className="content__editor--details">
                <span className="name">{name}</span>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span className="time">{duration}</span>
              </span>
            </div>
          )}

          {/*Lock status Message */}
          {mode === TAG_MODAL_MODE.Edit && isLocked ? (
            <div className="bottom">
              <Styled.WarringMessage>
                <IconBlock className="icon">
                  <IconWarning />
                </IconBlock>

                <p>{tagLockStatus?.lockStatusMessage}</p>
              </Styled.WarringMessage>
            </div>
          ) : null}
        </div>
      </Styled.ModalHeader>
    );
  }, [
    duration,
    getIcon,
    isLocked,
    mainFieldData?.ref,
    mainFieldData?.val,
    mode,
    name,
    selectedTagType?.label,
    selectedTagType?.value,
    showIndicateAnimation,
    showMainField,
    subTagOptions,
    tagLockStatus?.lockStatusMessage,
    tagModalOptions.mode,
    tagOptions,
    tagTypeDetails,
    who,
  ]);

  return (
    <DraggableModal
      visibility={modalVisibility}
      header={ModalHeader}
      onClose={onCloseModal}
      modalHeaderClassName="tag__modal__header"
      modalCss={Styled.ModalCss}
      className="draggable__tag__modal"
      isLoading={isContentLoading}
    >
      {/*
        ----------------
        Body Container
        ----------------
       */}

      <Styled.ModalBody>
        {/* 
          Only show when mode is Text Selected.
         */}

        {/* Form Container */}
        <TagForm
          showMainField={showMainField}
          setMainField={setMainField}
          currentFocusTab={currentFocusTab}
          mode={mode}
          data={fieldsQuery?.data}
          tagData={tagModalOptions.tagData}
          sectionVisibilityStatus={sectionVisibilityStatus}
          onSubmit={onSubmitHandler}
          onCancel={onCloseModal}
          isLocked={isLocked}
          setShouldClose={setShouldClose}
        />
      </Styled.ModalBody>
    </DraggableModal>
  );
};

function checkTabStatus(tabs, currentFormFields, existingFormFields) {
  if (!tabs || tabs.length === 0 || !existingFormFields) {
    return [];
  }

  // Get none required fields
  existingFormFields = Object.entries(existingFormFields)
    .map(([, field]) => field)
    .filter((field) => !field.__isRequired);

  //Check and assign to new array of none required fields.
  let noneRequiredFields = [];

  currentFormFields = currentFormFields.forEach((cField) => {
    existingFormFields.forEach((exField) => {
      if (cField.tagFieldId === exField.__id) {
        noneRequiredFields.push(cField);
      }
    });
  });

  return tabs.map((tabItem) => {
    const canSectionShow = noneRequiredFields
      .filter((field) => tabItem?.id === field?.tagSubTypeId)
      .some((field) => {
        if (field?.value && Array.isArray(field?.value)) {
          return field.value.length > 0;
        }

        return field?.value;
      });

    return { ...tabItem, visibility: canSectionShow };
  });
}

export default TagItemModal;
