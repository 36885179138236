import React from "react";
import { CaptionText, ListItemWrapper, TitleText } from "./styled";
import People from "../Avatar";

const ListItemContext = React.createContext();
const useListItemContext = () => React.useContext(ListItemContext);

const ListItem = ({ data, children, ...rest }) => {
  return (
    <ListItemContext.Provider value={data}>
      <ListItemWrapper {...rest}>{children}</ListItemWrapper>
    </ListItemContext.Provider>
  );
};

function Avatar(props) {
  const data = useListItemContext();
  return <People src={data?.src} {...props} />;
}

function Title(props) {
  const data = useListItemContext();
  return <TitleText {...props}>{data?.title}</TitleText>;
}

function Caption(props) {
  const data = useListItemContext();
  return <CaptionText {...props}>{data?.caption}</CaptionText>;
}

ListItem.Avatar = Avatar;
ListItem.Title = Title;
ListItem.Caption = Caption;

export default ListItem;
