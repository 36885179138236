export const AvailabilityStatus = {
  NA: 1,
  Confirm: 2,
  Tentative: 3,
  Public: 4,
};

export const CalendarType = {
  Tag: 1,
  User: 2,
};
