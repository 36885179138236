import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

import ContentItem, { ViewTypes } from "../ContentItem";
import { FieldTypes } from "../../../../../../constant/FieldTypes";
import ScheduleField from "../../../common/Form/ScheduleField";
import Typography from "../../../../../../common/components/Typography";
import { RESEARCH_TAG_VISUAL_TYPE } from "../../TagCard";
import { useIcon } from "../../../../../../common/components/IconSelector";
import EventCalendar from "../../../../../../shared/components/EventCalendar";
import { EventPreview } from "./styled";

let document_extensions = [
  "doc",
  "docx",
  "pdf",
  "ppt",
  "rtf",
  "swf",
  "txt",
  "xls",
];

let image_extensions = ["jpg", "png", "svg"];

const SubTagContent = (props) => {
  const { data, tagData } = props;
  const [contentFileds, setContentFileds] = useState([]);
  const findIcon = useIcon();
  const tagId = tagData?.tagId;

  useEffect(() => {
    renderContent();
  }, [data]);

  const renderContent = async () => {
    try {
      const content = data?.tagFileds?.map((filed, i) => {
        if (
          filed?.value === "" ||
          filed.value?.length === 0 ||
          filed.value === null ||
          filed.value === undefined
        ) {
          return null;
        }

        let viewType =
          filed?.layoutTypeId !== undefined || filed?.layoutTypeId !== null
            ? filed?.layoutTypeId
            : 1;

        if (
          filed?.fieldTypeId === FieldTypes.Int ||
          filed?.fieldTypeId === FieldTypes.TextInput ||
          filed?.fieldTypeId === FieldTypes.Decimal ||
          filed?.fieldTypeId === FieldTypes.Currency ||
          filed?.fieldTypeId === FieldTypes.Email ||
          filed?.fieldTypeId === FieldTypes.TextArea ||
          filed?.fieldTypeId === FieldTypes.Address ||
          filed?.fieldTypeId === FieldTypes.Telephone
        ) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              render={filed?.value}
              icon={filed?.icon}
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.Relationship) {
          console.log("Relationship filed: ", filed);
          const Icon = findIcon(filed?.icon || "fill-users");
          return (
            <ContentItem
              key={i}
              viewType={viewType}
              icon={<Icon />}
              render={
                filed?.value ? (
                  Array.isArray(filed.value) ? (
                    filed.value.length > 0 ? (
                      <>
                        {filed.value.map((val, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <Icon
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            <span>{val}</span>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No Relationships available</p>
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                        }}
                      />
                      <span>{filed.value}</span>
                    </div>
                  )
                ) : null
              }
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.WebAddress) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              icon={filed?.icon}
              render={
                filed?.value ? (
                  Array.isArray(filed.value) ? (
                    filed.value.length > 0 ? (
                      <>
                        {filed.value.map((link, index) => {
                          if (!link || typeof link !== "object") {
                            console.warn(
                              "Invalid link at index: ",
                              index,
                              ", Link: ",
                              link
                            );
                            return null;
                          }

                          return (
                            <div className="link__item" key={index}>
                              <a
                                href={link.url || "#"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link.name || "Unnamed Link"}
                              </a>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <p>No links available</p>
                    )
                  ) : (
                    (console.warn("--filed.valu is not array:: ", filed.value),
                    null)
                  )
                ) : null
              }
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.TelephoneList) {
          //console.log("field.value", filed.value);

          if (filed?.value && Array.isArray(filed?.value)) {
            return (
              <ContentItem
                key={i}
                title={filed.displayName}
                viewType={viewType}
                icon={filed?.icon}
                render={filed.value.map((d) => d.telephoneNo)}
              />
            );
          } else if (filed?.value) {
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              render={filed?.value}
              icon={filed?.icon}
            />;
          }
        } else if (filed?.fieldTypeId === FieldTypes.Select) {
          return (
            <ContentItem
              key={i}
              title={filed.displayName}
              viewType={viewType}
              icon={filed?.icon}
              render={filed?.value?.label}
            />
          );
        } else if (filed?.fieldTypeId === FieldTypes.File) {
          if (filed?.value) {
            const getFiles = () => {
              return filed?.value.map((file) => {
                let extension = file?.filePath?.split(".").pop() || "";
                extension = String(extension).toLowerCase();

                if (extension && image_extensions.indexOf(extension) !== -1) {
                  return (
                    <Image src={file?.filePath} height={75} width={150}></Image>
                  );
                }

                if (
                  extension &&
                  document_extensions.indexOf(extension) !== -1
                ) {
                  return (
                    <div className="link__item">
                      <a target="_blank" rel="noreferrer" href={file?.filePath}>
                        {file?.fileName}
                      </a>
                    </div>
                  );
                }

                return (
                  <Typography
                    variant={Typography.font_variant.small}
                    fontWeight={Typography.font_weight.regular}
                  ></Typography>
                );
              });
            };

            if (filed?.visualTypeId === RESEARCH_TAG_VISUAL_TYPE.IMAGE) {
              return null;
            }

            return (
              <ContentItem
                key={i}
                title={filed.displayName}
                icon={filed?.icon}
                viewType={viewType}
                render={<div className="file__wrapper">{getFiles()}</div>}
              />
            );
          }
        } else if (filed?.fieldTypeId === FieldTypes.Schedule) {
          const processingTimeField = data.tagFileds.find(
            (field) => field.displayName === "Processing Time"
          );
          return (
            <>
              <ContentItem
                key={i}
                title={filed.displayName}
                viewType={viewType}
                icon={filed?.icon}
                render={
                  <>
                    {/* Temporary solution for showing events items */}
                    <EventPreview>
                      <EventCalendar
                        viewMode
                        meta={{ tagId, subTagTypeId: data?.tagSubTypeId }}
                      />
                    </EventPreview>

                    {/* <ScheduleField
                      // isFullButton
                      // buttonText="Schedule"
                      value={filed.value}
                      tooltipText={null}
                    /> */}
                  </>
                }
              />
              {processingTimeField && (
                <ContentItem
                  key={`processing-time-${i}`}
                  title={processingTimeField.displayName}
                  viewType={viewType}
                  icon={null}
                  render={
                    <>
                      <Typography
                        variant={Typography.font_variant.small}
                        fontWeight={Typography.font_weight.regular}
                      >
                        {processingTimeField.value}
                      </Typography>
                    </>
                  }
                />
              )}
            </>
          );
        } else {
          return null;
        }

        return null;
      });

      setContentFileds(content);
    } catch (err) {
      console.log(err);
    }
  };

  return contentFileds;
};

export default SubTagContent;
