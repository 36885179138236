import styled from "styled-components";
import { fontStyle } from "../../../../../../styled/utility";
import ListItem from "../../../../../../common/components/UI/ListItem";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  gap: var(--layout-spacing-8);

  .filters {
    display: flex;
    margin-bottom: var(--layout-spacing-4);

    .select-date {
      width: 14.625rem;
    }
  }

  .columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--layout-spacing-8);
  }

  .chart {
    border-radius: var(--layout-border-radius-8);
    background-color: var(--font-default-white);
    padding: 0 var(--layout-spacing-24);
  }
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  gap: var(--layout-spacing-8);

  .column-head,
  .column-body {
    border-radius: var(--layout-border-radius-4);
    background-color: var(--font-default-white);
  }

  .column-head {
    padding: var(--layout-spacing-8) var(--layout-spacing-28);
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-16);

    .column-order {
      width: var(--layout-size-28);
      height: var(--layout-size-28);
      min-width: var(--layout-size-28);
      min-height: var(--layout-size-28);

      .number {
        border-radius: 999px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--font-default-white);
        background-color: var(--color-secondary-200);
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        height: inherit;
        width: inherit;
      }
    }

    .column-icon {
      min-height: var(--layout-size-28);

      .icon__button__element {
        --icon-button-icon-color: var(--color-neutral-900);
      }
    }

    .column-title {
      ${fontStyle("base-medium")};
      font-weight: 500;
      color: var(--color-neutral-900);
    }
  }

  .column-body {
    padding: var(--layout-spacing-8) var(--layout-spacing-16);
  }
`;

export const ColumnItem = styled(ListItem)`
  gap: var(--layout-spacing-12);
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  border-radius: var(--layout-border-radius-4);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: var(--layout-spacing-4);
  }

  &:hover {
    opacity: 0.7;
  }

  ${({ $isSelected }) => {
    if ($isSelected) {
      return `
        background-color: var(--color-accent-100);

        .caption {
          color: var(--font-default-white);
        }
      `;
    }
  }}

  .start {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
  }

  .center {
    flex: 1;
  }
  .start {
  }
`;
