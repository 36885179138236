import React from "react";
import { useFormik } from "formik";

import { onUpdateDoSubmitState, useTravelPackWizardStore } from "../store";

const useFormSubmission = ({
  key,
  initialValues,
  onSubmitted,
  validationSchema,
  enableReinitialize = false,
}) => {
  const formShouldSubmit = useTravelPackWizardStore((state) => state.doSubmit);

  const onSubmit = React.useCallback(
    (values, options) => {
      onSubmitted(values, { ...options, stepKey: key });
    },
    [key, onSubmitted]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize,
    onSubmit: onSubmit,
  });

  const { handleSubmit } = formik;

  React.useEffect(() => {
    if (formShouldSubmit) {
      handleSubmit();
      onUpdateDoSubmitState();
    }
  }, [formShouldSubmit, handleSubmit]);

  return { formik };
};

export default useFormSubmission;
