import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.04047 2.66504H4.9878V3.71771H6.04047V2.66504Z"
      fill="currentColor"
    />
    <path
      d="M6.04047 4.88364H4.9878V5.93631H6.04047V4.88364Z"
      fill="currentColor"
    />
    <path
      d="M6.04047 7.10243H4.9878V8.1551H6.04047V7.10243Z"
      fill="currentColor"
    />
    <path
      d="M6.04047 9.32121H4.9878V10.3739H6.04047V9.32121Z"
      fill="currentColor"
    />
    <path
      d="M14.471 2.66504H13.4184V3.71771H14.471V2.66504Z"
      fill="currentColor"
    />
    <path
      d="M14.471 4.88364H13.4184V5.93631H14.471V4.88364Z"
      fill="currentColor"
    />
    <path
      d="M14.471 7.10243H13.4184V8.1551H14.471V7.10243Z"
      fill="currentColor"
    />
    <path
      d="M14.471 9.32121H13.4184V10.3739H14.471V9.32121Z"
      fill="currentColor"
    />
    <path
      d="M8.25722 8.933C8.2226 8.933 8.18816 8.92416 8.15667 8.90685C8.09147 8.8702 8.05132 8.8015 8.05132 8.72729V4.49745C8.05132 4.42305 8.09147 4.35436 8.15667 4.31789C8.22186 4.28217 8.30142 4.2829 8.36477 4.32231L11.8093 6.43705C11.8705 6.47443 11.9073 6.54073 11.9073 6.61219C11.9073 6.68383 11.8703 6.75012 11.8093 6.78732L8.36495 8.90242C8.33199 8.92287 8.2946 8.933 8.25722 8.933ZM8.46311 4.86522V8.35952L11.3084 6.61219L8.46311 4.86522Z"
      fill="currentColor"
    />
    <path
      d="M13.7184 1.18806C14.4596 1.18806 15.0627 1.79119 15.0627 2.53244V10.5801C15.0627 11.3214 14.4596 11.9245 13.7184 11.9245H5.67066C4.92941 11.9245 4.32628 11.3214 4.32628 10.5801V2.53244C4.32628 1.79119 4.92941 1.18806 5.67066 1.18806H13.7184ZM13.7184 0.776459H5.67066C4.70086 0.776459 3.91467 1.56265 3.91467 2.53244V10.5801C3.91467 11.5499 4.70086 12.3361 5.67066 12.3361H13.7184C14.6882 12.3361 15.4743 11.5499 15.4743 10.5801V2.53244C15.4742 1.56265 14.688 0.776459 13.7184 0.776459Z"
      fill="currentColor"
    />
    <path
      d="M12.3651 12.7475H11.9417L11.9832 12.9177C12.0712 13.2781 12.0134 13.6516 11.8204 13.9685C11.6274 14.2857 11.3224 14.5083 10.962 14.5963L3.85832 16.3249C3.49865 16.4122 3.12535 16.3551 2.80749 16.1617C2.49036 15.9689 2.26771 15.6638 2.17968 15.3033L0.451687 8.20022C0.363657 7.83981 0.422037 7.46633 0.614855 7.14939C0.807672 6.83226 1.11283 6.60961 1.47323 6.52158L3.50325 6.02766V5.60408L1.376 6.1214C0.908593 6.23521 0.513566 6.52416 0.263105 6.93521C0.0131972 7.34589 -0.061941 7.82987 0.051687 8.29727L1.78023 15.4004C1.89349 15.8678 2.18244 16.2628 2.59368 16.5133C2.8795 16.687 3.20012 16.7765 3.52646 16.7765C3.66918 16.7765 3.81338 16.7591 3.95574 16.7242L11.0594 14.9956C11.5268 14.8824 11.9219 14.5934 12.1718 14.1822C12.4222 13.7715 12.4968 13.2875 12.3832 12.8201L12.3651 12.7475Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
