import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Button from "../../../../../../../common/components/Buttons/UIButton";
import FieldGroup from "../../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../../common/components/Form/InputField";
import SelectField from "../../../../../../../common/components/Form/SelectField";
import { Operators, Units, UnitsToDays, ColumType} from "../../../constant";
import { useMilestone } from "../../../context/MilestoneContext";
import { getMilestoneTracker } from "../../../../../api";

function getEndLevelRows(sections, headers) {
  const endLevelRows = [];

  const deliverablesKey = headers.find(header => header.type === "deliverables")?.key;
  const dueKey = headers.find(header => header.type === "due")?.key;

  console.log("Deliverables Key:", deliverablesKey);
  console.log("Due Key:", dueKey);

  function traverse(section) {
    // if (!section.subRows || section.subRows.length === 0 ) {

      const DeliverablesDueCellData = Object.values(section) 
      .filter(cell => cell.columnId !== deliverablesKey  && cell.columnId !== dueKey && cell.cellId !== undefined && (cell.value?.due !== null)) 
      .map(cell => ({
        cellId: cell.cellId,
        deliverableId: cell.deliverableId,
        columnId: cell.columnId,
        label: cell.label,
        value: cell.value,
      }));
      // this is.an endlevel row
      //console.log("deliverablesKey section---",section[deliverablesKey]);
      //console.log("duekey section---",section[dueKey]);
      if (section[deliverablesKey].label && deliverablesKey) {
        endLevelRows.push({
          label: section[deliverablesKey].label,
          value: section.id,//deliverableEvent's row id 
          labelCellId: section[deliverablesKey].cellId, //deliverableEventCellId
          dueCells: DeliverablesDueCellData //used to compare and calculate due date of selected deliverable /
        });
      }
      
    // } else {
    //   section.subRows.forEach(traverse);
    // }
    if (section.subRows && section.subRows.length > 0) {
      section.subRows.forEach(traverse);
    }
  }

  sections.forEach(traverse);
  return endLevelRows;
}

const initialValues = {
  value: "",
  unit: null,
  operator: null,
  deliverableEventCellId: 0,
};

// dateFormat library casuing issue when using exising or adding new
//Temp formater
function formatDateLocal(date) { 
  if (date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

const validationSchema = Yup.object().shape({
  value: Yup.number()
    .typeError("Enter numbers only")
    .required("Value field is required"),
  unit: Yup.string().required("Unit field is required").nullable(),
  operator: Yup.string().required("Operator field is required").nullable(),
  deliverableEventCellId: Yup.number().required("Deliverable Event field is required"),
});

const Form = ({ body, onClose, onRootClose, visibility, onSubmit }) => {
  const { eventsList = [], selectedDueCellValues } = useMilestone();
  const [updatedcellsReady, setUpdatedCellsReady] = React.useState(false);
  const { dueDateSubmitHandler } = useMilestone();
  const [milestoneTracker, setMilestoneTracker] = React.useState(null);
  const [dropdownData, setDropdownData] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const eventId = selectedDueCellValues?.row?.original?.eventId || 0;
  const selectedRow = selectedDueCellValues?.cell?.row?.original;
  const [updatedCells, setUpdatedCells] = React.useState([]);
  const selectedCell = selectedRow && typeof selectedRow === 'object'
  ? Object.values(selectedRow).find((d) => d && d.columnId === 3)
  : undefined;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (value, options) => {

      if(value.value === "" || value.unit === null || value.operator === null || value.deliverableEventCellId === 0)
        {
          const toastId = toast.info("Cannot submit empty field values, please check again!", {
            autoClose: 1500, 
          });
          return;
        }

      if (!updatedcellsReady) {
        console.log("Cells not ready yet. Please wait...");
        const toastId = toast.info("Preparing data, wait few seconds before trying again...", {
          autoClose: 1500, 
        });
        return;
      }

      const handleEachCell = async (cells) => {
        try {
          await Promise.all(cells.map(cell => dueDateSubmitHandler(cell)));
          console.log("All cells have been saved successfully.");
          
        } catch (error) {
          console.error("Error saving cells:", error);
        }
      };

      await handleEachCell(updatedCells);
      onSubmit && onSubmit({ ...value }, options);
      console.log("Submition Complete");
    },
  });

  function calculateDueDate(selectedRowCell, selectedOptionCell, formValues) {
    const { value, operator, unit } = formValues;
  
    if (!selectedOptionCell?.value?.due?.dueDate) {
      return null;
    }
    const dueDate = new Date(selectedOptionCell.value.due.dueDate);
  
    if (isNaN(dueDate)) {
      console.error("Invalid initial due date.");
      return null;
    }
  
    let calculatedDueDate = new Date(dueDate); 
    const daysMultiplier = UnitsToDays[unit] || 0;
    const daysToAdjust = value * daysMultiplier;
  
    if (isNaN(daysToAdjust)) {
      console.error("Invalid unit or value, cannot calculate due date.");
      return null;
    }
  
    switch (operator) {
      case Operators[1].value: // on--
        calculatedDueDate = dueDate;
        break;
  
      case Operators[2].value: // b4--
        if (unit === 1) { // days
          calculatedDueDate.setDate(dueDate.getDate() - daysToAdjust);
        } else if (unit === 2) { // Weeks
          calculatedDueDate.setDate(dueDate.getDate() - daysToAdjust);
        } else if (unit === 3) { // Months
          calculatedDueDate.setMonth(dueDate.getMonth() - value);
        }
        break;
  
      case Operators[3].value: // after--
        if (unit === 1) { // days
          calculatedDueDate.setDate(dueDate.getDate() + daysToAdjust);
        } else if (unit === 2) { // weeks
          calculatedDueDate.setDate(dueDate.getDate() + daysToAdjust);
        } else if (unit === 3) { // months
          calculatedDueDate.setMonth(dueDate.getMonth() + value);
        }
        break;
  
      default:
        console.error("Invalid operator.");
        return null;
    }
    return calculatedDueDate;
  }

  const {
    touched,
    errors,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  React.useEffect(() => {
    setFieldValue("value", selectedDueCellValues?.value?.value?.value || 0);
    setFieldValue("unit", selectedDueCellValues?.value?.value?.unit || 0);
    setFieldValue(
      "operator",
      selectedDueCellValues?.value?.value?.operator || 0
    );

  }, [
    selectedDueCellValues?.value?.value?.operator,
    selectedDueCellValues?.value?.value?.unit,
    selectedDueCellValues?.value?.value?.value,
    setFieldValue,
  ]);

  React.useEffect(() => {
    if (selectedCell?.label)
    {
      const splitLabel = selectedCell.label.split(' ');
      const extractedDeliverableEventLabel = splitLabel.slice(3).join(' ');
      const matchingOption = dropdownData.find(
        (dd) => dd.label === extractedDeliverableEventLabel
      );
      
      if (matchingOption && !selectedOption ) {
        setFieldValue("deliverableEventCellId", matchingOption.labelCellId);
      } else {

        if(!selectedOption){setFieldValue("deliverableEventCellId", 0);}
      }
      console.log("==deliverableEventCellId: ", values.deliverableEventCellId);
    }
  }, [
    selectedOption,
    selectedCell,
    selectedDueCellValues?.value?.value?.operator,
    selectedDueCellValues?.value?.value?.unit,
    selectedDueCellValues?.value?.value?.value,
    setFieldValue,
    dropdownData 
  ]);

  React.useEffect(() => {
    const fetchMilestoneTracker = async () => {
      if (selectedRow && selectedRow?.projectId) {
        try {
          const trackerData = await getMilestoneTracker(selectedRow.projectId);
          const deliverablesKey = trackerData.headers.find(header => header.type === "deliverables")?.key;
          const dueKey = trackerData.headers.find(header => header.type === "due")?.key;

          const selectedRowDueCellData = Object.values(selectedRow) 
          .filter(cell => cell.columnId !== deliverablesKey && cell.columnId !== dueKey && cell.cellId !== undefined)
          .map(cell => ({
            ...cell,
          }));
      
          if (selectedOption && selectedOption.dueCells.length > 0) {
            const calculatedCells = selectedRowDueCellData.map(selectedCell => {
              const matchingDueCell = selectedOption.dueCells.find(
                dueCell => dueCell.columnId === selectedCell.columnId
              );
      
              if (matchingDueCell) {
                const calculatedDueDate = calculateDueDate(selectedCell, matchingDueCell, values);
                const formattedDate = formatDateLocal(calculatedDueDate);
      
                if (calculatedDueDate && !isNaN(calculatedDueDate.getTime())) {
                  return {
                    dueId: selectedCell.value.due? selectedCell.value.due.dueId : 0,
                    dueDate: formattedDate,
                    cellId: selectedCell.cellId,
                    rowId: selectedRow.id || 0,
                  };
                }
              }
              return null;
            }).filter(cell => cell !== null);
      
            setUpdatedCells(calculatedCells);
          } else {
            console.log("No due cells in selectedOption to compare.;");
          }   

        } catch (error) {
          console.error("Failed to fetch milestone tracker:", error);
        }
      }else{ console.error("----Failed");}
    };
    fetchMilestoneTracker();
  }, [selectedRow, selectedOption, values, formik.handleChange]);
  
  React.useEffect(() => {
    if (updatedCells.length > 0) {
      setUpdatedCellsReady(true);
      console.log("Updated Cells after setting state: ", updatedCells);
    }
  }, [updatedCells]);
  

  React.useEffect(() => {
    const initialOption = dropdownData.find((d) => d.labelCellId === values.deliverableEventCellId);
    setSelectedOption(initialOption || null);
  }, [values.deliverableEventCellId, selectedRow]);

  React.useEffect(() => {

    const fetchMilestoneTracker = async () => {
      if (selectedRow && selectedRow.projectId) {
        try {
          const trackerData = await getMilestoneTracker(selectedRow.projectId);
          setMilestoneTracker(trackerData);
          
          const endLevelRows = getEndLevelRows(trackerData?.sections, trackerData?.headers);
          setDropdownData(endLevelRows);
        } catch (error) {
          console.error("Failed to fetch milestone tracker:", error);
        }
      }else{setDropdownData([]);}
    };
  
    fetchMilestoneTracker();
  }, [selectedRow]);

  React.useEffect(() => {
    console.log("Selected opt (after update): ", selectedOption);
  }, [selectedOption]);

  return (
    <form className="modal-form" onSubmit={handleSubmit}>
      <div className="start">
        <FieldGroup label="Value" error={touched.value && errors.value}>
          <InputField
            name="value"
            value={values.value}
            onChange={handleChange}
          />
        </FieldGroup>

        <FieldGroup label="Unit" error={touched.unit && errors.unit}>
          <SelectField
            value={Units.find((d) => +d.value === values.unit) || null}
            options={Units}
            onChange={(data) => {
              setFieldValue("unit", data.value);
            }}
          />
        </FieldGroup>

        <FieldGroup
          label="Operator"
          error={touched.operator && errors.operator}
        >
          <SelectField
            value={Operators.find((d) => +d.value === values.operator) || null}
            options={Operators}
            onChange={(data) => {
              setFieldValue("operator", data.value);
            }}
          />
        </FieldGroup>

        <FieldGroup label="Deliverable Event" error={errors.deliverableEventCellId}>
          <SelectField
            value={selectedOption || null}
            options={dropdownData}
            onChange={(data) => {
              console.log("****selected option details", data);
              setFieldValue("deliverableEventCellId",data.labelCellId);
            }}
          />
        </FieldGroup>
      </div>

      {/* Actions */}
      <div className="end">
        <Button
          type="button"
          size={Button.Sizes.Small}
          variant={Button.Variant.Danger}
          onClick={onClose}
        >
          Cancel
        </Button>

        <div className="right">
          <Button
            type="button"
            size={Button.Sizes.Small}
            variant={Button.Variant.Info}
            onClick={resetForm}
          >
            Clear
          </Button>
          <Button
            type="submit"
            size={Button.Sizes.Small}
            variant={Button.Variant.Success}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
