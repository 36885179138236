import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";

import { TitleBar } from "./title-bar";
import { DialogUtility, Dialog } from "@syncfusion/ej2-react-popups";
import { ListView } from "@syncfusion/ej2-react-lists";
import { registerLicense } from "@syncfusion/ej2-base";

import API_Template from "../../../services/api-template";
import API_FileUpload from "../../../services/api-fileupload";
import API_ENDPOINTS from "../../../services/api-endpoints";
import { toast } from "react-toastify";

import "/node_modules/@syncfusion/ej2-base/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-popups/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-lists/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-navigations/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css";
import "/node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css";
//import "/node_modules/@syncfusion/ej2-react-documenteditor/styles/fabric.css";

import "../../assets/css/fabric.css";
import "../../assets/css/template_builder.css";

import { TemplateBase } from "./template-base";
import withRouter from "../../../hoc/withRouter";
import { ROUTE_OPTIONS } from "../../../constant/route-options";
import { getTemplateDocById } from "../../../Actions/Templates";

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

DocumentEditorContainerComponent.Inject(Toolbar);

const routeId = ROUTE_OPTIONS.templates.children["create-templates"].id;

let USER = JSON.parse(localStorage.getItem("user"));

let TemplateTypeList = [];

export class Template extends TemplateBase {
  constructor(props) {
    super(props);

    this.state = {
      templateId: 0,
      allTemplateTypes: [],
      show: false,
      templateTypeId: 0,
      TemplateName: "",
      isEditMode: false,
    };

    const getAndBindTemplateTypeList = async (e) => {
      try {
        this.setState({ show: true }); //Loading Indicator
        const endpoints = new API_ENDPOINTS();
        const response = API_Template.get(
          endpoints.TEMPLATE_GET_TEMPLATE_TYPE
        ).then((response) => {
          if (response.data.isSuccess === true) {
            TemplateTypeList = response.data.result;
            const templateType_list = TemplateTypeList.map((item) => {
              return (
                <option value={item.tenantTemplateTypeId}>
                  {item.templateTypeName}
                </option>
              );
            });
            this.setState({ allTemplateTypes: templateType_list });
            this.setState({ show: false }); //Hide Loading Indicator
          } else {
            toast.error(response.data.displayMessage);
            this.setState({ show: false }); //Hide Loading Indicator
            return;
          }
        });
      } catch (err) {
        console.log(err);
        this.setState({ show: false }); //Hide Loading Indicator
      }
    };

    getAndBindTemplateTypeList();
  }

  // API Call to load template
  loadDocument = (templateId) => {
    if (!templateId) return;

    getTemplateDocById(templateId)
      .then((doc) => {
        if (!doc) return;

        this.container.documentEditor.open(doc);
      })
      .catch((err) => {
        console.error(err);
      });

    //
  };

  // componentDidMount() {
  //   console.log('Dismount');
  //   const { isNotificationOpen } = this.props;
  //   isNotificationOpen && this.notify();
  // };

  // componentDidUpdate(prevProps) {
  //   console.log('Update');
  //   const { isNotificationOpen } = this.props;

  //   if (prevProps.isNotificationOpen !== isNotificationOpen) {
  //     isNotificationOpen && this.notify();
  //   }
  // };

  notify = () => toast("Wow so easy!");

  saveTemplate = async (e) => {
    this.notify();

    let isEditMode = false;
    if (this.state.TemplateName.length === 0) {
      toast.error("Please enter a Template Name");
      return;
    }
    if (this.state.TemplateTypeId === 0) {
      toast.error("Please select the Template Type");
      return;
    }

    if (this.state.templateId == 0) {
      isEditMode = false;
    } else {
      isEditMode = true;
    }

    this.setState({ show: true }); //show Loading Indicator

    try {
      //let tenantId = this.state.USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();
      let End_point = "";
      this.container.documentEditor.saveAsBlob("Docx").then((blob) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          var base64Text = ";base64,";

          End_point = endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT;
          console.log(End_point);
          const response = API_FileUpload.post(End_point, {
            fileName: this.container.documentEditor.documentName + ".docx",
            containerName: process.env.REACT_APP_TEMPLATES_CONTAINER,
            documentData: fileReader.result.substring(
              fileReader.result.indexOf(base64Text) + base64Text.length
            ),
          }).then((response) => {
            console.log(response);
            if (response.data.isSuccess === true) {
              ////////////////////////////////////////////////////////////////
              ///UPDATE TEMPLATE DETAILS IN DB
              ////////////////////////////////////////////////////////////////
              //Create
              let templateUrl = response.data.blob.uri;
              if (this.state.templateId === 0) {
                End_point = endpoints.TEMPLATE_CREATE;
                console.log(End_point);
                isEditMode = false;
                const response = API_Template.post(End_point, {
                  templateId: this.state.templateId,
                  globalTemplateId: 0,
                  templateTypeId: this.state.templateTypeId,
                  templateName: this.state.TemplateName,
                  templateDescription: "",
                  templateFilePath: templateUrl,
                }).then((response) => {
                  console.log(response);
                  if (response.data.isSuccess === true) {
                    if (!isEditMode) {
                      // toast.success("Template created successfully");
                    } else {
                      // toast.success("Template updated successfully");
                    }
                    this.setState({
                      templateId: response.data.result.templateId,
                    });
                    this.setState({ show: false }); //Hide Loading Indicator
                    console.log("Successfully Saved");
                  } else {
                    console.log(response.data.displayMessage);
                    toast.error(response.data.result.message);
                    this.setState({ show: false }); //Hide Loading Indicator
                    return;
                  }
                });
              } else {
                // Update
                End_point = endpoints.TEMPLATE_UPDATE;
                isEditMode = true;
                const response = API_Template.put(
                  End_point + "/" + this.state.templateId,
                  {
                    templateId: this.state.templateId,
                    globalTemplateId: 0,
                    templateTypeId: this.state.templateTypeId,
                    templateName: this.state.TemplateName,
                    templateDescription: "",
                    templateFilePath: templateUrl,
                  }
                ).then((response) => {
                  console.log("Template Updates: ", response);
                  if (response.data.isSuccess === true) {
                    if (!isEditMode) {
                      const { templateTypeId, templateName } =
                        response.data.result;

                      // toast.success("Template created successfully");
                    } else {
                      // toast.success("Template updated successfully");
                    }
                    this.setState({ show: false }); //Hide Loading Indicator
                  } else {
                    console.log(response.data.displayMessage);
                    toast.error(response.data.displayMessage);
                    this.setState({ show: false }); //Hide Loading Indicator
                    return;
                  }
                });
              }

              ////////////////////////////////////////////////////////////////
              console.log("Template File Successfully Uploaded");
            } else {
              console.log(response.data.displayMessage);
              toast.error(response.data.result.message);
              this.setState({ show: false }); //Hide Loading Indicator
              return;
            }
          });
        };
        fileReader.readAsDataURL(blob);
      });
    } catch (err) {
      console.log(err);
      this.setState({ show: false }); //Hide Loading Indicator
    }
  };

  saveTemplateOld = async (e) => {
    console.log("Save Template Start");

    this.notify();

    //return;

    let isEditMode = false;
    if (this.state.TemplateName.length === 0) {
      toast.error("Please enter a Template Name");
      return;
    }
    if (this.state.TemplateTypeId === 0) {
      toast.error("Please select the Template Type");
      return;
    }

    if (this.state.templateId == 0) {
      isEditMode = false;
    } else {
      isEditMode = true;
    }

    this.setState({ show: true }); //show Loading Indicator

    try {
      //Generate Document Template

      // let base64Data = await this.container.documentEditor.saveAsBlob('Docx');

      // this.container.documentEditor.saveAsBlob('Docx').then((blob) => {
      //     let fileReader = new FileReader();
      //     fileReader.onload = () => {
      //       let base64String = fileReader.result;
      //       let responseData = {
      //         fileName: this.container.documentEditor.documentName + '.docx',
      //         documentData: base64String,
      //       };
      //       console.log(base64String);

      //       let tenantId = USER.result.tenantId;
      //       const endpoints = new API_ENDPOINTS();

      //       const response = API_FileUpload.post(endpoints.FILE_STREAM_UPLOAD_ENDPOINT, {
      //         ContainerName: "loop-templates",
      //         ImageData: [responseData.documentData],
      //         ImageName: [responseData.fileName],
      //         ImageType: ["docx"],
      //       }).then((response) => {
      //         console.log("Upload: ", response);
      //         if (response.data.isSuccess === true) {
      //           //setShow(false); //Loading Indicator off
      //         } else {
      //           console.log(response.data.displayMessage);
      //           toast.error(response.data.displayMessage);
      //           //setShow(false); //Loading Indicator off
      //           return;
      //         }
      //       });

      //     };
      //     fileReader.readAsDataURL(blob);
      // });

      // this.container.documentEditor.saveAsBlob('Docx').then((blob) => {
      //     let fileReader = new FileReader();
      //     fileReader.onload = () => {
      //       let base64String = fileReader.result;
      //       let responseData = {
      //         fileName: this.container.documentEditor.documentName + '.docx',
      //         documentData: base64String,
      //       };
      //       console.log(base64String);

      //       let tenantId = USER.result.tenantId;
      //       const endpoints = new API_ENDPOINTS();

      //       const response = API_FileUpload.post(endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT, {
      //         ContainerName: "loop-templates",
      //         FileData: responseData.documentData,
      //         FileName: responseData.fileName,
      //         FileType: "docx",
      //       }).then((response) => {
      //         console.log("Upload: ", response);
      //         if (response.data.isSuccess === true) {
      //           //setShow(false); //Loading Indicator off
      //         } else {
      //           console.log(response.data.displayMessage);
      //           toast.error(response.data.displayMessage);
      //           //setShow(false); //Loading Indicator off
      //           return;
      //         }
      //       });

      //     };
      //     fileReader.readAsDataURL(blob);
      // });

      // this.container.documentEditor.saveAsBlob('Docx').then((blob) => {

      //   var fileReader = new FileReader();

      //   fileReader.onload = function () {
      //       var base64Text = ";base64,";
      //       var documentData = {
      //           fileName: this.container.documentEditor.documentName + '.docx',
      //           documentData: fileReader.result.substring(fileReader.result.indexOf(base64Text) + base64Text.length)
      //       }
      //       var httpRequest = new XMLHttpRequest();
      //       httpRequest.open('Post', 'https://localhost:44361/DocumentApi/Save', true);
      //       httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      //       httpRequest.onreadystatechange = function () {
      //           if (httpRequest.readyState === 4) {
      //               if (httpRequest.status === 200 || httpRequest.status === 304) {
      //                   //Sucess
      //               }
      //           }
      //       };
      //       httpRequest.send(JSON.stringify(documentData));
      //   };

      //   fileReader.readAsDataURL(blob);
      // });

      // this.container.documentEditor.saveAsBlob('Docx').then((blob) => {
      // let fileReader = new FileReader();
      // fileReader.onload = () => {

      //   var base64Text = ";base64,";
      //   // var documentData = {
      //   //     fileName: this.container.documentEditor.documentName + '.docx',
      //   //     documentData: fileReader.result.substring(fileReader.result.indexOf(base64Text) + base64Text.length)
      //   // }
      //   // var documentData = {
      //   //        fileName: this.container.documentEditor.documentName + '.docx',
      //   //        documentData: blob
      //   //   }

      //   let formData = new FormData();
      //   formData.append('fileName', this.container.documentEditor.documentName + '.docx');
      //   //formData.append('data', blob);
      //   formData.append('files', blob);

      //   console.log(formData);

      //   var httpRequest = new XMLHttpRequest();
      //   httpRequest.open('Post', 'https://localhost:44361/DocumentApi/SaveToS3', true);
      //   httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      //   httpRequest.onreadystatechange = function () {
      //       if (httpRequest.readyState === 4) {
      //           if (httpRequest.status === 200 || httpRequest.status === 304) {
      //               //Sucess
      //           }
      //       }
      //   };
      //   httpRequest.send(formData);
      //httpRequest.send(JSON.stringify(documentData));

      // let tenantId = USER.result.tenantId;
      // const endpoints = new API_ENDPOINTS();

      // const response = API_FileUpload.post(endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT, {
      //   ContainerName: "loop-templates",
      //   FileData: responseData.documentData,
      //   FileName: responseData.fileName,
      //   FileType: "docx",
      // }).then((response) => {
      //   console.log("Upload: ", response);
      //   if (response.data.isSuccess === true) {
      //     //setShow(false); //Loading Indicator off
      //   } else {
      //     console.log(response.data.displayMessage);
      //     toast.error(response.data.displayMessage);
      //     //setShow(false); //Loading Indicator off
      //     return;
      //   }
      // });

      //};
      //fileReader.readAsDataURL(blob);
      //});

      //let tenantId = this.state.USER.result.tenantId;
      const endpoints = new API_ENDPOINTS();

      this.container.documentEditor.saveAsBlob("Docx").then((blob) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          var base64Text = ";base64,";
          var documentData = {
            fileName: this.container.documentEditor.documentName + ".docx",
            containerName: "loop-templates",
            documentData: fileReader.result.substring(
              fileReader.result.indexOf(base64Text) + base64Text.length
            ),
          };

          let requestURL =
            process.env.REACT_APP_API_STORAGE_URL +
            endpoints.DOCUMENT_UPLOAD_DOCUMENT_ENDPOINT;
          var httpRequest = new XMLHttpRequest();
          httpRequest.open("Post", requestURL, true);
          httpRequest.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === 4) {
              if (httpRequest.status === 200 || httpRequest.status === 304) {
                //Sucess
                let End_point = "";
                //console.log(httpRequest.responseText);
                var result = JSON.parse(httpRequest.response);
                console.log(result);
                console.log(result.blob.uri);
                let templateUrl = result.blob.uri;
                //Create
                if (this.state.templateId === 0) {
                  End_point = endpoints.TEMPLATE_CREATE;
                  console.log(End_point);
                  isEditMode = false;
                  const response = API_Template.post(End_point, {
                    templateId: this.state.templateId,
                    globalTemplateId: 0,
                    templateTypeId: this.state.TemplateTypeId,
                    templateName: this.state.TemplateName,
                    templateDescription: "",
                    templateFilePath: templateUrl,
                  }).then((response) => {
                    console.log(response);
                    if (response.data.isSuccess === true) {
                      if (!isEditMode) {
                        // toast.success("Template created successfully");
                      } else {
                        // toast.success("Template updated successfully");
                      }
                      this.setState({
                        templateId: response.data.result.templateId,
                      });
                      this.setState({ show: false }); //Hide Loading Indicator
                      console.log("Successfully Saved");
                    } else {
                      console.log(response.data.displayMessage);
                      toast.error(response.data.result.message);
                      this.setState({ show: false }); //Hide Loading Indicator
                      return;
                    }
                  });
                } else {
                  // Update
                  End_point = endpoints.TEMPLATE_UPDATE;
                  isEditMode = true;
                  const response = API_Template.put(
                    End_point + "/" + this.state.templateId,
                    {
                      templateId: this.state.templateId,
                      globalTemplateId: 0,
                      templateTypeId: this.state.TemplateTypeId,
                      templateName: this.state.TemplateName,
                      templateDescription: "",
                      templateFilePath: templateUrl,
                    }
                  ).then((response) => {
                    console.log("Template Updates: ", response);
                    if (response.data.isSuccess === true) {
                      if (!isEditMode) {
                        // toast.success("Template created successfully");
                      } else {
                        // toast.success("Template updated successfully");
                      }
                      this.setState({ show: false, isEditMode: false }); //Hide Loading Indicator
                    } else {
                      console.log(response.data.displayMessage);
                      toast.error(response.data.displayMessage);
                      this.setState({ show: false }); //Hide Loading Indicator
                      return;
                    }
                  });
                }
              }
            }
          };
          httpRequest.send(JSON.stringify(documentData));
        };
        fileReader.readAsDataURL(blob);
      });
    } catch (err) {
      console.log(err);
      this.setState({ show: false }); //Hide Loading Indicator
    }
  };

  hostUrl = "https://ej2services.syncfusion.com/production/web-services/";
  container;
  titleBar;
  toolbarOptions = [
    "New",
    "Open",
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    {
      prefixIcon: "icon icon-lp-bx-duplicate",
      tooltipText: "Insert Field",
      //text: this.onWrapText('Insert'),
      text: "Insert",
      id: "InsertField",
    },
    {
      prefixIcon: "icon icon-lp-insert-template",
      tooltipText: "Merge Document",
      //text: this.onWrapText('Merge'),
      text: "Merge",
      id: "MergeDocument",
    },
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    {
      prefixIcon: "icon icon-lp-document-layout-left",
      tooltipText: "Table of Content",
      text: "Table of Content",
      id: "TableOfContents",
    },
    "Separator",
    "Header",
    "Footer",
    {
      prefixIcon: "icon icon-lp-document-outline-badged",
      tooltipText: "Page Setup",
      text: "Page",
      id: "PageSetup",
    },
    {
      prefixIcon: "icon icon-lp-documents",
      tooltipText: "Page Setup",
      text: "Page",
      id: "PageNumber",
    },
    "Break",
    "Separator",
    "Find",
    "Separator",
    "Comments",
    {
      prefixIcon: "icon icon-lp-view-document",
      tooltipText: "Track Changes",
      text: "Track",
      id: "TrackChanges",
    },
    "Separator",
    {
      prefixIcon: "icon icon-lp-document-outline-badged",
      tooltipText: "Local Clipboard",
      text: "Clipboard",
      id: "LocalClipboard",
    },
    {
      prefixIcon: "icon icon-lp-change",
      tooltipText: "Document Permissions",
      text: "Permissions",
      id: "RestrictEditing",
    },
    "Separator",
    {
      prefixIcon: "icon icon-lp-th-list",
      tooltipText: "Document Permissions",
      text: "Form Fields",
      id: "FormFields",
    },
    {
      prefixIcon: "icon icon-lp-template",
      tooltipText: "Document Permissions",
      text: "Update Fields",
      id: "UpdateFields",
    },
  ];
  listview;
  field;
  insertFieldDialogObj = new Dialog({
    header: "Merge Field",
    content:
      '<div className="dialogContent">' +
      // tslint:disable-next-line:max-line-length
      '<label className="e-insert-field-label">Name:</label></br><input type="text" id="field_text" className="e-input" placeholder="Type a field to insert eg. FirstName">' +
      "</div>",
    showCloseIcon: true,
    isModal: true,
    width: "auto",
    height: "auto",
    close: this.closeFieldDialog,
    buttons: [
      {
        click: () => {
          let fieldNameTextBox = document.getElementById("field_text");
          let fieldName = fieldNameTextBox.value;
          if (fieldName !== "") {
            this.container.documentEditor.editor.insertField(
              "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
            );
          }
          this.insertFieldDialogObj.hide();
          this.container.documentEditor.focusIn();
        },
        buttonModel: {
          content: "Ok",
          cssClass: "e-flat",
          isPrimary: true,
        },
      },
      {
        click: () => {
          this.insertFieldDialogObj.hide();
          this.container.documentEditor.focusIn();
        },
        buttonModel: {
          content: "Cancel",
          cssClass: "e-flat",
        },
      },
    ],
  });
  Data;

  mergeDocument() {
    this.container.documentEditor.saveAsBlob("Docx").then((blob) => {
      let exportedDocumment = blob;
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let base64String = fileReader.result;
        let responseData = {
          fileName: this.container.documentEditor.documentName + ".docx",
          documentData: base64String,
        };
        // let waitingPopUp:HTMLElement = document.getElementById('waiting-popup');
        // let inActiveDiv:HTMLElement = document.getElementById('popup-overlay');
        this.showHideWaitingIndicator(true);
        let baseUrl = this.hostUrl + "api/documenteditor/MailMerge";
        let httpRequest = new XMLHttpRequest();
        httpRequest.open("POST", baseUrl, true);
        httpRequest.setRequestHeader(
          "Content-Type",
          "application/json;charset=UTF-8"
        );
        httpRequest.onreadystatechange = () => {
          if (httpRequest.readyState === 4) {
            if (httpRequest.status === 200 || httpRequest.status === 304) {
              this.container.documentEditor.open(httpRequest.responseText);
            } else {
              // Failed to merge document
              DialogUtility.alert({
                title: "Information",
                content: "failure to merge document",
                showCloseIcon: true,
                closeOnEscape: true,
              });
            }
            this.showHideWaitingIndicator(false);
          }
        };
        httpRequest.send(JSON.stringify(responseData));
      };
      fileReader.readAsDataURL(blob);
    });
  }
  showHideWaitingIndicator(show) {
    let waitingPopUp = document.getElementById("waiting-popup");
    let inActiveDiv = document.getElementById("popup-overlay");
    inActiveDiv.style.display = show ? "block" : "none";
    waitingPopUp.style.display = show ? "block" : "none";
  }
  showInsertFielddialog(container) {
    let instance = this;
    if (
      document.getElementById("insert_merge_field") === null ||
      document.getElementById("insert_merge_field") === undefined
    ) {
      let fieldcontainer = document.createElement("div");
      fieldcontainer.id = "insert_merge_field";
      document.body.appendChild(fieldcontainer);
      this.insertFieldDialogObj.appendTo("#insert_merge_field");
      fieldcontainer.parentElement.style.position = "fixed";
      fieldcontainer.style.width = "auto";
      fieldcontainer.style.height = "auto";
    }
    this.insertFieldDialogObj.close = () => {
      container.documentEditor.focusIn();
    };
    this.insertFieldDialogObj.beforeOpen = () => {
      container.documentEditor.focusIn();
    };
    this.insertFieldDialogObj.show();
    let fieldNameTextBox = document.getElementById("field_text");
    fieldNameTextBox.value = "";
  }
  closeFieldDialog() {
    this.insertFieldDialogObj.hide();
    this.container.documentEditor.focusIn();
  }
  insertField(fieldName) {
    let fileName = fieldName
      .replace(/\n/g, "")
      .replace(/\r/g, "")
      .replace(/\r\n/g, "");
    let fieldCode = "MERGEFIELD  " + fileName + "  \\* MERGEFORMAT ";
    this.container.documentEditor.editor.insertField(
      fieldCode,
      "«" + fieldName + "»"
    );
    this.container.documentEditor.focusIn();
  }
  onWrapText(text) {
    let content = "";
    let index = text.lastIndexOf(" ");
    content = text.slice(0, index);
    text.slice(index);
    content +=
      '<div className="e-de-text-wrap">' + text.slice(index) + "</div>";
    return content;
  }
  /*
    onSelect(args: SelectEventArgs) {
        let fieldName: any = args.text;
       //this.listview.selectItem(undefined);
        this.insertField(fieldName);
    } */
  rendereComplete() {
    const { location } = this.props;

    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };
    this.container.serviceUrl = this.hostUrl + "api/documenteditor/";
    this.container.documentEditor.pageOutline = "#FFE0E0";
    this.container.documentEditor.backgroundColor = "red";
    this.container.documentEditor.background = "red";

    this.container.documentEditor.acceptTab = true;
    this.container.documentEditor.resize();

    this.titleBar = new TitleBar(
      document.getElementById("documenteditor_titlebar"),
      this.container.documentEditor,
      true
    );
    this.onLoadDefault();
    // creating Custom Options

    //   let menuItems = [
    //     {
    //         text: '<<Name>>',
    //         id: 'Field_Name',
    //         iconCss: 'e-icons e-de-ctnr-find'
    //     },
    //     {
    //         text: 'Member',
    //         items: [
    //             {
    //                 text: 'Contract',
    //                 items: [
    //                     {
    //                         text: '<<Start Date>>',
    //                         id: 'Field_Start_Date',
    //                         iconCss: 'e-icons e-de-ctnr-find'
    //                     },
    //                     {
    //                         text: '<<End Date>>',
    //                         id: 'Field_End_Date',
    //                         iconCss: 'e-icons e-de-ctnr-find'
    //                     }
    //                 ]
    //             },
    //             {
    //                 text: '<<Expiry Date>>',
    //                 id: 'Field_Expiry_Date',
    //                 iconCss: 'e-icons e-de-ctnr-find'
    //             },
    //             {
    //                 text: '<<Address>>',
    //                 id: 'Field_Address',
    //                 iconCss: 'e-icons e-de-ctnr-find'
    //             },
    //             {
    //                 text: '<<Company Name>>',
    //                 id: 'Field_Company_Name',
    //                 iconCss: 'e-icons e-de-ctnr-find'
    //             }
    //         ]
    //     }
    // ];

    let menuItems = [
      {
        text: "Contractor",
        items: [
          {
            text: "Title",
            id: "Field_Title",
            iconCss: "e-icons e-menu",
          },
          {
            text: "Full Name",
            id: "Field_Full_Name",
            iconCss: "e-icons e-stamp",
          },
          {
            text: "ID Number",
            id: "Field_ID_Number",
            iconCss: "e-icons e-clear-format",
          },
          {
            text: "Residential Address",
            id: "Field_Res_Address",
            iconCss: "e-icons e-location",
          },
          {
            text: "Given Name",
            id: "Field_Given_Name",
            iconCss: "e-icons e-description",
          },
          {
            text: "Nick Name",
            id: "Field_Nick_Name",
            iconCss: "e-icons e-description",
          },
          {
            text: "Surname",
            id: "Field_Surname",
            iconCss: "e-icons e-description",
          },
          {
            text: "City",
            id: "Field_City",
            iconCss: "e-icons e-description",
          },
          {
            text: "Email",
            id: "Field_Email",
            iconCss: "e-icons e-description",
          },
          {
            text: "Phone Number",
            id: "Field_Phone",
            iconCss: "e-icons e-description",
          },
          {
            text: "Date of Birth",
            id: "Field_Date_of_Birth",
            iconCss: "e-icons e-description",
          },
        ],
      },
      {
        text: "Company",
        items: [
          {
            text: "Company Name",
            id: "Field_Company_Name",
            iconCss: "e-icons e-de-ctnr-find",
          },
          {
            text: "Signatory´s Name ",
            id: "Field_Company_Signatory_Name",
            iconCss: "e-icons e-de-ctnr-find",
          },
          {
            text: "Short Name",
            id: "Field_Company_ShortName",
            iconCss: "e-icons e-de-ctnr-find",
          },
        ],
      },
    ];
    console.log("Janaka");
    console.log(menuItems);

    // adding Custom Options
    this.container.documentEditor.contextMenu.addCustomMenu(menuItems, false);
    // custom Options Select Event
    this.container.documentEditor.customContextMenuSelect = (args) => {
      let item = args.id;
      this.handleCustomMenuId(item);
    };

    // If Edit mode
    if (location?.state?.isEditMode) {
      const { templateId, templateName, templateTypeId } = location?.state;

      // Load document template
      this.loadDocument(templateId);

      this.setState({
        templateId,
        templateTypeId,
        TemplateName: templateName,
        isEditMode: true,
      });
    }
  }

  render() {
    return (
      <div className="base__content">
        <div className="base__content__menu__area"></div>
        <div className="base__content__contain__area">
          <div className="page__content">
            <div className="page__content__iW">
              <div className="page__content__wrapper tp__builder__editor__fullwidth">
                <div className="template__builder__page__container editor_page">
                  <div className="template__builder__page__iW">
                    <div className="template__builder__page__wrapper">
                      <div className="tp__builder__editor__page__top">
                        <div className="tp__builder__editor__page__iW">
                          <div className="tp__editor__doc__detail__w">
                            <div className="tp__editor__doc__detail__name">
                              <div className="col-auto">
                                {" "}
                                <label
                                  className="visually-hidden"
                                  htmlFor="autoSizingInputGroup"
                                >
                                  Name your template
                                </label>
                                <div className="input-group editor_name_field">
                                  <div className="input-before-text icon icon-lp-document-layout-left"></div>
                                  <input
                                    className="form-control line-input"
                                    type="text"
                                    placeholder="Name your template"
                                    autoComplete="off"
                                    id="autoSizingInputGroup"
                                    onChange={(e) =>
                                      this.setState({
                                        TemplateName: e.target.value,
                                      })
                                    }
                                    value={this.state.TemplateName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tp__editor__doc__detail__type">
                              <div className="input-group input-group-loop lg">
                                <label
                                  className="input-group-text"
                                  htmlFor="inputGroupSelect01"
                                >
                                  Template Type
                                </label>
                                <select
                                  className="form-select form-select-lg"
                                  aria-label="Select Template"
                                  id="inputTemplateType"
                                  onChange={(e) =>
                                    this.setState({
                                      templateTypeId: e.target.value,
                                    })
                                  }
                                  value={this.state.templateTypeId}
                                >
                                  <option selected value={0}>
                                    --------
                                  </option>
                                  {this.state.allTemplateTypes}
                                </select>
                              </div>
                            </div>
                            <button
                              className="tp__editor__doc__detail__btn btn btn-primary icon icon-lp-save-file"
                              onClick={this.saveTemplate}
                            >
                              {this.state.isEditMode
                                ? "Update Template"
                                : "Save Template"}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tp__builder__editor__area">
                        <div className="tp__builder__editor__area__iW">
                          <div
                            id="documenteditor_titlebar"
                            className="e-de-ctn-title"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            className="col-lg-10 control-section"
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              paddingTop: "0px",
                              width: "100%",
                            }}
                          >
                            <DocumentEditorContainerComponent
                              id="container"
                              ref={(scope) => {
                                this.container = scope;
                              }}
                              style={{ display: "block" }}
                              height={"750px"}
                              width={"100%"}
                              enableToolbar={true}
                              locale="en-US"
                            />
                          </div>
                        </div>
                        <div id="listview" style={{ display: "none" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // custom Options Functionality
  handleCustomMenuId = (item) => {
    let id = this.container.documentEditor.element.id;
    console.log(id);
    console.log(item);
    let fieldName = "";
    switch (item) {
      case "Field_Title":
        fieldName = "title";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Full_Name":
        fieldName = "fullName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_ID_Number":
        fieldName = "idNumber";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Res_Address":
        fieldName = "address";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Given_Name":
        fieldName = "givenName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Nick_Name":
        fieldName = "nickName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Surname":
        fieldName = "surName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_City":
        fieldName = "city";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Email":
        fieldName = "email";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Phone":
        fieldName = "phoneNo";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Date_of_Birth":
        fieldName = "dateOfBirth";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Nick_Name":
        fieldName = "nickName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Surname":
        fieldName = "surName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_City":
        fieldName = "city";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Email":
        fieldName = "email";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Phone":
        fieldName = "phoneNo";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Date_of_Birth":
        fieldName = "dateOfBirth";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Company_Name":
        fieldName = "Company_Name";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Company_Signatory_Name":
        fieldName = "Signatury_Name";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
      case "Field_Company_ShortName":
        fieldName = "Company_ShortName";
        if (fieldName !== "") {
          this.container.documentEditor.editor.insertField(
            "MERGEFIELD " + fieldName + " \\* MERGEFORMAT"
          );
        }
        break;
    }
  };
  onLoadDefault = () => {
    {
      // tslint:disable
      // let defaultDocument = {
      //   sections: [
      //     {
      //       blocks: [
      //         {
      //           rows: [
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 83.5999984741211,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableStart:Orders  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableStart:Orders»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontSize: 24.0,
      //                         fontSizeBidi: 24.0,
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'INVOICE',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontSize: 24.0,
      //                             fontSizeBidi: 24.0,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           imageString:
      //                             'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM4AAAAJCAYAAAB68hPIAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAA+SURBVFhH7dgxEQAwDAOxgCuiYiyiEEgxxLN8Jwi/uMbM1qvuHmCnzn0D7AgHAsKBgHAgIBwICAcC7mhY6/kc4VEEyn2PzAAAAABJRU5ErkJggg==',
      //                           length: 1,
      //                           width: 103.0,
      //                           height: 4.5,
      //                           isInlineImage: false,
      //                           isMetaFile: false,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Invoice ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: 'to:',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShipName  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShipName»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShipAddress  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShipAddress»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShipCity  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShipCity»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' - ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShipPostalCode  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShipPostalCode»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShipCountry  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShipCountry»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 347.10000610351563,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                     },
      //                     cellWidth: 347.10000610351563,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [],
      //                     },
      //                     {
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [],
      //                     },
      //                     {
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Order ID',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: '\t',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ':',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  OrderID  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«OrderID»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Order',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' Date',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: '\t',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ':',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  OrderDate \\@ "dd-MMM-yyyy" \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«OrderDate»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Customer ID',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: '\t',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ':',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  CustomerID  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«CustomerID»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Required',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: 'Date:',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  RequiredDate \\@ "dd-MMM-yyyy" \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«RequiredDate»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                     {
      //                       paragraphFormat: {
      //                         styleName: 'Header',
      //                         tabs: [
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 234.0,
      //                           },
      //                           {
      //                             tabJustification: 'Left',
      //                             position: 0.0,
      //                             tabLeader: 'None',
      //                             deletePosition: 468.0,
      //                           },
      //                         ],
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Shipped',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: 'Date',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: '\t',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ':',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ShippedDate \\@ "dd-MMM-yyyy" \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ShippedDate»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableEnd:Orders  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableEnd:Orders»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 203.69999694824219,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                     },
      //                     cellWidth: 203.69999694824219,
      //                   },
      //                 },
      //               ],
      //             },
      //           ],
      //           title: null,
      //           description: null,
      //           tableFormat: {
      //             allowAutoFit: false,
      //             leftMargin: 5.4000000953674316,
      //             rightMargin: 5.4000000953674316,
      //             topMargin: 0.0,
      //             bottomMargin: 0.0,
      //             leftIndent: 0.0,
      //             tableAlignment: 'Center',
      //             preferredWidthType: 'Auto',
      //             borders: {
      //               left: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               right: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               top: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               bottom: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               vertical: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               horizontal: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               diagonalDown: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //               diagonalUp: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //             },
      //             bidi: false,
      //           },
      //         },
      //         {
      //           paragraphFormat: {
      //             styleName: 'Normal',
      //           },
      //           inlines: [],
      //         },
      //         {
      //           rows: [
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 11.600000381469727,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Product ID',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             fontColor: '#FFFFFFFF',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 182.35000610351563,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#2F5496FF',
      //                     },
      //                     cellWidth: 185.35000610351563,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         fontColor: '#FFFFFFFF',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Quantity',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             fontColor: '#FFFFFFFF',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 60.400001525878906,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#2E74B5FF',
      //                     },
      //                     cellWidth: 62.400001525878906,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         fontColor: '#FFFFFFFF',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Unit Price',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             fontColor: '#FFFFFFFF',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 64.0999984741211,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#2E74B5FF',
      //                     },
      //                     cellWidth: 66.0999984741211,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         fontColor: '#FFFFFFFF',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Discount',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             fontColor: '#FFFFFFFF',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 60.549999237060547,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#2E74B5FF',
      //                     },
      //                     cellWidth: 62.549999237060547,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         fontColor: '#FFFFFFFF',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Price',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             fontColor: '#FFFFFFFF',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 175.39999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#2E74B5FF',
      //                     },
      //                     cellWidth: 178.39999389648438,
      //                   },
      //                 },
      //               ],
      //             },
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 29.149999618530273,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         bold: true,
      //                         fontFamily: 'Calibri',
      //                         boldBidi: true,
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableStart:Order  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableStart:Order»',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ProductName  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ProductName»',
      //                           characterFormat: {
      //                             bold: true,
      //                             fontFamily: 'Calibri',
      //                             boldBidi: true,
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 182.35000610351563,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 185.35000610351563,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  Quantity  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«Quantity»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 60.400001525878906,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 62.400001525878906,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: '$',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  UnitPrice  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«UnitPrice»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 64.0999984741211,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 66.0999984741211,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Center',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  Discount  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«Discount»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 60.549999237060547,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 62.549999237060547,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: '$',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  ExtendedPrice  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«ExtendedPrice»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableEnd:Order  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableEnd:Order»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 175.39999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 178.39999389648438,
      //                   },
      //                 },
      //               ],
      //             },
      //           ],
      //           title: null,
      //           description: null,
      //           tableFormat: {
      //             allowAutoFit: false,
      //             cellSpacing: 2.0,
      //             leftMargin: 5.4000000953674316,
      //             rightMargin: 5.4000000953674316,
      //             topMargin: 0.0,
      //             bottomMargin: 0.0,
      //             leftIndent: 0.0,
      //             tableAlignment: 'Center',
      //             preferredWidthType: 'Auto',
      //             borders: {
      //               left: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               right: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               top: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               bottom: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               vertical: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               horizontal: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               diagonalDown: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //               diagonalUp: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //             },
      //             bidi: false,
      //           },
      //         },
      //         {
      //           paragraphFormat: {
      //             styleName: 'Normal',
      //           },
      //           inlines: [],
      //         },
      //         {
      //           rows: [
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 16.75,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Sub Total',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 184.39999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 187.39999389648438,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableStart:OrderTotals  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableStart:OrderTotals»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           text: '$',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  Subtotal  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«Subtotal»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 172.14999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 175.14999389648438,
      //                   },
      //                 },
      //               ],
      //             },
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 15.399999618530273,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Freight',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 184.39999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 187.39999389648438,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         afterSpacing: 0.0,
      //                         lineSpacing: 1.0,
      //                         lineSpacingType: 'Multiple',
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: '$',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  Freight  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«Freight»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 172.14999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 175.14999389648438,
      //                   },
      //                 },
      //               ],
      //             },
      //             {
      //               rowFormat: {
      //                 allowBreakAcrossPages: true,
      //                 isHeader: false,
      //                 height: 21.600000381469727,
      //                 heightType: 'AtLeast',
      //                 borders: {
      //                   left: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   right: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   top: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   bottom: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   vertical: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   horizontal: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: true,
      //                   },
      //                   diagonalDown: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                   diagonalUp: {
      //                     lineStyle: 'None',
      //                     lineWidth: 0.0,
      //                     shadow: false,
      //                     space: 0.0,
      //                     hasNoneStyle: false,
      //                   },
      //                 },
      //                 leftMargin: 5.4,
      //                 rightMargin: 5.4,
      //                 topMargin: 0.0,
      //                 bottomMargin: 0.0,
      //               },
      //               cells: [
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontFamily: 'Calibri',
      //                       },
      //                       paragraphFormat: {
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: 'Total',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 184.39999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 187.39999389648438,
      //                   },
      //                 },
      //                 {
      //                   blocks: [
      //                     {
      //                       characterFormat: {
      //                         fontColor: '#FFFFFFFF',
      //                       },
      //                       paragraphFormat: {
      //                         textAlignment: 'Right',
      //                         styleName: 'Normal',
      //                       },
      //                       inlines: [
      //                         {
      //                           text: '$',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  Total  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«Total»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                         {
      //                           text: ' ',
      //                           characterFormat: {
      //                             fontColor: '#FFFFFFFF',
      //                           },
      //                         },
      //                         {
      //                           hasFieldEnd: true,
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                           fieldType: 0,
      //                         },
      //                         {
      //                           text: ' MERGEFIELD  TableEnd:OrderTotals  \\* MERGEFORMAT ',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 2,
      //                         },
      //                         {
      //                           text: '«TableEnd:OrderTotals»',
      //                           characterFormat: {
      //                             fontFamily: 'Calibri',
      //                           },
      //                         },
      //                         {
      //                           fieldType: 1,
      //                         },
      //                       ],
      //                     },
      //                   ],
      //                   cellFormat: {
      //                     columnSpan: 1,
      //                     rowSpan: 1,
      //                     preferredWidth: 172.14999389648438,
      //                     preferredWidthType: 'Point',
      //                     verticalAlignment: 'Top',
      //                     isSamePaddingAsTable: true,
      //                     borders: {
      //                       left: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       right: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       top: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       bottom: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       vertical: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       horizontal: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalDown: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                       diagonalUp: {
      //                         lineStyle: 'None',
      //                         lineWidth: 0.0,
      //                         shadow: false,
      //                         space: 0.0,
      //                         hasNoneStyle: false,
      //                       },
      //                     },
      //                     shading: {
      //                       texture: 'TextureNone',
      //                       backgroundColor: '#E7E6E6FF',
      //                     },
      //                     cellWidth: 175.14999389648438,
      //                   },
      //                 },
      //               ],
      //             },
      //           ],
      //           title: null,
      //           description: null,
      //           tableFormat: {
      //             allowAutoFit: true,
      //             cellSpacing: 2.0,
      //             leftMargin: 5.4000000953674316,
      //             rightMargin: 5.4000000953674316,
      //             topMargin: 0.0,
      //             bottomMargin: 0.0,
      //             leftIndent: 0.0,
      //             tableAlignment: 'Right',
      //             preferredWidthType: 'Auto',
      //             borders: {
      //               left: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               right: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               top: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               bottom: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               vertical: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               horizontal: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: true,
      //               },
      //               diagonalDown: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //               diagonalUp: {
      //                 lineStyle: 'None',
      //                 lineWidth: 0.0,
      //                 shadow: false,
      //                 space: 0.0,
      //                 hasNoneStyle: false,
      //               },
      //             },
      //             shading: {
      //               texture: 'TextureNone',
      //               backgroundColor: '#FFFFFFFF',
      //             },
      //             bidi: false,
      //           },
      //         },
      //         {
      //           characterFormat: {
      //             fontSize: 16.0,
      //             fontSizeBidi: 16.0,
      //           },
      //           paragraphFormat: {
      //             textAlignment: 'Center',
      //             styleName: 'Normal',
      //           },
      //           inlines: [],
      //         },
      //         {
      //           characterFormat: {
      //             fontSize: 16.0,
      //             fontSizeBidi: 16.0,
      //           },
      //           paragraphFormat: {
      //             textAlignment: 'Center',
      //             styleName: 'Normal',
      //           },
      //           inlines: [],
      //         },
      //         {
      //           characterFormat: {
      //             fontSize: 16.0,
      //             fontSizeBidi: 16.0,
      //           },
      //           paragraphFormat: {
      //             textAlignment: 'Center',
      //             styleName: 'Normal',
      //           },
      //           inlines: [],
      //         },
      //         {
      //           characterFormat: {
      //             fontSize: 16.0,
      //             fontFamily: 'Calibri',
      //             fontSizeBidi: 16.0,
      //           },
      //           paragraphFormat: {
      //             textAlignment: 'Center',
      //             styleName: 'Normal',
      //           },
      //           inlines: [
      //             {
      //               text: 'Thank ',
      //               characterFormat: {
      //                 fontSize: 16.0,
      //                 fontFamily: 'Calibri',
      //                 fontSizeBidi: 16.0,
      //               },
      //             },
      //             {
      //               text: 'you for doing business with us!',
      //               characterFormat: {
      //                 fontSize: 16.0,
      //                 fontFamily: 'Calibri',
      //                 fontSizeBidi: 16.0,
      //               },
      //             },
      //           ],
      //         },
      //       ],
      //       headersFooters: {
      //         header: {
      //           blocks: [
      //             {
      //               paragraphFormat: {
      //                 styleName: 'Header',
      //               },
      //               inlines: [],
      //             },
      //           ],
      //         },
      //       },
      //       sectionFormat: {
      //         headerDistance: 36.0,
      //         footerDistance: 36.0,
      //         pageWidth: 612.0,
      //         pageHeight: 792.0,
      //         leftMargin: 36.0,
      //         rightMargin: 36.0,
      //         topMargin: 36.0,
      //         bottomMargin: 36.0,
      //         differentFirstPage: false,
      //         differentOddAndEvenPages: false,
      //         bidi: false,
      //         restartPageNumbering: false,
      //         pageStartingNumber: 0,
      //       },
      //     },
      //   ],
      //   paragraphFormat: {
      //     afterSpacing: 8.0,
      //     lineSpacing: 1.0791666507720947,
      //     lineSpacingType: 'Multiple',
      //   },
      //   background: {
      //     color: '#FFFFFFFF',
      //   },
      //   styles: [
      //     {
      //       type: 'Paragraph',
      //       name: 'Normal',
      //       next: 'Normal',
      //       characterFormat: {
      //         fontSize: 12.0,
      //         fontFamily: 'Times New Roman',
      //         fontColor: '#000000FF',
      //         fontSizeBidi: 12.0,
      //       },
      //       paragraphFormat: {
      //         afterSpacing: 8.0,
      //         lineSpacing: 1.0791666507720947,
      //         lineSpacingType: 'Multiple',
      //       },
      //     },
      //     {
      //       type: 'Character',
      //       name: 'Default Paragraph Font',
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Table Normal',
      //       next: 'Table Normal',
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'No List',
      //       next: 'No List',
      //       characterFormat: {
      //         fontFamily: 'Times New Roman',
      //       },
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Header',
      //       basedOn: 'Normal',
      //       next: 'Header',
      //       link: 'Header Char',
      //       paragraphFormat: {
      //         afterSpacing: 0.0,
      //         lineSpacing: 1.0,
      //         lineSpacingType: 'Multiple',
      //         tabs: [
      //           {
      //             tabJustification: 'Center',
      //             position: 234.0,
      //             tabLeader: 'None',
      //             deletePosition: 0.0,
      //           },
      //           {
      //             tabJustification: 'Right',
      //             position: 468.0,
      //             tabLeader: 'None',
      //             deletePosition: 0.0,
      //           },
      //         ],
      //       },
      //     },
      //     {
      //       type: 'Character',
      //       name: 'Header Char',
      //       basedOn: 'Default Paragraph Font',
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Footer',
      //       basedOn: 'Normal',
      //       next: 'Footer',
      //       link: 'Footer Char',
      //       paragraphFormat: {
      //         afterSpacing: 0.0,
      //         lineSpacing: 1.0,
      //         lineSpacingType: 'Multiple',
      //         tabs: [
      //           {
      //             tabJustification: 'Center',
      //             position: 234.0,
      //             tabLeader: 'None',
      //             deletePosition: 0.0,
      //           },
      //           {
      //             tabJustification: 'Right',
      //             position: 468.0,
      //             tabLeader: 'None',
      //             deletePosition: 0.0,
      //           },
      //         ],
      //       },
      //     },
      //     {
      //       type: 'Character',
      //       name: 'Footer Char',
      //       basedOn: 'Default Paragraph Font',
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Table Grid',
      //       basedOn: 'Table Normal',
      //       next: 'Table Grid',
      //     },
      //     {
      //       type: 'Character',
      //       name: 'Hyperlink',
      //       basedOn: 'Default Paragraph Font',
      //       characterFormat: {
      //         underline: 'Single',
      //         fontColor: '#0563C1FF',
      //       },
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Grid Table 4',
      //       basedOn: 'Table Normal',
      //       next: 'Grid Table 4',
      //     },
      //     {
      //       type: 'Paragraph',
      //       name: 'Grid Table 7 Colorful',
      //       basedOn: 'Table Normal',
      //       next: 'Grid Table 7 Colorful',
      //     },
      //   ],
      //   defaultTabWidth: 36.0,
      //   formatting: false,
      //   trackChanges: false,
      //   protectionType: 'NoProtection',
      //   enforcement: false,
      //   dontUseHTMLParagraphAutoSpacing: false,
      // };
      // tslint:enable
    }

    //this.container.documentEditor.open(JSON.stringify(defaultDocument));
    this.container.documentEditor.documentName = "Loop-NDA";
    let item = this.toolbarOptions;
    this.container.toolbarItems = item;

    this.titleBar.updateDocumentTitle();
    this.container.documentChange = () => {
      this.titleBar.updateDocumentTitle();
      this.container.documentEditor.focusIn();
    };
    document
      .getElementById("listview")
      .addEventListener("dragstart", (event) => {
        event.dataTransfer.setData("Text", event.target.innerText);
        event.target.classList.add("de-drag-target");
      });
    // Prevent default drag over for document editor element
    document
      .getElementById("container")
      .addEventListener("dragover", (event) => {
        event.preventDefault();
      });
    // Drop Event for document editor element
    document.getElementById("container").addEventListener("drop", (e) => {
      let text = e.dataTransfer.getData("Text");
      this.container.documentEditor.selection.select({
        x: e.offsetX,
        y: e.offsetY,
        extend: false,
      });
      this.insertField(text);
    });
    document.addEventListener("dragend", (event) => {
      if (event.target.classList.contains("de-drag-target")) {
        event.target.classList.remove("de-drag-target");
      }
    });
    this.Data = [
      {
        text: "ProductName",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "ShipName",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "CustomerID",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "Quantity",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "UnitPrice",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "Discount",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "ShipAddress",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "ShipCity",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "ShipCountry",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "OrderId",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
      {
        text: "OrderDate",
        category: "Drag or click the field to insert.",
        htmlAttributes: { draggable: true },
      },
    ];
    //this.field = {tooltip:'category',htmlAttributes: { draggable: true }};
    let listDivElement = document.getElementById("listview");
    let listView = new ListView({
      dataSource: this.Data,
      fields: { tooltip: "category" },
      select: onSelect.bind(this),
    });
    listView.appendTo(listDivElement);
    function onSelect(args) {
      let fieldName = args.text;
      listView.selectItem(undefined);
      this.insertField(fieldName);
    }
    this.container.toolbarClick = (args) => {
      switch (args.item.id) {
        case "MergeDocument":
          this.mergeDocument();
          break;
        case "InsertField":
          this.showInsertFielddialog(this.container);
          break;
      }
    };
  };
}

export default withRouter(Template, { routeId });

//   const CreateTemplate = () => {

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//     } catch (err) {
//       console.log(err);
//     }
//   };
