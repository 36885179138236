import React from "react";
import { format } from "date-fns";

import * as Styled from "./styled";
import TimeBlocks from "./TimeBlocks";
import { useCalendar } from "../hooks/useCalendar";

const EventPanel = () => {
  const { currentSelectDate } = useCalendar();

  let currentHours = new Date().getHours();

  const eventBodyRef = React.useRef();
  const [isScrolling, setIsScrolling] = React.useState();

  React.useEffect(() => {
    if (!eventBodyRef?.current) return;

    const ele = eventBodyRef?.current;

    let timer;

    const scroll = () => {
      clearTimeout(timer);
      setIsScrolling(true);

      timer = setTimeout(() => setIsScrolling(false), 500);
    };

    ele?.addEventListener("scroll", scroll);

    return () => {
      ele.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <Styled.Wrapper>
      <div className="event__inner">
        <div className="event__head">
          <div className="date">{format(currentSelectDate, "dd MMM yyyy")}</div>
          <div className="day">{format(currentSelectDate, "EEEE")}</div>
        </div>

        <div className="event__body" ref={eventBodyRef}>
          <TimeBlocks
            currentSelectDate={currentSelectDate}
            isScrolling={isScrolling}
            eventBodyRef={eventBodyRef?.current}
            currentHours={currentHours}
          />
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default EventPanel;
