import React from "react";
import produce from "immer";

import SearchList from "../../../../../../../common/components/UI/SearchList";
import ListItem from "../../../../../../../common/components/UI/ListItem";
import SelectionField from "../../../../../../../common/components/Form/SelectionField";
import { MemberItem } from "../../../styled";

const MemberSearch = ({ members, value = [], onChange }) => {
  const [allMembers, setAllMember] = React.useState([]);
  const [searchResult, setSearchResult] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    const updated = produce(members, (draft) => {
      draft.forEach((m) => {
        const isChecked = value
          .map((d) => String(d?.id))
          .includes(String(m?.id));
        m.isChecked = isChecked;
      });
    });

    setAllMember(updated);
  }, [members, value]);

  React.useEffect(() => {
    const result = allMembers.filter(
      (d) => d.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    setSearchResult(result);
  }, [allMembers, searchText, value]);

  const memberSelectHandler = React.useCallback(
    (_, data) => {
      let result = [...value];

      const isExist = result.find((d) => String(d?.id) === String(data?.id));

      if (isExist) {
        result = result.filter((d) => String(d?.id) !== String(data?.id));
        onChange && onChange(result);
        return;
      }

      result = [...result].concat(data);
      onChange && onChange(result);
    },
    [onChange, value]
  );

  return (
    <SearchList
      data={searchResult}
      onRootClose={() => {
        setSearchText("");
      }}
      onSearch={(val) => {
        setSearchText(val);
      }}
      render={({ key, data }) => {
        return (
          <Item
            key={key}
            src={data?.src}
            name={data?.name}
            designation={data?.designation}
            isChecked={data?.isChecked}
            onChange={(isChecked) => {
              memberSelectHandler(isChecked, data);
            }}
          />
        );
      }}
    />
  );
};

function Item({ src, name, designation, isChecked, onChange }) {
  return (
    <MemberItem
      data={{
        src: src,
        title: name,
        caption: designation,
      }}
    >
      <div className="start">
        <MemberItem.Avatar size="size-36" />
      </div>

      <div className="center">
        <ListItem.Title />
        <ListItem.Caption />
      </div>

      <div className="end">
        <SelectionField
          checked={isChecked}
          onChange={(e) => onChange && onChange(e.target.checked)}
        />
      </div>
    </MemberItem>
  );
}

export default MemberSearch;
