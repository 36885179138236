import React from "react";
import Form from "./Form";
import { Modal } from "./styled";
import { useCalendar } from "../hooks/useCalendar";

const EventDialog = () => {
  const { isDialogVisible, closeDialogHandler, dialogFormValues } =
    useCalendar();

  return (
    <Modal
      visibility={isDialogVisible}
      title={dialogFormValues ? "Update Time" : "Set New Time"}
      subTitle="Manage your Event specific details here"
      bodyComponent={() => <Form />}
      onClose={() => {
        closeDialogHandler();
      }}
      // onOutsideClick={() => setAnchorEl(null)}
      // appendTo={document.querySelector('.time__container')}
    />
  );
};

export default EventDialog;
