import React from "react";
import { addYears } from "date-fns";
import { DayPicker } from "react-day-picker";

// Day picker css
import "react-day-picker/dist/style.css";

import DatePickerHeader from "./DatePickerHeader";
import { UIDatePickerWrapper } from "./styled";

const FromDate = new Date(1917, 0, 1);
const ToDate = addYears(new Date(), 50);

const DatePicker = (props, ref) => {
  // Props
  const { fromDate, toDate, showOutsideDays = true, ...restProps } = props;

  const [fDate, setFDate] = React.useState(FromDate);
  const [tDate, setTDate] = React.useState(ToDate);

  React.useEffect(() => {
    if (fromDate) {
      setFDate(fromDate);
    }

    if (toDate) {
      setTDate(toDate);
    }
  }, [fromDate, toDate]);

  return (
    <UIDatePickerWrapper ref={ref}>
      <DayPicker
        ISOWeek
        showOutsideDays={showOutsideDays}
        weekStartsOn={1}
        fromDate={fDate}
        toDate={tDate}
        fixedWeeks
        components={{
          Caption: DatePickerHeader,
        }}
        {...restProps}
      />
    </UIDatePickerWrapper>
  );
};

export default React.forwardRef(DatePicker);
