import * as LineIcon from "../../icons/Line";

// Research icons
import IconContributor01 from "../../icons/Research/Contributor/01";
import IconArchive01 from "../../icons/Research/Archive/01";
import IconBudget01 from "../../icons/Research/Budget/01";
import IconLegal01 from "../../icons/Research/Legal/01";
import IconReference01 from "../../icons/Research/Reference/01";
import IconResearchData01 from "../../icons/Research/ResearchData/01";
import IconStoryBeat01 from "../../icons/Research/StoryBeat/01";
import IconTalent01 from "../../icons/Research/Talent/01";
import IconTravelPack01 from "../../icons/Research/TravelPack/01";
import IconCalendar01 from "../../icons/Research/Calendar/01";
import IconSchedule01 from "../../icons/Research/Schedule/01";
import IconTeam01 from "../../icons/Research/Team/01";
import IconAgent01 from "../../icons/Research/Agent/01";
import IconWardrobe01 from "../../icons/Research/Wardrobe/01";
import IconContract01 from "../../icons/Research/Contract/01";
import IconDocuments01 from "../../icons/Research/Documents/01";
import IconTemplate01 from "../../icons/Research/Template/01";
import IconProject01 from "../../icons/Research/Project/01";
import IconContact01 from "../../icons/Research/contact/01";
import IconContact02 from "../../icons/Research/contact/02";
import IconMeals01 from "../../icons/Research/meals/01";
import IconLogistics01 from "../../icons/Research/logistics/01";
import IconStyle01 from "../../icons/Research/style/01";
import IconKit01 from "../../icons/Research/kitlist/01";
import IconProps01 from "../../icons/Research/props/01";
import IconTimeline01 from "../../icons/Research/timeline/01";
import IconMoodboard01 from "../../icons/Research/moodboard/01";

import IconDocumentsfiles01 from "../../icons/Research/Documentsfiles/01";
import IconFieldnotes01 from "../../icons/Research/Fieldnotes/01";
import IconInterviewnotes01 from "../../icons/Research/interviewnotes/01";
import IconScript01 from "../../icons/Research/Script/01";
import IconShortlist01 from "../../icons/Research/Shortlist/01";
import IconShotbrief01 from "../../icons/Research/Shotbrief/01";
import IconTranscript01 from "../../icons/Research/Transcript/01";

const researchToolIcons = [
  {
    type: "research-tool",
    name: "research-contributor-01",
    icon: IconContributor01,
  },
  { type: "research-tool", name: "research-archive-01", icon: IconArchive01 },
  { type: "research-tool", name: "research-access-01", icon: IconArchive01 },

  { type: "research-tool", name: "research-budget-01", icon: IconBudget01 },
  { type: "research-tool", name: "research-legal-01", icon: IconLegal01 },

  {
    type: "research-tool",
    name: "research-reference-01",
    icon: IconReference01,
  },
  {
    type: "research-tool",
    name: "research-research-data-01",
    icon: IconResearchData01,
  },
  {
    type: "research-tool",
    name: "research-story-beat-01",
    icon: IconStoryBeat01,
  },
  { type: "research-tool", name: "research-talent-01", icon: IconTalent01 },
  {
    type: "research-tool",
    name: "research-location-01",
    icon: LineIcon.Location,
  },
  {
    type: "research-tool",
    name: "research-schedule-01",
    icon: LineIcon.Archive,
  },
  { type: "research-tool", name: "research-access-1", icon: LineIcon.Access1 },

  // new icons
  {
    type: "research-tool",
    name: "research-travel-pack-01",
    icon: IconTravelPack01,
  },
  { type: "research-tool", name: "research-calendar-01", icon: IconCalendar01 },
  { type: "research-tool", name: "research-schedule-01", icon: IconSchedule01 },
  { type: "research-tool", name: "research-team-01", icon: IconTeam01 },
  { type: "research-tool", name: "research-agent-01", icon: IconAgent01 },
  { type: "research-tool", name: "research-wardrobe-01", icon: IconWardrobe01 },
  { type: "research-tool", name: "research-contract-01", icon: IconContract01 },
  {
    type: "research-tool",
    name: "research-documents-01",
    icon: IconDocuments01,
  },
  { type: "research-tool", name: "research-template-01", icon: IconTemplate01 },
  { type: "research-tool", name: "research-project-01", icon: IconProject01 },
  { type: "research-tool", name: "research-meals-01", icon: IconMeals01 },
  { type: "research-tool", name: "research-contact-01", icon: IconContact01 },
  { type: "research-tool", name: "research-contact-02", icon: IconContact02 },
  {
    type: "research-tool",
    name: "research-logistics-01",
    icon: IconLogistics01,
  },
  { type: "research-tool", name: "research-style-01", icon: IconStyle01 },
  { type: "research-tool", name: "research-kit-list-01", icon: IconKit01 },
  { type: "research-tool", name: "research-props-01", icon: IconProps01 },
  { type: "research-tool", name: "research-timeline-01", icon: IconTimeline01 },
  {
    type: "research-tool",
    name: "research-moodboard-01",
    icon: IconMoodboard01,
  },

  {
    type: "research-tool",
    name: "research-documentsfiles-01",
    icon: IconDocumentsfiles01,
  },
  {
    type: "research-tool",
    name: "research-fieldnotes-01",
    icon: IconFieldnotes01,
  },
  {
    type: "research-tool",
    name: "research-interviewnotes-01",
    icon: IconInterviewnotes01,
  },
  { type: "research-tool", name: "research-script-01", icon: IconScript01 },
  {
    type: "research-tool",
    name: "research-shortlist-01",
    icon: IconShortlist01,
  },
  {
    type: "research-tool",
    name: "research-shotbrief-01",
    icon: IconShotbrief01,
  },
  {
    type: "research-tool",
    name: "research-transcript-01",
    icon: IconTranscript01,
  },
];

/**
 * research-documentsfiles-01
research-fieldnotes-01
research-interviewnotes-01
research-script-01
research-shortlist-01
research-shotbrief-01
research-transcript-01
 * 
 * 
 * New icons 2023-11-24

  research-travel-pack-01
  research-calendar-01
  research-schedule-01
  research-team-01
  research-agent-01
  research-wardrobe-01
  research-contract-01
  research-documents-01
  research-template-01
  research-project-01

  New -  2024/4/3
  research-meals-01
  research-contact-01
  research-contact-02

  New -  2024/4/5
  research-logistics-01
  research-style-01
  research-kit-list-01
  research-props-01
  research-timeline-01
  research-moodboard-01
 */

export default researchToolIcons;
