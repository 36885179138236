import React from "react";

import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../common/components/Form/InputField";
import useFormSubmission from "../../hooks/useFormSubmission";
import { getFormValues, useTravelPackWizardStore } from "../../store";
import { format, isValid } from "date-fns";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("This field is required")
    .max(75, "The value may not be longer than 75 characters."),
});
const initialValues = {
  title: "",
  startDate: "",
  endDate: "",
};
const StepKey = "step_01";

const Basic = ({ mode, onSubmitted }) => {
  const initialState = useTravelPackWizardStore((state) => state.initialState);

  // Form submit hook
  const { formik } = useFormSubmission({
    key: StepKey,
    validationSchema,
    initialValues,
    onSubmitted,
  });

  const { values, handleChange, setFieldValue, touched, errors } = formik;

  React.useEffect(() => {
    const formValue = getFormValues(StepKey);

    const formattedDate = (date) => {
      if (!isValid(new Date(date))) {
        return "";
      }
      return format(new Date(date), "yyyy-MM-dd");
    };

    const startDate =
      formattedDate(initialState?.startDate || formValue?.startDate) || "";

    const endDate =
      formattedDate(initialState?.endDate || formValue?.endDate) || "";

    // Set form values
    setFieldValue("title", formValue?.title || "");
    setFieldValue("startDate", startDate);
    setFieldValue("endDate", endDate);
  }, [initialState?.endDate, initialState?.startDate, setFieldValue]);

  return (
    <>
      <div className="grid g2col">
        <div className="g-col full-width">
          <FieldGroup label="Title" error={touched.title && errors.title}>
            <InputField
              name="title"
              placeholder="Enter your title"
              value={values.title}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup label="Start Date">
            {/* <DatePickerField /> */}
            <InputField
              name="stateDate"
              placeholder="Start Date"
              disabled
              value={values.startDate}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup label="End Date">
            <InputField
              name="endDate"
              placeholder="End Date"
              disabled
              value={values.endDate}
            />
            {/* <DatePickerField /> */}
          </FieldGroup>
        </div>
      </div>
    </>
  );
};

export default Basic;
