import React from "react";
import { Wrapper } from "./styled";
import { displayTime, getEventPotions } from "./helper";
import { useCalendar } from "../../hooks/useCalendar";

const EventItem = ({ onClick, data }) => {
  const { currentSelectDate, isDisabled } = useCalendar();

  const styles = React.useMemo(() => {
    let start = data?.start;
    let end = data?.end;

    const styles = getEventPotions(start, end, currentSelectDate);

    if (!styles) {
      return null;
    }

    return {
      top: `${styles?.top}%`,
      height: `calc(${styles?.height}% - 1px)`,
    };
  }, [currentSelectDate, data?.end, data?.start]);

  const displayTimes = React.useMemo(() => {
    return displayTime(data?.start, data?.end);
  }, [data]);

  if (!styles) return null;

  return (
    <>
      <Wrapper
        className="event__item"
        onClick={onClick}
        style={styles}
        $isDisabled={isDisabled}
      >
        <div className="title">{data?.title || "Untitled"}</div>
        {displayTimes ? <div className="duration">{displayTimes}</div> : null}
      </Wrapper>
    </>
  );
};

export default EventItem;
