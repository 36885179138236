import React from "react";
import { CalendarContext } from "../context/CalendarContext";

export const useCalendar = () => {
  const context = React.useContext(CalendarContext);

  if (!context) {
    throw new Error(
      "Please use the 'useCalendar' hook inside the 'CalendarContextProvider'"
    );
  }

  return context;
};
