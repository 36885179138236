import React from "react";
import { format } from "date-fns";

import { EPopover, PopoverContainer } from "./styled";
import IconButton from "./../../../../../common/components/Buttons/IconButton/index";
import IconEdit from "./../../../../../common/components/icons/Line/Edit/01";
import IconDelete from "./../../../../../common/components/icons/Line/Delete/01";
import IconClose from "./../../../../../common/components/icons/Line/Close/01";
import { useCalendar } from "../../hooks/useCalendar";

const EventPopover = ({ anchorEl, onClose, ...rest }) => {
  const { selectedEvent, onSetDialogFormValues } = useCalendar();

  if (!anchorEl) return null;

  console.log(selectedEvent);
  return (
    <EPopover
      className="event__popover"
      anchorEl={anchorEl}
      appendTo={document.body}
      hideOverlay
      placement="top-start"
      modifiers={[
        {
          name: "detectOverflow",
          options: {
            padding: 100,
          },
        },
      ]}
      {...rest}
    >
      <PopoverContainer>
        <div className="event_popover__inner">
          <div className="header">
            <IconButton
              btnSize={20}
              btnIconSize={12}
              icon={<IconEdit />}
              onClick={() => {
                onSetDialogFormValues(selectedEvent);
                onClose();
              }}
            />
            {/* <IconButton btnSize={20} btnIconSize={12} icon={<IconDelete />} /> */}
            <IconButton
              className="close__icon__btn"
              btnSize={20}
              btnIconSize={12}
              icon={<IconClose />}
              onClick={onClose}
            />
          </div>
          <div className="content">
            <div className="start">
              <div className="title">{selectedEvent?.title}</div>
              <div className="subtitle">
                {format(new Date(selectedEvent?.start), "EEEE, LLLL dd . ")}
                <span>
                  {format(new Date(selectedEvent?.start), "hh:mm aaa")}
                  &nbsp;-&nbsp;
                  {format(new Date(selectedEvent?.end), "hh:mm aaa")}
                </span>
              </div>
            </div>

            <div className="end">
              <p>{selectedEvent?.comment}</p>
            </div>
          </div>
        </div>
      </PopoverContainer>
    </EPopover>
  );
};

export default EventPopover;
