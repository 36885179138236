import styled from "styled-components";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-16);

  .form__inputs {
    display: grid;
    gap: var(--layout-spacing-16);

    .field__group {
      margin-bottom: 0;
    }

    .column {
      display: grid;
      gap: var(--layout-spacing-16);

      &--2 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .form__actions {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: var(--layout-spacing-16);
  }
`;
