import React from "react";
import { addDays, format } from "date-fns";
import produce from "immer";
import { Swiper as SlideContainer, SwiperSlide as Slide } from "swiper/react";

// Import styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useScheduleContext } from "../../context/ScheduleContext";
import Column from "./Column";

import * as Styled from "./styled";
import { useContextMenu } from "../../../../../../../../hooks/useContextMenu";
import {
  createScheduleitem,
  deleteCalenderItems,
  getAllMilestones,
  moveCalenderItems,
} from "../../../../../../API/common";
import { useGlobalStore } from "../../../../../../../../store/GlobalStore";
import { useAppStore } from "../../../../../../../../store/AppStore/AppStore";
import { ScheduleItemType } from "../../../../../../Constants";
import CellSelector from "./Column/CellSelector";
import { TravelPackDialog } from "../../../../../../../../shared/components/Modals/TravelPackWizard/store";

export const CONTEXT_MENU_TYPE = {
  EmptyRow: 0,
  CellDataRow: 1,
};

const RightPanel = () => {
  const { episodeId } = useScheduleContext();
  const { columnWidth, columns, cellData } = useScheduleContext();
  const [milestones, setMilestones] = React.useState([]);
  const currentCellPayloadRef = React.useRef();
  const projectId = useGlobalStore((state) => state.selectedProjectId);
  const { dates, handleUpdateCellData } = useScheduleContext();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const [contextMenuType, setContextMenuType] = React.useState(
    CONTEXT_MENU_TYPE.EmptyRow
  );
  const [selectedItems, setSelectedItemIds] = React.useState([]);
  const [pasteEnabledColumnTypeId, setPasteEnabledColumnTypeId] =
    React.useState();

  React.useEffect(() => {
    getAllMilestones().then((response) => {
      setMilestones(response);
    });
  }, []);

  const findScheduleItemsByDateId = React.useCallback(
    (selectedDateIds = [], columnTypeId) => {
      const cellDataObj = cellData?.find(
        (c) => c?.columnTypeId === columnTypeId
      );

      const items = cellDataObj?.cellItems
        .filter((item) => {
          return selectedDateIds.includes(String(item?.dateId));
        })
        .filter((d) => d?.activities.length > 0);

      const activities = items.map((d) => d?.activities);

      const scheduleItemIds = activities.reduce((a, b) => {
        const actIds = b.map((act) => act?.scheduleItemId);
        return a.concat(actIds);
      }, []);

      return {
        scheduleItemIds,
        activities,
        items,
      };
    },
    [cellData]
  );

  const findDateByDateId = React.useCallback(
    (dateId) => {
      if (!dateId) return;

      return dates.find((d) => d.dateId === dateId)?.date;
    },

    [dates]
  );

  const handleMilestone = React.useCallback(
    (payload) => {
      console.log("Already selected ", payload);

      showLoader();
      const date = findDateByDateId(
        currentCellPayloadRef?.current?.cellItem?.dateId || 0
      );

      if (payload?.isSelected) {
        createScheduleitem(
          currentCellPayloadRef?.current?.columnTypeId,
          projectId,
          episodeId, //episode Id
          ScheduleItemType.Milestone || 0,
          addDays(new Date(date), 1) || new Date(), // Change this when fix the date selecting issue
          [{ itemTypeId: payload?.itemTypeId || 0, count: 1 }],
          new Date(dates[0]?.date),
          new Date(dates[dates.length - 1]?.date)
        ).then((response) => {
          if (response) {
            handleUpdateCellData(response);
          }
          hideLoader();
        });
      } else {
        deleteCalenderItems(
          [payload?.currentCell?.scheduleItemId] || [],
          currentCellPayloadRef?.current?.columnTypeId || 0,
          projectId || 0,
          episodeId || 0,
          format(new Date(dates[0]?.date), "yyyy-MM-dd"),
          format(new Date(dates[dates.length - 1]?.date), "yyyy-MM-dd")
        ).then((response) => {
          if (response) {
            handleUpdateCellData(response?.data?.result);
          }
          hideLoader();
        });
      }
    },
    [
      dates,
      episodeId,
      findDateByDateId,
      handleUpdateCellData,
      hideLoader,
      projectId,
      showLoader,
    ]
  );

  const handleCut = React.useCallback(() => {
    const getSelectedItemIds = () => {
      let result = [];
      let selectedNodes = document.querySelectorAll('[data-selected="true"]');

      [...selectedNodes]?.forEach((el) => {
        el.classList.add("is__cut");
        result.push(el.dataset.id);
      });

      return result || [];
    };

    resetAlreadyCutItems();
    const ids = getSelectedItemIds();

    setPasteEnabledColumnTypeId(currentCellPayloadRef?.current?.columnTypeId);
    setSelectedItemIds(ids);

    // const clientRect = (el) => {
    //   return el.getBoundingClientRect();
    // };

    // let firstElement = els[0];
    // let lastElement = els[els.length - 1];

    // let top = clientRect(firstElement).top;
    // let bottom = clientRect(lastElement).bottom;

    // let parent = firstElement.parentNode;
    // let tempDiv = document.createElement("div");
    // tempDiv.classList.add("cut__layer");
    // tempDiv.style.position = "absolute";
    // tempDiv.style.top = top + "px";
    // tempDiv.style.height = `${Math.abs(top - bottom)}px`;
    // tempDiv.style.width = "100%";

    // parent.appendChild(tempDiv);
  }, []);

  const handlePaste = React.useCallback(() => {
    if (
      pasteEnabledColumnTypeId !== currentCellPayloadRef?.current?.columnTypeId
    ) {
      alert("Not allowed to paste");
      return;
    }

    const dateIds = selectedItems;

    const scheduleItemsIds = findScheduleItemsByDateId(
      dateIds,
      currentCellPayloadRef?.current?.columnTypeId
    );

    let currentDate = new Date(currentCellPayloadRef?.current?.date);

    if (!currentCellPayloadRef?.current?.date) {
      const dateId = currentCellPayloadRef?.current?.cellItem?.dateId;

      if (!dateId) return;
      currentDate = new Date(findDateByDateId(dateId));
    }

    currentDate.setDate(currentDate.getDate() + 1);

    showLoader();

    moveCalenderItems(
      projectId,
      currentCellPayloadRef?.current?.columnTypeId,
      episodeId,
      new Date(dates[0]?.date),
      new Date(dates[dates.length - 1]?.date),
      new Date(currentDate),
      scheduleItemsIds?.scheduleItemIds
    ).then((result) => {
      console.log(result);
      handleUpdateCellData(result?.data?.result);
      hideLoader();
    });

    unselectAllItems();
    resetAlreadyCutItems();
  }, [
    pasteEnabledColumnTypeId,
    selectedItems,
    findScheduleItemsByDateId,
    showLoader,
    projectId,
    episodeId,
    dates,
    findDateByDateId,
    handleUpdateCellData,
    hideLoader,
  ]);

  const handleDelete = React.useCallback(() => {
    const getSelectedItemIds = () => {
      let result = [];
      let selectedNodes = document.querySelectorAll('[data-selected="true"]');

      [...selectedNodes]?.forEach((el) => {
        result.push(el.dataset.id);
      });

      return result || [];
    };
    showLoader();
    const ids = getSelectedItemIds();

    const scheduleItemsIds = findScheduleItemsByDateId(
      ids,
      currentCellPayloadRef?.current?.columnTypeId
    );

    console.log(currentCellPayloadRef?.current);
    console.log("delete schedule items  ", scheduleItemsIds);

    deleteCalenderItems(
      scheduleItemsIds?.scheduleItemIds,
      currentCellPayloadRef?.current?.columnTypeId,
      projectId,
      episodeId,
      new Date(dates[0]?.date),
      new Date(dates[dates.length - 1]?.date)
    ).then((result) => {
      console.log(result);
      handleUpdateCellData(result?.data?.result);
      hideLoader();
    });

    unselectAllItems();
  }, [
    projectId,
    episodeId,
    dates,
    showLoader,
    findScheduleItemsByDateId,
    handleUpdateCellData,
    hideLoader,
  ]);

  const handleClearSelection = React.useCallback(() => {
    setSelectedItemIds([]);
    setPasteEnabledColumnTypeId(undefined);
    unselectAllItems();
    resetAlreadyCutItems();
  }, []);

  const handleTravelPack = React.useCallback(() => {
    const getSelectedItemIds = () => {
      let result = [];
      let selectedNodes = document.querySelectorAll('[data-selected="true"]');

      [...selectedNodes]?.forEach((el) => {
        result.push(el.dataset.id);
      });

      return result || [];
    };

    const ids = getSelectedItemIds();

    const scheduleItemsIds = findScheduleItemsByDateId(
      ids,
      currentCellPayloadRef?.current?.columnTypeId
    );

    const scheduleData = produce(scheduleItemsIds, (draft) => {
      draft?.items?.forEach((d) => {
        const date = findDateByDateId(d?.dateId);
        d.date = new Date(date);
      });
    });

    const dates = scheduleData.items.map((d) => d.date);
    const startDate = new Date(Math.min(...dates));
    const endDate = new Date(Math.max(...dates));

    if (!endDate || !startDate) {
      unselectAllItems();
      return;
    }

    // Trigger TravelPack Modal
    TravelPackDialog.open({ scheduleData, startDate, endDate });

    unselectAllItems();
  }, [findDateByDateId, findScheduleItemsByDateId]);

  const options = React.useMemo(() => {
    const hasCutElements =
      document.querySelectorAll(".is__cut")?.length > 0 ? true : false;

    return [
      {
        label: "Cut",
        isDisabled: contextMenuType === CONTEXT_MENU_TYPE.EmptyRow,
        onClick: handleCut,
      },

      {
        label: "Paste",
        isDisabled: !hasCutElements,
        onClick: handlePaste,
      },

      {
        label: "Delete",
        isDisabled: contextMenuType === CONTEXT_MENU_TYPE.EmptyRow,
        onClick: handleDelete,
      },

      {
        label: "Milestone",
        isDisabled: contextMenuType === CONTEXT_MENU_TYPE.EmptyRow,
        child: milestones?.map((m) => {
          return {
            ...m,
            label: m?.itemType?.itemName || "Milestone",
            type: "checkbox",
            isSelected: !!m?.isSelected,
            onClick: (payload) =>
              handleMilestone({
                ...m,
                isSelected: payload?.isSelected,
              }),
          };
        }),
      },
      {
        label: "Create TravelPack",
        onClick: handleTravelPack,
      },
      {
        label: "Clear selection(s)",
        onClick: handleClearSelection,
      },
    ];
  }, [
    contextMenuType,
    milestones,
    handleCut,
    handlePaste,
    handleDelete,
    handleTravelPack,
    handleClearSelection,
    handleMilestone,
  ]);

  const handleContextMenuMilestone = React.useCallback((params) => {
    const existingMilestoneIds = params?.payload?.cellItem?.milstones?.map(
      (s) => s.itemTypeId
    );

    // Update the milestones according to the current selected cell type
    setMilestones(
      produce((draft) => {
        draft.forEach((milestone) => {
          const hasItemTypeId = [...new Set(existingMilestoneIds)].find(
            (m) => m === milestone?.itemTypeId
          );

          milestone.isSelected = hasItemTypeId ? true : false;

          milestone.currentCell =
            params?.payload?.cellItem?.milstones.find(
              (mm) => mm.itemTypeId === hasItemTypeId
            ) || null;
        });
      })
    );
  }, []);

  const { contextMenu, show } = useContextMenu({ options: options });

  return (
    <>
      <Styled.Container $columnWidth={columnWidth}>
        <div className="inner">
          <SlideContainer
            spaceBetween={8}
            slidesPerView="auto"
            className="column__swiper"
            touchStartPreventDefault={false}
          >
            {columns
              ?.sort((a, b) => String(a.order).localeCompare(String(b.order)))
              .map((d, i) => {
                // Filter and get cell items by columnTypeId
                const cellDataObj = cellData?.find(
                  (c) => c?.columnTypeId === d?.columnTypeId
                );

                return (
                  <Slide key={d?.columnTypeId}>
                    <Column
                      columnTypeId={d?.columnTypeId}
                      handleContextMenu={(params) => {
                        currentCellPayloadRef.current = params?.payload;
                        setTimeout(() => {
                          setContextMenuType(params?.payload?.contextMenuType);
                          handleContextMenuMilestone(params);
                          show(params);
                        }, 100);
                      }}
                      data={JSON.stringify(d)}
                      cellItems={JSON.stringify(cellDataObj?.cellItems || [])}
                    />
                  </Slide>
                );
              })}
          </SlideContainer>

          <CellSelector />
        </div>
      </Styled.Container>

      {/* Load Context menu */}
      {contextMenu}
    </>
  );
};

function resetAlreadyCutItems() {
  let alreadyCutNodes = document.querySelectorAll(".is__cut");
  [...alreadyCutNodes].forEach((node) => node.classList.remove("is__cut"));
}

function unselectAllItems() {
  let selectedNodes = document.querySelectorAll('[data-selected="true"]');
  [...selectedNodes].forEach((node) => (node.dataset.selected = false));
}

export default RightPanel;
