import React from "react";
import * as Styled from "./styled";
import ColorPicker from "./components/ColorPicker";
import SectionItem from "./components/SectionItem";
import NumberSelector from "./components/NumberSelector";
import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import {
  ActivityModal,
  useSchedulerStore,
  LegendModal,
} from "../../../store/SchedulerStore";
import { ScheduleItemType } from "../../../../../Constants";
import {
  createScheduleitem,
  getActivityItemTypeByBlockId,
} from "../../../../../API/ScheduleItemType";
import { useAppStore } from "../../../../../../../store/AppStore/AppStore";
import { useGlobalStore } from "../../../../../../../store/GlobalStore";
import { useToastMessage } from "../../../../../../../context/ToastMessageProvider";
import { useScheduleContext } from "../../SchedulerPanel/context/ScheduleContext";
import { addDays } from "date-fns";
import { getEpisodeIdsByProjectId } from "../../../../../API/common";

const ActivityBlockModal = () => {
  const activityModalState = useSchedulerStore(
    (state) => state.activityModalState
  );
  const legendModalState = useSchedulerStore((state) => state.legendModalState);
  const projectId = useGlobalStore((state) => state.selectedProjectId);
  const { dates, handleUpdateCellData } = useScheduleContext();
  //legendModalState.payload?.columnTypeId
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const { fireToast } = useToastMessage();

  const [items, setItems] = React.useState([]);
  const [title, setTitle] = React.useState("Activity");
  const [buttonText, setButtonText] = React.useState("Create");
  const [itemCounts, setItemCounts] = React.useState([]);

  const isNotValid = React.useMemo(() => {
    return itemCounts.map((item) => item.count).every((d) => d === 0);
  }, [itemCounts]);

  const loadItems = React.useCallback(() => {
    if (activityModalState?.payload?.type === ScheduleItemType.Activity) {
      setItems([]);
      setItemCounts([]);
      let itemArray = [];
      let itemCountArray = [];

      itemArray.push(activityModalState?.payload?.data?.itemType);
      itemCountArray.push({
        itemTypeId:
          activityModalState?.payload?.data?.itemType?.itemTypeId || 0,
        count: 1,
      });

      setItems(itemArray);
      setItemCounts(itemCountArray);

      setTitle("Activity");
      setButtonText("Create");
    }
    if (activityModalState?.payload?.type === ScheduleItemType.Block) {
      showLoader();
      getActivityItemTypeByBlockId(
        activityModalState?.payload?.data?.blockTypeId || 0
      ).then((response) => {
        let itemArray = [];
        let itemCountArray = [];
        response.forEach((element) => {
          itemArray.push(element);
          itemCountArray.push({
            itemTypeId: element?.itemTypeId || 0,
            count: 1,
          });
        });
        setItems([]);
        setItemCounts([]);
        setItems(itemArray);
        setItemCounts(itemCountArray);
        setTitle("Activity Block");
        setButtonText("Create a Block");
        hideLoader();
      });
    }
  }, [
    activityModalState?.payload?.data?.blockTypeId,
    activityModalState?.payload?.data?.itemType,
    activityModalState?.payload?.type,
    hideLoader,
    showLoader,
  ]);

  React.useEffect(() => {
    loadItems();
  }, [activityModalState?.payload]);

  const findDateByDateId = React.useCallback(
    (dateId) => {
      if (!dateId) return;

      return dates.find((d) => d.dateId === dateId)?.date;
    },

    [dates]
  );

  const handleSubmit = React.useCallback(() => {
    if (isNotValid) {
      // fireToast((toast) => toast.error("0 activity items count not allowed !"));
      return;
    }
    showLoader();
    const date = findDateByDateId(legendModalState.payload?.dateId || 0);

    getEpisodeIdsByProjectId(projectId).then((epId) => {
      createScheduleitem(
        legendModalState.payload?.columnTypeId,
        projectId,
        epId, //episode Id
        ScheduleItemType.Activity || 0,
        addDays(new Date(date), 1) || new Date(), // Change this when fix the date selecting issue
        itemCounts,
        new Date(dates[0]?.date),
        new Date(dates[dates.length - 1]?.date)
      ).then((response) => {
        if (response) {
          //console.log("sch item result::: ", response);
          handleUpdateCellData(response);
          ActivityModal.close();
          LegendModal.close();
        }
        hideLoader();
      });
    });
  }, [
    dates,
    findDateByDateId,
    handleUpdateCellData,
    hideLoader,
    isNotValid,
    itemCounts,
    legendModalState.payload?.columnTypeId,
    legendModalState.payload?.dateId,
    projectId,
    showLoader,
  ]);

  const handleOnItemCountChanged = React.useCallback(
    (itemId, counts) => {
      const newState = itemCounts.map((obj) => {
        if (obj.itemTypeId === itemId) {
          return { ...obj, count: counts };
        }
        return obj;
      });
      setItemCounts(newState);
    },
    [itemCounts]
  );

  return (
    <Styled.Modal
      visibility={activityModalState.visibility}
      titleComponent={(props) => <TitleComponent title={title} />}
      bodyComponent={(props) => (
        <BodyComponent
          isNotValid={isNotValid}
          items={items}
          btnText={buttonText}
          handleOnCreate={handleSubmit}
          handleOnCountChanged={handleOnItemCountChanged}
        />
      )}
      onClose={ActivityModal.close}
    />
  );
};

function TitleComponent({ title }) {
  return (
    <Styled.TitleContainer>
      <div className="addText">Add</div>
      <div className="activityText">{title}</div>
    </Styled.TitleContainer>
  );
}

function BodyComponent({
  items,
  btnText,
  handleOnCreate,
  handleOnCountChanged,
  isNotValid,
}) {
  return (
    <Styled.ActivitySection>
      <div className="titlesContainer">
        <div>Color</div>
        <div>Section</div>
      </div>

      <div className="actionsContainer">
        {items?.map((d, i) => {
          console.log("leg i", d);
          return (
            <ActivityRow
              key={i}
              color={d?.bgColour || "#EEF8F8"}
              text={d?.itemName || ""}
              maxValue={10}
              value={d?.defaultDayCount || 0}
              minValue={0}
              onNumberChange={(v) => {
                handleOnCountChanged(d?.itemTypeId, v);
              }}
            />
          );
        })}
        {/* <ActivityRow
          color="#EEF8F8"
          text="Shoot Script / Beat Sheet"
          maxValue={10}
          value={0}
          onNumberChange={(v) => {
            console.log("val", v);
          }}
        />

        <ActivityRow
          icon={<IconFeedBack />}
          color="#EEF8F8"
          text="Shoot Script / Beat Sheet"
          maxValue={10}
          value={0}
          onNumberChange={(v) => {
            console.log("val", v);
          }}
        /> */}
      </div>

      <UIButton
        disabled={isNotValid}
        variant="primary"
        btnSize="sm"
        rounded
        onClick={handleOnCreate}
      >
        {btnText}
      </UIButton>
    </Styled.ActivitySection>
  );
}

function ActivityRow({
  color,
  text,
  maxValue,
  value = 0,
  onNumberChange,
  icon = null,
}) {
  return (
    <Styled.ActivityRow>
      <ColorPicker color={color} />
      <SectionItem color={color} text={text} icon={icon} />
      <NumberSelector
        maxVal={maxValue}
        value={value}
        onChange={onNumberChange}
      />
    </Styled.ActivityRow>
  );
}

export default ActivityBlockModal;
