import styled from "styled-components";

export const Wrapper = styled.form`
  display: flex;
  gap: var(--layout-spacing-16);
  flex-direction: column;

  .form__inputs {
    .two__column {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--layout-spacing-16);
    }
  }

  .form__actions {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: var(--layout-spacing-16);
  }
`;
