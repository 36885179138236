import React from "react";
import { Wrapper } from "./styled";
import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import SelectField from "../../../../../../common/components/Form/SelectField";
import Column from "./Column";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { useTravelPackWizardStore } from "../../store";
import { getMappingData, saveMappingData } from "../../api";
import { eachDayOfInterval, format } from "date-fns";
import { toast } from "react-toastify";
import useFormSubmission from "../../hooks/useFormSubmission";
import GanttChart from "./GanttChart";
import { useGlobalStore } from "../../../../../../store/GlobalStore";

const StepKey = "step_05";
const initialValues = {};
const Mapping = ({ mode, onSubmitted }) => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const currentTravelPack = useTravelPackWizardStore(
    (state) => state.currentTravelPack
  );
  const initData = useTravelPackWizardStore((state) => state.initialState);
  const [sections, setSections] = React.useState([]);
  const [seletedDate, setSelectedDate] = React.useState(null);
  const [chartDetails, setChartDetails] = React.useState(null);
  const projectId = Number(
    useGlobalStore((state) => state.selectedProjectId) || 0
  );

  const selectedDates = React.useMemo(() => {
    if (!initData) {
      return [];
    }
    const result = eachDayOfInterval({
      start: new Date(initData?.startDate),
      end: new Date(initData?.endDate),
    });

    const mappedData = result?.map((day) => {
      return {
        label: format(day, "dd MMMM"),
        value: format(day, "yyyy-MM-dd"),
      };
    });

    if (mappedData.length > 0) {
      setSelectedDate(mappedData[0]);
    }

    return mappedData;
  }, [initData]);

  const tagSelectHandler = React.useCallback(
    (payload) => {
      console.log(payload);

      showLoader();

      saveMappingData(
        currentTravelPack?.shootLegId || 0,
        seletedDate,
        payload,
        projectId
      )
        .then((result) => {
          console.log("saveMappingData", result);
          setSections(result?.tagMappingDetails || []);
          setChartDetails(result?.chartDetails || null);
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          console.log("saveMappingData", error);
          toast.error("Save Mapping Data Error: " + error.message);
        });
    },
    [
      currentTravelPack?.shootLegId,
      hideLoader,
      projectId,
      seletedDate,
      showLoader,
    ]
  );

  // Form submit hook
  const { formik } = useFormSubmission({
    key: StepKey,
    initialValues,
    onSubmitted,
  });
  const { values } = formik;

  React.useEffect(() => {
    showLoader();
    getMappingData(currentTravelPack?.shootLegId || 0, seletedDate, projectId)
      .then((data) => {
        console.log("map data", data);
        // Set form values
        setSections(data?.tagMappingDetails || []);
        setChartDetails(data?.chartDetails || null);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        console.log("mapping data fetch", error);
        toast.error("Mapping Data Fetch issue Error: " + error.message);
      });
  }, [
    currentTravelPack?.shootLegId,
    hideLoader,
    projectId,
    seletedDate,
    showLoader,
  ]);

  return (
    <Wrapper>
      <div className="filters">
        <div className="select-date">
          <FieldGroup label="Select Date">
            <SelectField
              value={seletedDate}
              options={selectedDates}
              onChange={(d) => setSelectedDate(d)}
            />
          </FieldGroup>
        </div>
      </div>

      <div className="columns">
        {sections
          ?.sort((a, b) => a.order - b.order)
          ?.map((d, i) => {
            return (
              <Column
                key={i}
                iconName={d?.icon}
                title={d?.name}
                order={d?.order}
                type={d?.type}
                tags={d?.tagList}
                onChange={tagSelectHandler}
              />
            );
          })}
      </div>

      <div className="chart">
        {chartDetails && chartDetails?.taskArray?.length > 0 ? (
          <GanttChart
            stylingMap={chartDetails?.stylingMap}
            taskArray={chartDetails?.taskArray}
          />
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Mapping;
