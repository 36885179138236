import styled from "styled-components";
import { fontStyle } from "../../../../../../../styled/utility";
import ListItem from "../../../../../../../common/components/UI/ListItem";

export const Wrapper = styled.div`
  margin-top: var(--layout-spacing-8);
  border-radius: var(--layout-border-radius-12);
  border: 1px solid var(--font-tint-01);
  overflow: hidden;

  .list-head {
    ${fontStyle("x-small-medium")};
    border-bottom: 1px solid var(--font-tint-01);
    padding: var(--layout-spacing-12);
    color: var(--color-primary-200);
    font-weight: 500;
  }

  .list-body {
    .scroll-wrapper {
      padding: var(--layout-spacing-8) var(--layout-spacing-2);
      /* overflow-y: scroll; */
      /* max-height: 180px; */
      /* max-height: 180px; */
      min-height: 80px;
    }
  }
`;

export const MemberItem = styled(ListItem)`
  gap: var(--layout-spacing-12);
  padding: var(--layout-spacing-4) var(--layout-spacing-8);
  border: 1px solid transparent;
  border-radius: var(--layout-border-radius-4);

  &:not(:last-child) {
    /* margin-bottom: var(--layout-spacing-4); */
  }

  &:hover {
    background-color: var(--color-neutral-30);
    border-color: #c1c4ce;
  }

  .drag-handler {
    cursor: grab;
    --icon-button-icon-color: var(--font-tint-04);
  }

  .start {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
  }

  .center {
    flex: 1;
  }
  .start {
  }
`;
