import React from "react";
import { NotFound, SearchPopover, Wrapper } from "./styled";
import InputField from "../../Form/InputField";
import { isEqualElement } from "../../../../utility";

const SearchList = ({
  data = [],
  render,
  onSearch,
  placeholder = "Search",
  onRootClose,
}) => {
  const [isSearchEnabled, setSearchEnabled] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [value, setValue] = React.useState("");
  const listRef = React.useRef();

  const searchPanelWidth = React.useMemo(() => {
    if (!anchorEl) return 0;
    const rect = anchorEl?.getBoundingClientRect();
    return Math.max(rect.width, 400);
  }, [anchorEl]);

  const rootCloseHandler = React.useCallback(() => {
    setSearchEnabled(false);
    setValue("");

    onRootClose && onRootClose();
  }, [onRootClose]);

  const renderList = React.useMemo(() => {
    return data.map((d, i) => {
      return render && render({ key: i, data: d }, rootCloseHandler);
    });
  }, [data, render, rootCloseHandler]);

  React.useEffect(() => {
    const clickHandler = (e) => {
      if (!listRef.current || !anchorEl) {
        return;
      }

      const target = e.target;
      const isSameElement = isEqualElement(target, listRef.current);
      const isInputElement = isEqualElement(target, anchorEl);

      if (isSameElement || isInputElement) {
        return;
      }

      rootCloseHandler();
    };

    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [anchorEl, rootCloseHandler]);

  return (
    <Wrapper>
      <InputField
        placeholder={placeholder}
        ref={setAnchorEl}
        value={value}
        onFocus={() => {
          setSearchEnabled(true);
        }}
        onChange={(e) => {
          setValue(e.target.value);
          onSearch && onSearch(e.target.value);
        }}
      />

      {isSearchEnabled ? (
        <SearchPopover
          // appendTo={document.body}
          anchorEl={anchorEl}
          hideOverlay
          placement="bottom-start"
        >
          <div
            ref={listRef}
            className="list-wrapper"
            style={{ width: searchPanelWidth }}
          >
            {data && data?.length > 0 ? (
              renderList
            ) : (
              <NotFound>No results found</NotFound>
            )}
          </div>
        </SearchPopover>
      ) : null}
    </Wrapper>
  );
};

export default SearchList;
