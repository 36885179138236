import React from "react";

import IconArrowRight from "../../../../common/components/icons/Line/arrow-right";
import IconArrowLeft from "../../../../common/components/icons/Line/arrow-left";
import UIButton from "../../../../common/components/Buttons/UIButton";

import {
  getCurrentStep,
  onBeforeNext,
  onNext,
  onPrevious,
  onSkip,
  setTravelPack,
  TravelPackDialog,
  updateFormValues,
  // onToggleEditMode,
  useTravelPackWizardStore,
} from "./store";
import { DurationBlock, TravelPackWizardModal } from "./styled";
import Basic from "./Forms/Basic";
import SchedulePriority from "./Forms/SchedulePriority";
import DefaultProvisions from "./Forms/DefaultProvisions";
import TeamContact from "./Forms/TeamContact";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import {
  saveConatctList,
  saveSchedulePriorityFilter,
  saveTravelPack,
  saveTravelPackScheduleDetails,
} from "./api";
import { useGlobalStore } from "../../../../store/GlobalStore";
import { toast } from "react-toastify";
import Mapping from "./Forms/Mapping/Index";
import { format, isValid } from "date-fns";

const TravelPackWizard = () => {
  const isVisible = useTravelPackWizardStore((state) => state.isVisible);
  const currentTravelPack = useTravelPackWizardStore(
    (state) => state.currentTravelPack
  );
  const steps = useTravelPackWizardStore((state) => state.steps);
  const activeStep = useTravelPackWizardStore(
    (state) => state.currentActiveStepKey
  );
  const currentStep = getCurrentStep();

  const projectId = Number(
    useGlobalStore((state) => state.selectedProjectId) || 0
  );

  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const handleSubmit = React.useCallback(
    (values, options) => {
      console.log("Done", values, options);
      switch (options.stepKey) {
        case "step_01": {
          updateFormValues(values, "step_01");

          showLoader();

          saveTravelPack(currentTravelPack?.shootLegId || 0, projectId, values)
            .then((result) => {
              hideLoader();
              console.log("saveTravelPack", result);
              if (result) {
                setTravelPack(result);
                onNext();
              }
            })
            .catch((error) => {
              hideLoader();
              console.log("saveTravelPack", error);
              toast.error("save travel pack error: " + error.message);
              onNext();
            });

          return;
        }
        case "step_02": {
          updateFormValues(values, "step_02");

          showLoader();

          saveConatctList(currentTravelPack?.shootLegId || 0, projectId, values)
            .then((result) => {
              hideLoader();
              console.log("saveConatctList", result);
              if (result) {
                onNext();
              }
            })
            .catch((error) => {
              hideLoader();
              console.log("saveConatctList", error);
              toast.error("save contact list error: " + error.message);
              onNext();
            });

          return;
        }
        case "step_03": {
          updateFormValues(values, "step_03");

          showLoader();

          saveSchedulePriorityFilter(currentTravelPack?.shootLegId || 0, values)
            .then((result) => {
              hideLoader();
              console.log("saveSchedulePriorityFilter", result);
              if (result) {
                onNext();
              }
            })
            .catch((error) => {
              hideLoader();
              console.log("saveSchedulePriorityFilter", error);
              toast.error(
                "Save Schedule Priority Filter Error: " + error.message
              );
              onNext();
            });

          return;
        }
        case "step_04": {
          updateFormValues(values, "step_04");
          showLoader();

          saveTravelPackScheduleDetails(
            currentTravelPack?.shootLegId || 0,
            values
          )
            .then((result) => {
              hideLoader();
              console.log("saveTravelPackScheduleDetails", result);
              if (result) {
                onNext();
              }
            })
            .catch((error) => {
              hideLoader();
              console.log("saveTravelPackScheduleDetails", error);
              toast.error(
                "Save Travel Pack Schedule Details Error: " + error.message
              );
              onNext();
            });

          return;
        }
        case "step_05": {
          TravelPackDialog.close();

          return;
        }

        default: {
          return;
        }
      }
    },
    [currentTravelPack?.shootLegId, hideLoader, projectId, showLoader]
  );

  const renderSteps = React.useMemo(() => {
    if (!activeStep) return null;

    const mode = currentStep?.isEditMode ? "edit" : "";

    switch (activeStep) {
      case "step_01": {
        return <Basic mode={mode} onSubmitted={handleSubmit} />;
      }
      case "step_02": {
        return <TeamContact mode={mode} onSubmitted={handleSubmit} />;
      }
      case "step_03": {
        return <SchedulePriority mode={mode} onSubmitted={handleSubmit} />;
      }
      case "step_04": {
        return <DefaultProvisions mode={mode} onSubmitted={handleSubmit} />;
      }
      case "step_05": {
        return <Mapping mode={mode} onSubmitted={handleSubmit} />;
      }

      default:
        return null;
    }
  }, [activeStep, currentStep?.isEditMode, handleSubmit]);

  return (
    <TravelPackWizardModal
      steps={steps.filter((step) => step.isEnabled)}
      visibility={isVisible}
      title={currentStep?.modalTitle}
      bodyTitle={currentStep?.title}
      bodySubTitle={currentStep?.subTitle}
      onClose={TravelPackDialog.close}
      // handleEdit={onToggleEditMode}
      // showEditButton={currentStep?.showEditButton}
      className={currentStep?.options?.modalClassName}
      bodyFooterSlots={{
        leftComponent: FooterLeftButtons,
        rightComponent: () => <FooterRightButtons />,
      }}
      headerEndContents={<DateDuration />}
    >
      {renderSteps}
    </TravelPackWizardModal>
  );
};

function FooterLeftButtons() {
  const actionStatus = useTravelPackWizardStore((state) => state.actionStatus);

  return (
    <>
      {/* {actionStatus?.hasSkipAndFinish ? (
        <UIButton
          variant={UIButton.Variant.Success}
          size={UIButton.Sizes.Small}
        >
          Skip & Finish Setup
        </UIButton>
      ) : null} */}

      {actionStatus?.hasPrev ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          startIcon={<IconArrowLeft />}
          onClick={onPrevious}
          type="button"
        >
          Previous
        </UIButton>
      ) : null}
    </>
  );
}

function FooterRightButtons() {
  const actionStatus = useTravelPackWizardStore((state) => state.actionStatus);

  return (
    <>
      {actionStatus?.hasSkip ? (
        <UIButton
          variant={UIButton.Variant.Secondary}
          size={UIButton.Sizes.Small}
          onClick={onSkip}
        >
          Skip
        </UIButton>
      ) : null}

      {actionStatus?.hasNext ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
          endIcon={<IconArrowRight />}
          onClick={onBeforeNext}
          type="button"
        >
          Next
        </UIButton>
      ) : null}

      {actionStatus?.hasFinish ? (
        <UIButton
          variant={UIButton.Variant.Primary}
          size={UIButton.Sizes.Small}
          onClick={onBeforeNext}
          type="button"
        >
          Finish Setup
        </UIButton>
      ) : null}
    </>
  );
}

function DateDuration() {
  const initialState = useTravelPackWizardStore((state) => state.initialState);

  const dates = React.useMemo(() => {
    let start = initialState?.startDate;
    let end = initialState?.endDate;

    if (
      !start ||
      !end ||
      !isValid(new Date(start)) ||
      !isValid(new Date(end))
    ) {
      return null;
    }

    start = format(new Date(start), "dd MMMM");
    end = format(new Date(end), "dd MMMM");

    return (
      <>
        <span>{start}</span>
        <span>&nbsp;-&nbsp;</span>
        <span>{end}</span>
      </>
    );
  }, [initialState]);

  return (
    <DurationBlock>
      <div className="date-text">Date</div>
      <div>{dates ? dates : "-"}</div>
    </DurationBlock>
  );
}

export default TravelPackWizard;
