import * as FillIcon from "../icons/Fill";
import * as LineIcon from "../icons/Line";
import * as LineIconBasic from "../icons/Line/Basic";
import * as LineIconDetails from "../icons/Line/Details";
import * as LineIconDocumentation from "../icons/Line/Documentation";
import RelationshipIcon from "../icons/Fill/Relationship/01"

import LineDeleteIcon1 from "../icons/Line/Delete/01";
import LineIconCallSheet from "../icons/Line/Callsheet/01";
import LineIconFieldNote from "../icons/Line/Fieldnote/icon";
import LineIconShortlist from "../icons/Line/Shortlist/01";
import LineIconDocument01 from "../icons/Line/Document/01";

import FillIconBeatSheet from "../icons/Fill/Beatsheet/01";
import FillIconOrganizer from "../icons/Fill/Organizer/01";
import FillIconCar01 from "../icons/Fill/Car/01";

// Schedule icons
import FillIconFlight01 from "../icons/Fill/Flight/01";
import FillIconMeal01 from "../icons/Fill/Meal/01";
import FillIconThreeDot01 from "../icons/Fill/Threedot/01";
import FillIconClean01 from "../icons/Fill/Clean/01";
import FillIconUsers01 from "../icons/Fill/Users/01";
import FillIconCamera06 from "../icons/Fill/Camera/06";
import FillIconPause01 from "../icons/Fill/Pause/01";
import LineIconSetup01 from "../icons/Line/Setup/01";

import SchedulerCarIn01 from "../icons/Scheduler/carin/01";
import SchedulerCarOut01 from "../icons/Scheduler/carout/01";
import SchedulerPhotographer01 from "../icons/Scheduler/photgrapher/01";

// Document types icons
import IconBeatSheet01 from "../icons/Research/BeatSheet/01";
import IconOrganizer01 from "../icons/Research/Organizer/01";

// CallSheet icons
import IconCallSheet01 from "../icons/CallSheet/CallSheet/01";

import researchToolIcons from "./icons/research.icons";
import tagFieldIcons from "./icons/tagfield.icons";
import mainMenuIcons from "./icons/menu.icons";
import moduleIcons from "./icons/module.icons";

const iconFillList = [
  { type: "fill", name: "fill-add", icon: FillIcon.Add },
  { type: "fill", name: "fill-address", icon: FillIcon.Address },
  { type: "fill", name: "fill-agent", icon: FillIcon.Agent },
  { type: "fill", name: "fill-arrow", icon: FillIcon.ArrowDown },
  { type: "fill", name: "fill-attachment", icon: FillIcon.Attachment },
  { type: "fill", name: "fill-contact", icon: FillIcon.ContactInfo },
  { type: "fill", name: "fill-correct", icon: FillIcon.Correct },
  { type: "fill", name: "fill-cost", icon: FillIcon.Cost },
  { type: "fill", name: "fill-data", icon: FillIcon.Data },
  { type: "fill", name: "fill-document", icon: FillIcon.Document },
  { type: "fill", name: "fill-drag", icon: FillIcon.Drag },
  { type: "fill", name: "fill-edit", icon: FillIcon.Edit },
  { type: "fill", name: "fill-email", icon: FillIcon.Email },
  { type: "fill", name: "fill-favorite", icon: FillIcon.Favorite },
  { type: "fill", name: "fill-grid", icon: FillIcon.Grid },
  { type: "fill", name: "fill-home", icon: FillIcon.Home },
  { type: "fill", name: "fill-invite", icon: FillIcon.Invite },
  { type: "fill", name: "fill-list", icon: FillIcon.ListAlt },
  { type: "fill", name: "fill-menu", icon: FillIcon.Menu },
  { type: "fill", name: "fill-phone", icon: FillIcon.Phone },
  { type: "fill", name: "fill-picker", icon: FillIcon.Picker },
  { type: "fill", name: "fill-prop", icon: FillIcon.Prop },
  { type: "fill", name: "fill-save", icon: FillIcon.Save },
  { type: "fill", name: "fill-settings", icon: FillIcon.Settings },
  { type: "fill", name: "fill-share", icon: FillIcon.Share },
  { type: "fill", name: "fill-shot", icon: FillIcon.Shot },
  { type: "fill", name: "fill-sort", icon: FillIcon.Sort },
  { type: "fill", name: "fill-split", icon: FillIcon.Split },
  { type: "fill", name: "fill-users", icon: FillIcon.Users },
  { type: "fill", name: "fill-beat-sheet", icon: FillIconBeatSheet },
  { type: "fill", name: "fill-organizer", icon: FillIconOrganizer },
  { type: "fill", name: "fill-car-01", icon: FillIconCar01 },
  { type: "fill", name: "fill-flight-01", icon: FillIconFlight01 },
  { type: "fill", name: "fill-meal-01", icon: FillIconMeal01 },
  { type: "fill", name: "fill-three-dot-01", icon: FillIconThreeDot01 },
  { type: "fill", name: "fill-clean-01", icon: FillIconClean01 },
  { type: "fill", name: "fill-users-01", icon: FillIconUsers01 },
  { type: "fill", name: "fill-camera-06", icon: FillIconCamera06 },
  { type: "fill", name: "fill-pause-01", icon: FillIconPause01 },
  { type: "fill", name: "hand-shake", icon: RelationshipIcon },
];

const iconLineList = [
  { type: "line", name: "line-access", icon: LineIcon.Access },
  { type: "line", name: "line-access-1", icon: LineIcon.Access1 },
  { type: "line", name: "line-add-1", icon: LineIcon.Add1 },
  { type: "line", name: "line-all", icon: LineIcon.AllTemplates },
  { type: "line", name: "line-all", icon: LineIcon.AllTemplates },
  { type: "line", name: "line-archive", icon: LineIcon.Archive },
  { type: "line", name: "line-arrow", icon: LineIcon.ArrowDown },
  { type: "line", name: "line-arrow", icon: LineIcon.ArrowLeft },
  { type: "line", name: "line-arrow", icon: LineIcon.ArrowRight },
  { type: "line", name: "line-arrow", icon: LineIcon.ArrowUp },
  { type: "line", name: "line-bookmark", icon: LineIcon.Bookmark },
  { type: "line", name: "line-calender", icon: LineIcon.Calender },
  { type: "line", name: "line-call-sheet", icon: LineIcon.Callsheet },
  { type: "line", name: "line-camera", icon: LineIcon.Camera },
  { type: "line", name: "line-chat", icon: LineIcon.Chat },
  { type: "line", name: "line-contract", icon: LineIcon.Contract },
  { type: "line", name: "line-contributor", icon: LineIcon.Contributor },
  { type: "line", name: "line-day", icon: LineIcon.Day },
  { type: "line", name: "line-doc-01", icon: LineIcon.Doc01 },
  { type: "line", name: "line-doc-01", icon: LineIcon.Doc01 },
  { type: "line", name: "line-doc", icon: LineIcon.DocBadge },
  { type: "line", name: "line-document", icon: LineIcon.DocumentList },
  { type: "line", name: "line-evening", icon: LineIcon.Evening },
  { type: "line", name: "line-file", icon: LineIcon.FileFolder },
  { type: "line", name: "line-filter", icon: LineIcon.Filter },
  { type: "line", name: "line-flip", icon: LineIcon.FlipImage },
  { type: "line", name: "line-floor", icon: LineIcon.Floor },
  { type: "line", name: "line-floor", icon: LineIcon.FloorContinue },
  { type: "line", name: "line-floor", icon: LineIcon.FloorEnd },
  { type: "line", name: "line-floor", icon: LineIcon.FloorStart },
  { type: "line", name: "line-format", icon: LineIcon.Format },
  { type: "line", name: "line-glass", icon: LineIcon.Glass },
  { type: "line", name: "line-history", icon: LineIcon.History },
  { type: "line", name: "line-image", icon: LineIcon.Image },
  { type: "line", name: "line-legal", icon: LineIcon.Legal },
  { type: "line", name: "line-list", icon: LineIcon.ListOrdered },
  { type: "line", name: "line-list", icon: LineIcon.ListUnordered },
  { type: "line", name: "line-location", icon: LineIcon.Location },
  { type: "line", name: "line-lock", icon: LineIcon.Lock },
  { type: "line", name: "line-menu", icon: LineIcon.Menu },
  { type: "line", name: "line-morning", icon: LineIcon.Morning },
  { type: "line", name: "line-night", icon: LineIcon.Night },
  { type: "line", name: "line-note", icon: LineIcon.Note },
  { type: "line", name: "line-notification", icon: LineIcon.Notification },
  { type: "line", name: "line-page", icon: LineIcon.PageNo },
  { type: "line", name: "line-pdf", icon: LineIcon.Pdf },
  { type: "line", name: "line-redo", icon: LineIcon.Redo },
  { type: "line", name: "line-reference", icon: LineIcon.Reference },
  { type: "line", name: "line-save", icon: LineIcon.Save },
  { type: "line", name: "line-sort", icon: LineIcon.Sort },
  { type: "line", name: "line-status", icon: LineIcon.StatusDone },
  { type: "line", name: "line-table", icon: LineIcon.Table },
  { type: "line", name: "line-todo", icon: LineIcon.Todo },
  { type: "line", name: "line-tone", icon: LineIcon.Tone },
  { type: "line", name: "line-undo", icon: LineIcon.Undo },
  { type: "line", name: "line-web", icon: LineIcon.Web },
  { type: "line", name: "line-basic-1", icon: LineIconBasic.Svg01 },
  { type: "line", name: "line-details-1", icon: LineIconDetails.Svg01 },
  { type: "line", name: "line-delete-1", icon: LineDeleteIcon1 },
  { type: "line", name: "line-setup-1", icon: LineIconSetup01 },
  {
    type: "line",
    name: "line-documentation-1",
    icon: LineIconDocumentation.Svg01,
  },
  { type: "line", name: "line-field-note", icon: LineIconFieldNote },
  { type: "line", name: "line-call-sheet", icon: LineIconCallSheet },
  { type: "line", name: "line-short-list", icon: LineIconShortlist },
  { type: "line", name: "line-document-01", icon: LineIconDocument01 },
];

const documentTypesIcons = [
  {
    type: "document-types",
    name: "document-beat-sheet-01",
    icon: IconBeatSheet01,
  },
  {
    type: "document-types",
    name: "document-organizer-01",
    icon: IconOrganizer01,
  },
];

const callSheetIcons = [
  {
    type: "call-sheet",
    name: "call-sheet-call-sheet-01",
    icon: IconCallSheet01,
  },
];

const schedulerIcons = [
  {
    type: "scheduler",
    name: "scheduler-car-in-01",
    icon: SchedulerCarIn01,
  },
  {
    type: "scheduler",
    name: "scheduler-car-out-01",
    icon: SchedulerCarOut01,
  },
  {
    type: "scheduler",
    name: "scheduler-photographer-01",
    icon: SchedulerPhotographer01,
  },
];

export const IconsList = [
  ...iconFillList,
  ...iconLineList,
  ...researchToolIcons,
  ...documentTypesIcons,
  ...callSheetIcons,
  ...schedulerIcons,
  ...mainMenuIcons,
  ...tagFieldIcons,
  ...moduleIcons
];
