import React from "react";
import { Wrapper } from "./styled";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import IconButton from "../../../../../../common/components/Buttons/IconButton";
import IconDrag from "../../../../../../common/components/icons/Fill/drag";
import useIcon from "../../../../../../common/components/IconSelector/hooks/useIcon";
import useFormSubmission from "../../hooks/useFormSubmission";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { getSchedulePriorityFilter } from "../../api";
import { useTravelPackWizardStore } from "../../store";

const StepKey = "step_03";

const initialValues = {
  tagTypes: [],
};

const SchedulePriority = ({ mode, onSubmitted }) => {
  const genIcon = useIcon();
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const currentTravelPack = useTravelPackWizardStore(
    (state) => state.currentTravelPack
  );
  // Form submit hook
  const { formik } = useFormSubmission({
    key: StepKey,
    initialValues,
    onSubmitted,
  });
  const { values, setFieldValue } = formik;

  React.useEffect(() => {
    showLoader();
    getSchedulePriorityFilter(currentTravelPack?.shootLegId || 0).then(
      (data) => {
        // Set form values
        setFieldValue("tagTypes", data || []);
        hideLoader();
      }
    );
  }, [currentTravelPack?.shootLegId, hideLoader, setFieldValue, showLoader]);

  const handleDragEnded = React.useCallback(
    (param) => {
      const { source, destination } = param;

      let cloneValues = [...values.tagTypes];
      const _item = cloneValues.splice(source.index, 1)[0];
      cloneValues.splice(destination.index, 0, _item);

      setFieldValue("tagTypes", cloneValues);

      console.log("cloneValues:::", cloneValues);
    },
    [setFieldValue, values.tagTypes]
  );

  return (
    <Wrapper>
      <DragDropContext onDragEnd={handleDragEnded}>
        <Droppable droppableId="draggable__list">
          {(provided) => (
            <div
              className="draggable__list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {values?.tagTypes?.map((d, i) => {
                const Icon = genIcon(d?.icon);

                return (
                  <Draggable key={`key${i}`} draggableId={`darg${i}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className={[
                          "draggable__item ",
                          snapshot.isDragging
                            ? "draggable__item--is-dragging"
                            : "",
                        ].join("")}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className="draggable__item__order">
                          {i <= 2 ? (
                            <div className="order-number">{i + 1}</div>
                          ) : null}
                        </div>
                        <div className="draggable__item__content">
                          <IconButton
                            as="div"
                            icon={<Icon />}
                            btnIconSize={28}
                            btnSize={28}
                          />
                          <div className="draggable__item__text">
                            {d?.label}
                          </div>
                        </div>
                        <div className="draggable__item__handler">
                          <IconButton
                            as="div"
                            icon={<IconDrag />}
                            btnIconSize={16}
                            btnSize={24}
                            {...provided.dragHandleProps}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default SchedulePriority;
