import React from "react";
import * as Styled from "./styled";
import EventPanel from "./EventPanel";
import Calendar from "./Calendar";
import Footer from "./Footer";
import { CalendarContextProvider } from "./context/CalendarContext";
import { useCalendar } from "./hooks/useCalendar";
import EventDialog from "./EventDialog";
import { CalendarType } from "./constant";
import Preview from "./Preview";

export const CalendarTypes = CalendarType;

const EventCalendar = (props) => {
  const {
    meta,
    viewMode = false,
    hideMainNote = true,
    type = CalendarType.Tag,
    disabled,
    onChange,
  } = props;

  // const clRef = React.useRef();
  // const [height, setHeight] = React.useState(0);

  // Update changes
  // React.useEffect(() => {
  //   if (!viewMode && !shouldUpdate) return;

  //   onSave && onSave({ note, modifiers });
  //   setShouldUpdate(false);

  //   return () => {
  //     setShouldUpdate(false);
  //   };
  // }, [modifiers, shouldUpdate, note, onSave, viewMode]);

  return (
    <CalendarContextProvider
      viewMode={viewMode}
      meta={meta}
      type={type}
      disabled={disabled}
      onChange={onChange}
    >
      <Styled.Container
      // style={{ "--calendar-height": height }}
      >
        {viewMode ? (
          <Preview />
        ) : (
          <>
            <div className="cln__event__inner">
              <div className="start">
                <MainCalendar />
              </div>
              <div className="divider"></div>
              <div className="end">
                <EventPanel />
              </div>
            </div>

            {!hideMainNote && (
              <div className="cln__event__note">
                <Footer
                  // note={note}
                  handleNoteChange={(value) => {}}
                />
              </div>
            )}

            <EventDialog />
          </>
        )}
      </Styled.Container>
    </CalendarContextProvider>
  );
};

function MainCalendar() {
  const { isViewMode, onDaySelect, onMonthChange, calendarDates, isDisabled } =
    useCalendar();

  const modifiers = React.useMemo(() => {
    if (!calendarDates) {
      return null;
    }
    const m = {
      confirm: calendarDates?.map((d) => new Date(d.startDate)),
    };

    return m;
  }, [calendarDates]);

  return (
    <Calendar
      disabled={isDisabled}
      viewMode={isViewMode}
      // ref={clRef}
      modifiers={modifiers}
      // handleModifiers={(modifiers) => {
      //   handleModifiers(modifiers);
      //   setShouldUpdate(true);
      // }}
      onMonthChange={onMonthChange}
      handleDaySelect={(date, modifiers) => {
        onDaySelect(new Date(date), modifiers);
      }}
    />
  );
}

export default EventCalendar;
