import CALLSHEET_API from "../../../../../services/api-callsheet";
import { MapActions } from "../constant";
import { TRAVEL_PACK_ENDPOINTS } from "./travel.pack.wizard.endpoints";

export const saveTravelPack = (__shootLegId, __projectId, values) => {
  let params = {
    shootLegId: __shootLegId || 0,
    projectId: __projectId || 0,
    episodeId: 1,
    title: values?.title || "",
    startDate: values?.startDate || "",
    endDate: values?.endDate || "",
  };
  return new Promise((resolve, reject) => {
    CALLSHEET_API.post(`${TRAVEL_PACK_ENDPOINTS.SAVE_TRAVEL_PACK}`, params)
      .then((res) => {
        const data = res?.data?.result || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getMembers = async (__shootLegId, __projectId, memberType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/contactlist/member_options?projectId=${__projectId}&memberType=${memberType}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const mappedData = response?.data?.result.map((item) => {
        return {
          id: item?.userId || "",
          name: item?.fullName || "",
          src: item?.profileImage || "",
          designation: item?.designationName || "",
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getSelectedMembers = async (__shootLegId) => {
  const selectedMembers = {
    crew: [],
    office: [],
    talents: [],
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/contactlist`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      selectedMembers.crew = response?.data?.result?.crewMembers?.map(
        (item) => {
          return {
            id: item?.userId || "",
            name: item?.fullName || "",
            src: item?.profileImage || "",
            designation: item?.designationName || "",
            data: item,
          };
        }
      );

      selectedMembers.office =
        response?.data?.result?.productionOfficeMembers?.map((item) => {
          return {
            id: item?.userId || "",
            name: item?.fullName || "",
            src: item?.profileImage || "",
            designation: item?.designationName || "",
            data: item,
          };
        });

      selectedMembers.talents = response?.data?.result?.talentMembers?.map(
        (item) => {
          return {
            id: item?.userId || "",
            name: item?.fullName || "",
            src: item?.profileImage || "",
            designation: item?.designationName || "",
            data: item,
          };
        }
      );

      resolve(selectedMembers);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveConatctList = (__shootLegId, __projectId, values) => {
  let params = {
    projectId: __projectId,
    crewMembers: values?.crew?.map((item, i) => {
      return {
        memberId: item?.data?.memberId || 0,
        shootLegId: __shootLegId,
        userId: item?.id,
        order: i + 1,
      };
    }),
    productionOfficeMembers: values?.office?.map((item, i) => {
      return {
        memberId: item?.data?.memberId || 0,
        shootLegId: __shootLegId,
        userId: item?.id,
        order: i + 1,
      };
    }),
    talentMembers: values?.talents?.map((item, i) => {
      return {
        memberId: item?.data?.memberId || 0,
        shootLegId: __shootLegId,
        userId: item?.id,
        order: i + 1,
      };
    }),
  };

  return new Promise((resolve, reject) => {
    CALLSHEET_API.post(`callsheet/shootleg/${__shootLegId}/contactlist`, params)
      .then((res) => {
        const data = res?.data?.result || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getSchedulePriorityFilter = async (__shootLegId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/schedule/priorityFilter`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const sorted = [...response?.data?.result].sort(
        (a, b) => a.order - b.order
      );
      console.log("sorted::: ", sorted);

      const mappedData = sorted?.map((item) => {
        return {
          icon: item?.icon || "",
          label: item?.tagTypeName || "",
          data: item,
        };
      });

      resolve(mappedData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveSchedulePriorityFilter = (__shootLegId, values) => {
  let params = {
    schedulePriorities: values?.tagTypes?.map((item, i) => {
      return {
        schedulePriorityId: item?.data?.schedulePriorityId || 0,
        shootLegId: __shootLegId,
        callSheetId: 0,
        userId: "",
        tagTypeId: item?.data?.tagTypeId || 0,
        order: i + 1,
      };
    }),
  };
  return new Promise((resolve, reject) => {
    CALLSHEET_API.post(
      `${TRAVEL_PACK_ENDPOINTS.SAVE_SCHEDULE_PRIORITY_FILTER}`,
      params
    )
      .then((res) => {
        const data = res?.data?.result || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getTravelPackScheduleDetails = async (__shootLegId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/schedule`
      );

      if (!response?.data?.isSuccess) {
        resolve(null);
        return;
      }

      const data = response?.data?.result || null;

      resolve(data);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveTravelPackScheduleDetails = (__shootLegId, values) => {
  let params = {
    shootLegId: __shootLegId,
    callSheetId: 0,
    userId: "",
    isTravelCalculate: values?.calculateTravel?.value || false,
    travelMode: values?.modelOfTravel?.value || 0,
    setUpTime: values?.setupTime || "",
    tearDownTime: values?.tearDownTime || "",
    minEventDuration: values?.minEventDueration || "",
    maxEventsPerDay: 0,
    mealBreak: values?.lunchBreak || "",
    workHours: values?.workHours || "",
    maxOT: values?.maxOT || "",
    isFlagConflicts: values?.flagConflicts?.value || false,
    holidayCountryIds: values?.publicHolidays?.map((item) => item.value) || [],
  };
  return new Promise((resolve, reject) => {
    CALLSHEET_API.post(
      `${TRAVEL_PACK_ENDPOINTS.SAVE_TRAVEL_PACK_SCHEDULE_DETAILS}`,
      params
    )
      .then((res) => {
        const data = res?.data?.result || null;
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
export const getSchedulePriorityFilterForMapping = async (__shootLegId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/schedule/priorityFilter`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const sorted = [...response?.data?.result].sort(
        (a, b) => a.order - b.order
      );

      const mappedData = sorted?.map((item) => {
        return {
          icon: item?.icon || "",
          name: item?.tagTypeName || "",
          type: item?.tagTypeId || 0,
          order: item?.order || 0,
          data: item,
        };
      });

      //Only get order 1-3
      const filteredData = mappedData?.filter(
        (item) => item.order >= 1 && item.order <= 3
      );

      resolve(filteredData);

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const getMappingData = async (__shootLegId, __date, __projectId) => {
  //   const dummyData = {
  //     tagMappingDetails: [
  //       {
  //         tagTypeId: 1,
  //         order: 1,
  //         tagTypeName: "Contributor",
  //         tagTypePluralName: "Contributors",
  //         icon: "icon",
  //         mobileIcon: "mobileIcon",
  //         tagList: [
  //           {
  //             tagId: 1,
  //             mainFieldValue: "Tharindu",
  //             src: "https://saruwataarchive.blob.core.windows.net/loop-documents-dev/82d1f545-e9a3-46f5-8b28-d2e94defac64-2024-09-18-09-47-33-jpeg_.jpeg",
  //             designation: "Software Engineer",
  //             isSelected: true,
  //             parentTagId: 0,
  //           },
  //           {
  //             tagId: 2,
  //             mainFieldValue: "Darshaka",
  //             src: "url",
  //             designation: "",
  //             isSelected: false,
  //             parentTagId: 0,
  //           },
  //           {
  //             tagId: 3,
  //             mainFieldValue: "Namila",
  //             src: "url",
  //             designation: "",
  //             isSelected: false,
  //             parentTagId: 0,
  //           },
  //         ],
  //       },
  //       {
  //         tagTypeId: 3,
  //         order: 2,
  //         tagTypeName: "Location",
  //         tagTypePluralName: "Locations",
  //         icon: "icon",
  //         mobileIcon: "mobileIcon",
  //         tagList: [
  //           {
  //             tagId: 4,
  //             mainFieldValue: "Lotus Tower",
  //             src: "url",
  //             designation: "",
  //             isSelected: false,
  //             parentTagId: 0,
  //           },
  //           {
  //             tagId: 5,
  //             mainFieldValue: "Colombo Port",
  //             src: "url",
  //             designation: "",
  //             isSelected: true,
  //             parentTagId: 0,
  //           },
  //         ],
  //       },
  //       {
  //         tagTypeId: 4,
  //         order: 3,
  //         tagTypeName: "Access",
  //         tagTypePluralName: "Access",
  //         icon: "icon",
  //         mobileIcon: "mobileIcon",
  //         tagList: [
  //           {
  //             tagId: 6,
  //             mainFieldValue: "Biracial Japanese Family",
  //             src: "url",
  //             designation: "",
  //             isSelected: false,
  //             parentTagId: 0,
  //           },
  //         ],
  //       },
  //     ],
  //     chartDetails: {
  //       stylingMap: {
  //         contributor: { background: "#00CCF8FF", color: "#FFF" },
  //         location: { background: "#569e1f", color: "#FFF" },
  //         access: { background: "#e8764f", color: "#FFF" },
  //         event: { background: "#fff", color: "#000", stroke: "#000" },
  //         setup: { background: "#fff", color: "#000", stroke: "#000" },
  //         teardown: { background: "#fff", color: "#000", stroke: "#000" },
  //         travel: { background: "#686868", color: "#FFF" },
  //       },
  //       taskArray: [
  //         {
  //           label: "Travel",
  //           type: "travel",
  //           group: 1,
  //           startTime: "06:30",
  //           endTime: "08:00",
  //         },
  //         {
  //           label: "S/U",
  //           type: "setup",
  //           group: 1,
  //           startTime: "08:00",
  //           endTime: "08:30",
  //         },
  //         {
  //           label: "Event 01 - 90 mins Duration",
  //           type: "event",
  //           group: 1,
  //           startTime: "08:30",
  //           endTime: "12:30",
  //         },
  //         {
  //           label: "T/D",
  //           type: "teardown",
  //           group: 1,
  //           startTime: "12:30",
  //           endTime: "13:00",
  //         },
  //         {
  //           label: "Contributor (Savannah Jen)",
  //           type: "contributor",
  //           group: 1,
  //           startTime: "08:30",
  //           endTime: "12:30",
  //         },
  //         {
  //           label: "Location (Galle-face Green)",
  //           type: "location",
  //           group: 1,
  //           startTime: "8:00",
  //           endTime: "16:00",
  //         },
  //         {
  //           label: "Access (Galle-face Green)",
  //           type: "access",
  //           group: 1,
  //           startTime: "08:00",
  //           endTime: "13:00",
  //         },
  //         {
  //           label: "Travel",
  //           type: "travel",
  //           group: 2,
  //           startTime: "13:30",
  //           endTime: "15:00",
  //         },
  //         {
  //           label: "S/U",
  //           type: "setup",
  //           group: 1,
  //           startTime: "15:00",
  //           endTime: "15:30",
  //         },
  //         {
  //           label: "Event 01 - 90 mins Duration",
  //           type: "event",
  //           group: 1,
  //           startTime: "15:30",
  //           endTime: "20:30",
  //         },
  //         {
  //           label: "T/D",
  //           type: "teardown",
  //           group: 1,
  //           startTime: "20:30",
  //           endTime: "21:00",
  //         },
  //         {
  //           label: "Contributor (Savannah Jen)",
  //           type: "contributor",
  //           group: 1,
  //           startTime: "15:30",
  //           endTime: "20:30",
  //         },
  //         {
  //           label: "Location (Galle-face Green)",
  //           type: "location",
  //           group: 1,
  //           startTime: "14:00",
  //           endTime: "22:00",
  //         },
  //         {
  //           label: "Access (Galle-face Green)",
  //           type: "access",
  //           group: 1,
  //           startTime: "15:00",
  //           endTime: "21:00",
  //         },
  //       ],
  //     },
  //   };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await CALLSHEET_API.get(
        `callsheet/shootleg/${__shootLegId}/mapping/${__date?.value}?projectId=${__projectId}`
      );

      if (!response?.data?.isSuccess) {
        resolve([]);
        return;
      }

      const sorted = [...response?.data?.result.tagMappingDetails].sort(
        (a, b) => a.order - b.order
      );

      const mappedData = sorted?.map((item) => {
        return {
          icon: item?.icon || "",
          name: item?.tagTypeName || "",
          type: item?.tagTypeId || 0,
          order: item?.order || 0,
          tagList: item?.tagList || [],
          data: item,
        };
      });

      //Only get order 1-3
      const filteredData = mappedData?.filter(
        (item) => item.order >= 1 && item.order <= 3
      );

      resolve({ ...response?.data?.result, tagMappingDetails: filteredData });

      //
    } catch (error) {
      reject(error);
    }
  });
};
export const saveMappingData = (__shootLegId, __date, values, __projectId) => {
  let params = {
    tagId: values?.data?.tagId,
    tagTypeId: values?.type || 0,
    projectId: __projectId,
    action:
      values?.isSelected === true ? MapActions.Select : MapActions.Deselect,
    date: __date?.value,
  };

  return new Promise((resolve, reject) => {
    CALLSHEET_API.post(`callsheet/shootleg/${__shootLegId}/mapping`, params)
      .then((response) => {
        const sorted = [...response?.data?.result.tagMappingDetails].sort(
          (a, b) => a.order - b.order
        );

        const mappedData = sorted?.map((item) => {
          return {
            icon: item?.icon || "",
            name: item?.tagTypeName || "",
            type: item?.tagTypeId || 0,
            order: item?.order || 0,
            tagList: item?.tagList || [],
            data: item,
          };
        });

        //Only get order 1-3
        const filteredData = mappedData?.filter(
          (item) => item.order >= 1 && item.order <= 3
        );

        resolve({ ...response?.data?.result, tagMappingDetails: filteredData });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const error = err.response.data;
          reject({
            systemeError: {
              errors: error.errors,
              title: error.title,
            },
          });
        } else {
          reject(err);
        }
      });
  });
};
