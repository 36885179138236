import React from "react";
import * as Styled from "./styled";
import IconButton from "../../../../../../../../../common/components/Buttons/IconButton";
import IconAdd from "../../../../../../../../../common/components/icons/Line/Add/04";
import IconMinus from "../../../../../../../../../common/components/icons/Line/Minus/01";

function NumberSelector(props) {
  const { maxVal, value = 0, onChange } = props;

  const [number, setNumber] = React.useState(value);

  function handleClick(val) {
    let __num = number;

    if ((val === -1 && __num === 0) || (val === 1 && maxVal <= __num)) {
      return;
    }
    __num = __num + val;

    setNumber(__num);
    onChange && onChange(__num);
  }

  return (
    <Styled.SelectorContainer>
      <IconButton
        onClick={() => handleClick(-1)}
        icon={<IconMinus />}
        className="iconButton"
        disabled={number === 0}
        btnIconSize={16}
        btnSize={20}
      />

      <div className="number">{number < 10 ? `0${number}` : number}</div>

      <IconButton
        onClick={() => handleClick(1)}
        icon={<IconAdd />}
        className="iconButton"
        disabled={number === maxVal}
        btnIconSize={16}
        btnSize={20}
      />
    </Styled.SelectorContainer>
  );
}

export default NumberSelector;
