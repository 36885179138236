import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";
import Popover from "../../../../../common/components/Popover";

export const EPopover = styled(Popover)`
  &[data-popper-reference-hidden="true"] {
    display: none;
  }
`;
export const PopoverContainer = styled.div`
  border-radius: var(--layout-borderRadius-4, 4px);
  border: 1px solid var(--layout-background-N30, #ebecef);
  background: var(--color-neutral-20, #f5f5f7);
  max-width: 287px;

  /* layout/boxShadow/primary/5 */
  box-shadow: 0px 8px 18px 10px rgba(45, 89, 102, 0.05);

  .event_popover__inner {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: var(--layout-spacing-12) var(--layout-spacing-12) 0
        var(--layout-spacing-16);

      .icon__button__element {
        --icon-button-icon-color: var(--font-tint-04);

        &:hover {
          opacity: 0.6;
        }
      }

      .close__icon__btn {
        margin-left: var(--layout-spacing-12);
      }
    }

    .content {
      padding: 0 var(--layout-spacing-16) var(--layout-spacing-12);
      display: flex;
      flex-direction: column;
      gap: var(--layout-spacing-8);

      .start {
        display: flex;
        flex-direction: column;

        .title {
          ${fontStyle("x-small-medium")};
          font-weight: 500;
          color: var(--color-primary-400, #012732);
        }

        .subtitle {
          ${fontStyle("x-small-regular")};
          color: var(--color-neutral-60, #b2b5c2);
        }
      }

      .end {
        p {
          margin: 0;
          padding: 0;
          ${fontStyle("x-small-regular")};
          color: var(--color-neutral-60, #b2b5c2);
        }
      }
    }
  }
`;
