import React from "react";
import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import InputField from "../../../../../../common/components/Form/InputField";
// import DatePickerField from "../../../../../../common/components/Form/DatePickerField";
import SelectField from "../../../../../../common/components/Form/SelectField";
// import TimePickerField from "../../../../../../common/components/Form/TimePickerField";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";
import { useTravelPackWizardStore } from "../../store";
import useFormSubmission from "../../hooks/useFormSubmission";
import * as Yup from "yup";
import { TravelMode, YesNo } from "../../constant";
import { getTravelPackScheduleDetails } from "../../api";
import { toast } from "react-toastify";

const StepKey = "step_04";
const initialValues = {
  calculateTravel: null,
  modelOfTravel: null,
  setupTime: "",
  tearDownTime: "",
  minEventDueration: "",
  lunchBreak: "",
  workHours: "",
  maxOT: "",
  flagConflicts: null,
  publicHolidays: [],
};
const validationSchema = Yup.object().shape({
  calculateTravel: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("This field is required"),
  modelOfTravel: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("This field is required"),
  setupTime: Yup.string().required("This field is required"),
  tearDownTime: Yup.string().required("This field is required"),
  minEventDueration: Yup.string().required("This field is required"),
  lunchBreak: Yup.string().required("This field is required"),
  workHours: Yup.string().required("This field is required"),
  maxOT: Yup.string().required("This field is required"),
});
let countries = [];
const DefaultProvisions = ({ mode, onSubmitted }) => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);
  const currentTravelPack = useTravelPackWizardStore(
    (state) => state.currentTravelPack
  );
  const userCountries = useAppStore((state) => state.countries);
  countries = userCountries.map((data) => ({
    value: data.countryId,
    label: data.countryName,
  }));
  // Form submit hook
  const { formik } = useFormSubmission({
    key: StepKey,
    initialValues,
    validationSchema,
    onSubmitted,
  });
  const { values, handleChange, setFieldValue, errors, touched } = formik;

  React.useEffect(() => {
    showLoader();

    getTravelPackScheduleDetails(currentTravelPack?.shootLegId || 0)
      .then((data) => {
        if (!data) {
          hideLoader();
          return;
        }
        // Set form values
        setFieldValue(
          "calculateTravel",
          YesNo?.find((x) => x.value === data?.isTravelCalculate) || false
        );
        setFieldValue(
          "modelOfTravel",
          TravelMode?.find((t) => t.value === data?.travelMode || 0) || null
        );
        setFieldValue("setupTime", data?.setUpTime || "");
        setFieldValue("tearDownTime", data?.tearDownTime || "");
        setFieldValue("minEventDueration", data?.minEventDuration || "");
        setFieldValue("lunchBreak", data?.mealBreak || "");
        setFieldValue("workHours", data?.workHours || "");
        setFieldValue("maxOT", data?.maxOT || "");
        setFieldValue(
          "flagConflicts",
          YesNo?.find((x) => x.value === data?.isFlagConflicts) || false
        );

        getCountries(data?.holidayCountryIds || []).then((countries) => {
          setFieldValue("publicHolidays", countries || []);
        });

        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        console.log("Travel Pack Schedule Details", error);
        toast.error(
          "Getting Travel Pack Schedule Details Error: " + error.message
        );
      });
  }, [currentTravelPack?.shootLegId, hideLoader, setFieldValue, showLoader]);

  const getCountries = async (data) => {
    return new Promise(async (resolve) => {
      const selectedHolidayCountries = [];
      data?.forEach((countryId) => {
        let country = countries?.find((c) => c.value === countryId) || null;
        if (country) {
          selectedHolidayCountries.push(country);
        }
      });

      resolve(selectedHolidayCountries);
    });
  };

  return (
    <form>
      <div className="grid g2col">
        <div className="g-col">
          <FieldGroup
            label="Calculate Travel"
            error={touched.calculateTravel && errors.calculateTravel}
          >
            <SelectField
              placeholder="Select"
              options={YesNo}
              name="calculateTravel"
              value={values.calculateTravel || null}
              onChange={(select) => {
                setFieldValue("calculateTravel", select || null);
              }}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Model of Travel"
            error={touched.modelOfTravel && errors.modelOfTravel}
          >
            <SelectField
              placeholder="Select"
              options={TravelMode}
              name="modelOfTravel"
              value={values.modelOfTravel || null}
              onChange={(select) => {
                setFieldValue("modelOfTravel", select || null);
              }}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Set Up Time"
            error={touched.setupTime && errors.setupTime}
          >
            <InputField
              type="time"
              placeholder="hh:mm"
              name="setupTime"
              value={values.setupTime}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Tear Down Time"
            error={touched.tearDownTime && errors.tearDownTime}
          >
            <InputField
              type="time"
              placeholder="hh:mm"
              name="tearDownTime"
              value={values.tearDownTime}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Min Event Duration"
            error={touched.minEventDueration && errors.minEventDueration}
          >
            <InputField
              placeholder="hh:mm"
              name="minEventDueration"
              value={values.minEventDueration}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Lunch Break"
            error={touched.lunchBreak && errors.lunchBreak}
          >
            <InputField
              placeholder="hh:mm"
              name="lunchBreak"
              value={values.lunchBreak}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup
            label="Work Hours"
            error={touched.workHours && errors.workHours}
          >
            <InputField
              placeholder="hh:mm"
              name="workHours"
              value={values.workHours}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup label="Max OT" error={touched.maxOT && errors.maxOT}>
            <InputField
              placeholder="hh:mm"
              name="maxOT"
              value={values.maxOT}
              onChange={handleChange}
            />
          </FieldGroup>
        </div>

        <div className="g-col">
          <FieldGroup label="Flag Conflicts">
            <SelectField
              placeholder="Select"
              options={YesNo}
              name="flagConflicts"
              value={values.flagConflicts || null}
              onChange={(select) => {
                setFieldValue("flagConflicts", select || null);
              }}
            />
          </FieldGroup>
        </div>

        <div className="g-col full-width">
          <FieldGroup label="Public Holidays">
            <SelectField
              isMulti
              placeholder="Select"
              options={countries}
              name="publicHolidays"
              value={values.publicHolidays || null}
              onChange={(select) => {
                setFieldValue("publicHolidays", select || null);
              }}
            />
          </FieldGroup>
        </div>
      </div>
    </form>
  );
};

export default DefaultProvisions;
