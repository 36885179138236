import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.12677 5.12423C8.18571 5.04434 8.25111 4.96834 8.32263 4.8968L10.764 2.45539C11.1162 2.10323 11.5781 1.86565 12.0751 1.83443C12.6468 1.79853 13.1906 2.00543 13.5928 2.40761C13.995 2.80979 14.2019 3.35362 14.166 3.92534C14.1348 4.42239 13.8972 4.88426 13.5451 5.23642L11.1036 7.67781C11.0321 7.74933 10.9561 7.81473 10.8762 7.87367C10.4337 8.20033 10.4031 8.85316 10.792 9.24208C11.1124 9.56245 11.6213 9.60421 11.9841 9.33287C12.1289 9.2246 12.2676 9.10463 12.3991 8.97319L14.8882 6.48405C15.6294 5.74286 16.0016 4.77049 16 3.7981C16 2.82408 15.6294 1.85333 14.8866 1.11381C13.4059 -0.370172 11.0003 -0.371818 9.51634 1.11216L7.02722 3.6014C6.89578 3.73284 6.77585 3.87156 6.66755 4.01634C6.39623 4.37914 6.43799 4.88808 6.75834 5.20843C7.1473 5.59734 7.80014 5.56676 8.12677 5.12423Z"
      fill="currentColor"
    />
    <path
      d="M7.87326 10.8767C7.81431 10.9566 7.74891 11.0326 7.67739 11.1041L5.236 13.5456C4.88384 13.8977 4.42195 14.1353 3.92492 14.1665C3.3532 14.2024 2.80938 13.9955 2.4072 13.5933C2.00502 13.1912 1.79812 12.6473 1.83401 12.0756C1.86523 11.5786 2.10281 11.1167 2.45497 10.7645L4.89639 8.32314C4.9679 8.25162 5.04392 8.18623 5.12381 8.12728C5.56634 7.80063 5.59692 7.14779 5.20799 6.75887C4.88764 6.43851 4.37872 6.39674 4.01589 6.66808C3.87112 6.77636 3.7324 6.89632 3.60096 7.02776L1.11177 9.51683C0.370601 10.258 -0.00164104 11.2304 5.43792e-06 12.2028C5.43792e-06 13.1768 0.370601 14.1475 1.11342 14.8871C2.59413 16.3711 4.99965 16.3727 6.48366 14.8887L8.9728 12.3996C9.10424 12.2681 9.22418 12.1294 9.33247 11.9846C9.60379 11.6218 9.56203 11.1129 9.24168 10.7925C8.85272 10.4036 8.19988 10.4342 7.87326 10.8767Z"
      fill="currentColor"
    />
    <path
      d="M11.8725 11.2174C11.6914 11.0362 11.3978 11.0362 11.2167 11.2174C11.0355 11.3984 11.0355 11.6922 11.2167 11.8732L13.0173 13.6737C13.1079 13.7643 13.2265 13.8096 13.3452 13.8096C13.4639 13.8096 13.5825 13.7643 13.6731 13.6737C13.8543 13.4926 13.8543 13.1989 13.6731 13.0179L11.8725 11.2174Z"
      fill="currentColor"
    />
    <path
      d="M15.4438 10.1762L12.9294 9.77538C12.6785 9.73472 12.4389 9.90728 12.3986 10.1604C12.358 10.4133 12.5306 10.6511 12.7836 10.6914L15.298 11.0923C15.3227 11.0962 15.3471 11.0981 15.3716 11.0981C15.5953 11.0981 15.7926 10.9354 15.8288 10.7073C15.8694 10.4543 15.6968 10.2165 15.4438 10.1762Z"
      fill="currentColor"
    />
    <path
      d="M10.6908 12.784C10.6505 12.5309 10.4105 12.359 10.16 12.399C9.90704 12.4393 9.73447 12.6771 9.775 12.9301L10.1758 15.4446C10.2121 15.6728 10.4091 15.8355 10.6331 15.8355C10.6575 15.8355 10.682 15.8336 10.7067 15.8296C10.9596 15.7893 11.1322 15.5515 11.0917 15.2986L10.6908 12.784Z"
      fill="currentColor"
    />
    <path
      d="M4.12727 4.78368C4.21785 4.87426 4.33652 4.91955 4.4552 4.91955C4.57389 4.91955 4.69252 4.87426 4.78313 4.78368C4.96429 4.60262 4.96429 4.3089 4.78313 4.12785L2.98274 2.32735C2.80158 2.1462 2.50807 2.1462 2.32691 2.32735C2.14575 2.50842 2.14575 2.80214 2.32691 2.98318L4.12727 4.78368Z"
      fill="currentColor"
    />
    <path
      d="M5.30919 3.21703C5.34541 3.44519 5.54245 3.60789 5.76642 3.60789C5.79088 3.60789 5.81533 3.60597 5.84002 3.602C6.09298 3.5617 6.26556 3.32391 6.22502 3.07096L5.82418 0.556421C5.78409 0.303351 5.54428 0.13068 5.29335 0.171425C5.04039 0.211729 4.86782 0.449517 4.90835 0.702471L5.30919 3.21703Z"
      fill="currentColor"
    />
    <path
      d="M0.555957 5.82486L3.07061 6.22569C3.09528 6.22966 3.11975 6.23158 3.14421 6.23158C3.36795 6.23158 3.5652 6.06886 3.60145 5.84072C3.64198 5.58777 3.46943 5.34998 3.21645 5.30967L0.701797 4.90882C0.451093 4.8684 0.211056 5.04072 0.17096 5.29381C0.130448 5.54674 0.303003 5.78453 0.555957 5.82486Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
