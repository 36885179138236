import React from "react";
import { ColumnItem } from "../styled";

const Item = ({ data, isSelected, onClick }) => {
  return (
    <ColumnItem data={data} $isSelected={isSelected} onClick={onClick}>
      <div className="start">
        <ColumnItem.Avatar size="size-36" />
      </div>

      <div className="center">
        <ColumnItem.Title />
        <ColumnItem.Caption className="caption" />
      </div>
    </ColumnItem>
  );
};

export default Item;
