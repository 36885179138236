import React from "react";
import * as Styled from "./styled";
import IconButton from "../../../../../Buttons/IconButton";
import IconEdit from "../../../../../icons/Line/Edit/01";

const BodyContainer = ({
  mainHeader,
  subHeader,
  children,
  slots,
  showEditButton = false,
  handleEdit,
  cardClassName,
  headerEndContents,
}) => {
  const renderLeft = React.useMemo(() => {
    if (!slots?.leftComponent) return null;

    const Component = slots?.leftComponent;

    return <Component />;
  }, [slots?.leftComponent]);

  const renderRight = React.useMemo(() => {
    if (!slots?.rightComponent) return null;

    const Component = slots?.rightComponent;

    return <Component />;
  }, [slots?.rightComponent]);

  return (
    <Styled.Container>
      <div className="headers">
        <div className="start">
          <div className="main__header">{mainHeader}</div>
          <div className="sub__header">{subHeader}</div>
        </div>

        <div className="end">
          {showEditButton && (
            <IconButton
              className="edit__button"
              icon={<IconEdit />}
              onClick={handleEdit}
            />
          )}

          {headerEndContents}
        </div>
      </div>
      <div className={["form__card", cardClassName].join(" ")}>
        {/* content */}
        <div className="form__wrapper">{children}</div>

        {/* footer */}
        <div className="form__button__wrapper">
          <div className="left__button">{renderLeft}</div>
          <div className="right__button">{renderRight}</div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default BodyContainer;
