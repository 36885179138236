import styled from "styled-components";
import { fontStyle } from "../../../../../styled/utility";

export const Wrapper = styled.div`
  position: absolute;
  width: calc(100% - var(--layout-spacing-8));
  background-color: var(--color-accent-75);
  border-radius: var(--layout-border-radius-4);
  box-shadow: -1px 0px 0px 0px rgb(45 89 102 / 05%);
  padding: var(--layout-spacing-2) var(--layout-spacing-4);
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
  pointer-events: auto;
  overflow: hidden;

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return `
        opacity: 0.5;
        cursor: auto;
      `;
    }
  }};

  .title,
  .duration {
    ${fontStyle("xs-small-medium")};
    color: var(--color-primary-400, #012732);
    font-weight: 500;
  }

  .duration {
    color: var(--font-tint-04);
    font-weight: 400;
    font-size: 11px;
    line-height: 1;
  }
`;
