import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import withAuth from "../../../../hoc/withAuth";

import { PublicRoutePath } from "../../../../routes/paths/public-path";
import { usePermissionStore } from "../../../../store/AppStore/PermissionStore";
import useUserData from "../../../../hooks/useUserData";
import Loader from "../../../ui-components/Loader";

import Toolbar from "../Components/Toolbar";
import LeftSide from "../leftside";

import "./styles.scss";
import { SYSTEM_ROLE_TYPE } from "../../../../constant/System";
import { useProjectStore } from "../../../../store/ProjectStore";
import { useAppStore } from "../../../../store/AppStore/AppStore";
import { useToastMessage } from "../../../../context/ToastMessageProvider";
import { useGlobalStore } from "../../../../store/GlobalStore";
import AddTemporaryUserModal from "../../../ui-components/Modals/AddTemporaryUserModal";
import ChatBoxManager from "../../../../setup/ChatBoxManager";
import ProjectWizardModal from "../../../../shared/components/Modals/ProjectWizardModal";
import TravelPackWizard from "../../../../shared/components/Modals/TravelPackWizard";
import NotificationPopover from "../../../../setup/NotificationPopover";

const MainLayout = (props) => {
  const { contentId = "containerBody" } = props;
  const { userRoles } = useUserData();

  const { userId, tenantId } = useUserData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { fireToast } = useToastMessage();

  const isLoading = usePermissionStore((state) => state.loading);
  const error = usePermissionStore((state) => state.menuLoadingError);
  const onLoadAppMenu = usePermissionStore((state) => state.onLoadAppMenu);
  const isOpenSideMenu = useAppStore((state) => state.isOpenSideMenu);
  const projectId = useGlobalStore((state) => state.selectedProjectId) || 0;

  const getProjectsByTenantId = useProjectStore(
    (state) => state.getProjectsByTenantId
  );

  const getProjectsBy_TenantAndUserId = useProjectStore(
    (state) => state.getProjectsByTenantAndUserId
  );

  const isAdmin = userRoles?.find((u) => u === SYSTEM_ROLE_TYPE.ADMIN);

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    const pId = isAdmin ? 0 : projectId;

    onLoadAppMenu(userId, pId)
      .then(() => {})
      .catch((err) => {
        fireToast((toast) => toast.error(err.message));
      });
  }, [onLoadAppMenu, userId, fireToast, projectId, isAdmin]);

  React.useEffect(() => {
    if (!tenantId) return;

    if (isAdmin) {
      getProjectsByTenantId(tenantId);
    } else if (userId) {
      getProjectsBy_TenantAndUserId(tenantId, userId);
    }
  }, [
    tenantId,
    userId,
    getProjectsByTenantId,
    getProjectsBy_TenantAndUserId,
    isAdmin,
  ]);

  // when error is returned
  React.useEffect(() => {
    if (error) {
      navigate(PublicRoutePath.Login.path, { state: { from: pathname } });
    }
  }, [error, navigate, pathname]);

  if (isLoading) {
    return <Loader />;
  }

  const sideMenuClasses = isOpenSideMenu ? "opened-side-menu" : null;

  return (
    <section className={["main__layout__wrapper", sideMenuClasses].join(" ")}>
      <LeftSide />

      <div className="main__layout">
        <Toolbar />
        {/* <Header /> */}

        <div className="main__layout__contents" id={contentId}>
          <Outlet />
        </div>

        {/* Add Temporary User Modal  */}
        <AddTemporaryUserModal />

        <ProjectWizardModal />

        <TravelPackWizard />

        <ChatBoxManager />

        <NotificationPopover />
      </div>
    </section>
  );
};

export default withAuth(MainLayout);
// export default withAuth(withProfileUpdateAlert(MainLayout));
