import styled from "styled-components";
import Popover from "../../Popover";
import { fontStyle } from "../../../../styled/utility";

export const Wrapper = styled.div`
  width: 100%;
`;

export const SearchPopover = styled(Popover)`
  .list-wrapper {
    margin-top: var(--layout-spacing-2);
    background-color: var(--font-default-white);
    border-radius: 0 0 var(--layout-border-radius-4)
      var(--layout-border-radius-4);
    overflow-y: auto;
    max-height: 50vh;
    padding: var(--layout-spacing-4) 0;
  }
`;

export const NotFound = styled.div`
  width: 100%;
  padding: var(--layout-spacing-8) var(--layout-spacing-16);
  color: var(--font-tint-04);
  ${fontStyle("small-regular")};
`;
