import React from "react";
import Masonry from "masonry-layout";

import { Delete, Edit } from "../../../../../../common/components/icons/Fill";
import { Close } from "../../../../../../common/components/icons/Popup/Popovers";
import { StyledIconButton } from "../../../../../../styled/Common/Buttons";
import { IconBlock } from "../../../../../../styled/Common/Icons";
import {
  TAG_MODAL_MODE,
  useResearchBoardStore,
} from "../../../../store/ResearchBoardStore";
import SubTagContent from "../../../research-data/ContentBlocks/SubTagContent";

import useTagCardContent from "../../../../hooks/useTagCardContent";

import {
  modalCss,
  StyledModalHeader,
  StyledOtherContent,
  StyledStatus,
  StyledTopContent,
} from "./styled";
import Typography from "../../../../../../common/components/Typography";
import BaseModal from "../../../../../../common/components/UI/Modal/BaseModal";
import { RESEARCH_TAG_VISUAL_TYPE } from "../../../research-data/TagCard";
import SlideViewer from "./SlideViewer";
import { tagDeleteByTagId } from "../../../../api/dynamic-form";
import { useMutation } from "react-query";
import { useToastMessage } from "../../../../../../context/ToastMessageProvider";
import { getTagRelationshipByParentId } from "../../../../api/common/tags";
import useTagTypes from "../../../../hooks/useTagTypes";

const ModalColumn = ({ title, children }) => {
  return (
    <div className="popup__column">
      <div className="popup__column__title">
        <Typography
          variant={Typography.font_variant.large}
          fontWeight={Typography.font_weight.bold}
        >
          {title}
        </Typography>
      </div>

      <div className="content">{children}</div>
    </div>
  );
};

const PreviewModal = (props) => {
  const containerRef = React.useRef(null);
  const [relationships, setRelationships] = React.useState([]);
  const visibility = useResearchBoardStore((state) => state.showPreviewModal);
  const modalData = useResearchBoardStore((state) => state.previewModalData);
  const onShowTagModal = useResearchBoardStore((state) => state.onShowTagModal);
  const onTagRevalidate = useResearchBoardStore((s) => s.onTagRevalidate);
  const [subTagsContent, setSubTags] = React.useState([]);
  let subTagTypefileds = modalData?.subTagsCategorizedTagFileds;
  //console.log("subTagTypefileds: ", subTagTypefileds);
  const onCloseModal = useResearchBoardStore(
    (state) => state.onHidePreviewModal
  );
  const { tagTypeItems } = useTagTypes("tags-in-modal", {
    enabled: !!true,
  });
  const { toast } = useToastMessage();

  const { content: renderContents } = useTagCardContent({
    variant: modalData?.variant,
    galleryOptions: {
      thumbSize: "54px",
      slidesPerView: 7,
      thumbsCenteredSlides: true,
    },
    visual: modalData?.visual,
    visualType:
      modalData?.formData?.find((i) => i.displayTypeId === 4)?.value?.length !==
      0
        ? modalData?.visualType
        : RESEARCH_TAG_VISUAL_TYPE.EMPTY,
  });

  React.useEffect(() => {
    const fetchAndPrepareSubTagData = async () => {
      try {
        let relationshipsData = null;
        if (modalData) {
          relationshipsData = await getTagRelationshipByParentId(
            modalData?.tagData?.tagId
          );
        }
        setRelationships(relationshipsData);
        console.log("Fetched relationshipsData:", relationshipsData);

        let finalSubTagTypefileds = subTagTypefileds;

        if (relationshipsData) {
          const customTagFields = relationshipsData?.map((relationship) => {
            const matchingTagType = tagTypeItems.find(
              (d) => d.__typeId === relationship.tag.tagTypeId
            );

            return {
              displayName: matchingTagType
                ? matchingTagType.__typeName
                : "Unknown",
              displayTypeId: 100,
              fieldTypeId: 100,
              icon: matchingTagType ? matchingTagType.__icon : null,
              relationshipId: relationship.relationshipId,
              tagSubTypeName: matchingTagType
                ? matchingTagType.__typeName
                : "Unknown",
              value: relationship.tag.mainFieldValue,
              visualTypeId: 100,
            };
          });

          const customSubTagTypefileds = [
            ...subTagTypefileds,
            {
              icon: "fill-users",
              tagSubTypeId: 100,
              tagSubTypeName: "Tag Relationship",
              tagFileds: customTagFields,
            },
          ];

          finalSubTagTypefileds = customSubTagTypefileds;
        }

        renderSubTags(finalSubTagTypefileds);
      } catch (error) {
        console.error("Error fetching or preparing subTag data:", error);
      }
    };

    fetchAndPrepareSubTagData();
  }, [modalData]);

  const renderSubTags = (finalSubTagTypefileds) => {
    try {
      console.log("Rendering subTags with:", finalSubTagTypefileds);

      const content = finalSubTagTypefileds?.map((item, i) => {
        const itemCount = item?.tagFileds?.length || 0;
        const valueEmptyItemCount = item?.tagFileds?.filter(
          (i) =>
            i.value === "" ||
            i.value?.length === 0 ||
            i.value === null ||
            i.value === undefined
        )?.length;

        if (itemCount !== valueEmptyItemCount) {
          return (
            <ModalColumn title={item.tagSubTypeName} key={i}>
              <SubTagContent data={item} tagData={modalData?.tagData || null} />
            </ModalColumn>
          );
        }

        return null;
      });

      setSubTags(content);
    } catch (error) {
      console.error("Error rendering subTags:", error);
    }
  };

  const tagDeleteMutation = useMutation(
    (tagId) => {
      return tagDeleteByTagId(tagId);
    },
    {
      onMutate: () => {},
      onError: (error) => {
        console.error("Mutation error:", error);
      },
      onSuccess: (response) => {
        onTagRevalidate && onTagRevalidate(false);
        if (!response.isSuccess) {
          return;
        }

        toast.success(response?.displayMessage);
        onTagRevalidate && onTagRevalidate();
      },
    }
  );

  const initMasonry = React.useCallback(() => {
    new Masonry(containerRef?.current, {
      columnWidth: ".popup__column__sizer",
      itemSelector: ".popup__column",
      percentPosition: true,
      gutter: ".popup__column__gutter",
      horizontalOrder: true,
    });
  }, []);

  React.useEffect(() => {
    if (containerRef?.current && visibility) {
      setTimeout(() => {
        initMasonry();
      }, 1);
    }
  }, [visibility, subTagsContent, relationships, initMasonry]);

  return (
    <BaseModal
      visibility={visibility}
      onRootClose={onCloseModal}
      outerClassName="preview__modal__outer"
      cssBackdropStyle={modalCss}
    >
      <StyledModalHeader
        $hasBannerContent={modalData?.showContent && renderContents}
      >
        <div className="header__inner">
          <div className="start">
            <StyledStatus />
            <Typography
              className="title"
              variant={Typography.font_variant.h5}
              fontWeight={Typography.font_weight.bold}
            >
              {modalData?.title}
            </Typography>

            <Typography
              className="title"
              variant={Typography.font_variant.small}
              fontWeight={Typography.font_weight.regular}
              as="div"
            >
              {modalData?.subTitle}
            </Typography>
          </div>

          <div className="end">
            <StyledIconButton
              className="delete__btn"
              onClick={() => {
                onCloseModal();
                tagDeleteMutation.mutate(modalData?.tagData?.tagId || null);
              }}
            >
              <IconBlock fontSize="12px">
                <Delete />
              </IconBlock>
            </StyledIconButton>

            <StyledIconButton
              className="edit__btn"
              onClick={() => {
                onCloseModal();

                onShowTagModal({
                  mode: TAG_MODAL_MODE.Edit,
                  tagTypeId: modalData?.tagData?.tagTypeId || null,
                  tagId: modalData?.tagData?.tagId || null,
                  tagData: modalData?.tagData,
                  previewPayload: modalData,
                });
              }}
            >
              <IconBlock>
                <Edit />
              </IconBlock>
            </StyledIconButton>

            <StyledIconButton className="close__btn" onClick={onCloseModal}>
              <IconBlock fontSize="12px">
                <Close />
              </IconBlock>
            </StyledIconButton>
          </div>
        </div>
      </StyledModalHeader>

      {modalData?.showContent && renderContents !== null && (
        <StyledTopContent>
          <div className="inner__content">
            <SlideViewer data={modalData} />
            {/* {renderContents} */}
          </div>
        </StyledTopContent>
      )}

      <StyledOtherContent>
        <div
          className={[
            "inner__content",
            subTagsContent?.filter((d) => d !== null)?.length <= 1
              ? "single__item"
              : "",
          ].join(" ")}
          ref={(ref) => (containerRef.current = ref)}
        >
          <div className="popup__column__gutter"></div>
          <div className="popup__column__sizer"></div>

          {subTagsContent}
        </div>
      </StyledOtherContent>
    </BaseModal>
  );
};

export default PreviewModal;
