import * as React from "react";
import { memo } from "react";
const Svg01 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.18263 6.15428C2.88012 6.15428 2.90195 6.60775 3.20546 6.60775H6.32304C6.63816 6.60775 6.63816 6.15208 6.32304 6.15428C5.35965 6.16049 4.04912 6.15428 3.18263 6.15428Z"
      fill="currentColor"
    />
    <path
      d="M3.18263 8.18315C2.88012 8.18315 2.90195 8.63702 3.20546 8.63702H5.46476C5.77988 8.63702 5.77988 8.18135 5.46476 8.18315C4.50138 8.18976 4.04912 8.18315 3.18263 8.18315Z"
      fill="currentColor"
    />
    <path
      d="M3.18263 10.2124C2.88012 10.2124 2.90195 10.6661 3.20546 10.6661H6.32304C6.63816 10.6661 6.63816 10.2104 6.32304 10.2124C5.35965 10.2186 4.04912 10.2124 3.18263 10.2124Z"
      fill="currentColor"
    />
    <path
      d="M3.18263 12.2415C2.88012 12.2415 2.90195 12.6964 3.20546 12.6964H6.32304C6.63816 12.6964 6.63816 12.2407 6.32304 12.2415C5.35965 12.2479 4.04912 12.2415 3.18263 12.2415Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53581 0C4.46914 0 4.40648 0.0290296 4.36363 0.0792809L1.05626 3.87996C1.01982 3.9218 1 3.97406 1 4.02931V15.7723C1 15.8986 1.1023 16.0009 1.22723 15.9997H9.40438C9.53391 16.0059 9.64222 15.9024 9.64222 15.7733C9.64222 15.6442 9.53391 15.5409 9.40438 15.5459H1.45346V4.25554H4.93622C5.06035 4.25454 5.16145 4.15444 5.16145 4.02931V1.42866C5.16245 1.30033 5.05914 1.19702 4.93081 1.19922C4.80689 1.20143 4.70678 1.30353 4.70799 1.42866V3.80188H1.72474L4.63811 0.454664H12.321V5.52044C12.319 5.64737 12.4211 5.75067 12.5484 5.75067C12.6755 5.75067 12.7776 5.64737 12.7756 5.52044V0.228433C12.7766 0.102104 12.6745 0 12.5484 0H4.53581Z"
      fill="currentColor"
    />
    <path
      d="M15.5543 7.79756C15.519 7.80076 15.4846 7.81117 15.4542 7.82879L14.1949 8.55353C14.079 8.61199 14.0363 8.75594 14.1008 8.86865C14.1664 8.98137 14.3114 9.0164 14.4209 8.94693L15.6796 8.22199C15.8892 8.10327 15.7943 7.78274 15.5543 7.79756Z"
      fill="currentColor"
    />
    <path
      d="M13.7474 6.00393C13.6725 6.01034 13.6065 6.05418 13.569 6.11865L12.8423 7.37193C12.7796 7.48044 12.8163 7.61898 12.9258 7.68244C13.0341 7.74531 13.1726 7.70767 13.2353 7.59936L13.9618 6.34588C14.0581 6.18652 13.9318 5.98631 13.7474 6.00393Z"
      fill="currentColor"
    />
    <path
      d="M7.02795 7.79756C6.80472 7.81438 6.73805 8.11148 6.93405 8.22199L8.19154 8.94693C8.30105 9.01661 8.446 8.98137 8.51086 8.86865C8.57533 8.75594 8.53268 8.61219 8.41797 8.55353L7.16048 7.82879C7.12084 7.80577 7.075 7.79436 7.02795 7.79756Z"
      fill="currentColor"
    />
    <path
      d="M8.836 6.00393C8.66523 6.01134 8.56393 6.19913 8.65162 6.34608L9.37756 7.59956C9.44002 7.70787 9.57876 7.74551 9.68707 7.68264C9.79558 7.61918 9.83322 7.48064 9.77036 7.37213L9.04261 6.11885C9.00097 6.04477 8.92069 5.99993 8.836 6.00393Z"
      fill="currentColor"
    />
    <path
      d="M11.3023 5.34586C11.1772 5.34806 11.0771 5.45137 11.0793 5.5765V7.02377C11.0751 7.1519 11.1772 7.25921 11.3065 7.25921C11.4349 7.25921 11.5378 7.1519 11.5328 7.02377V5.5765C11.5348 5.44816 11.4305 5.34406 11.3023 5.34586Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3742 14.8642H12.2459C12.1147 15.2522 11.7213 15.5096 11.3095 15.5096C10.8987 15.5096 10.5075 15.2522 10.3742 14.8642ZM10.1936 13.9206H12.4208L12.3376 14.4095H10.2805L10.1936 13.9206ZM11.3073 8.16033C12.4138 8.16033 13.4095 8.82261 13.8329 9.84765C14.2563 10.8727 14.0227 12.0445 13.2395 12.8255L12.5972 13.4667H10.0246L9.37394 12.8163C8.59174 12.0363 8.3571 10.8629 8.78053 9.84325C9.20396 8.82361 10.2012 8.16033 11.3073 8.16033ZM11.3073 7.70446C10.0184 7.70446 8.85501 8.48046 8.3617 9.66927C7.8684 10.8579 8.14168 12.2281 9.05301 13.1372L9.70988 13.7941L9.88926 14.8105C10.0124 15.5052 10.6452 15.9629 11.3093 15.9629C11.9746 15.9629 12.6106 15.5074 12.7294 14.8105L12.9027 13.8023L13.5606 13.1474C14.4719 12.2383 14.743 10.8669 14.2509 9.67408C13.7578 8.48166 12.595 7.70446 11.3073 7.70446Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3229 11.2293C10.834 11.2293 10.4222 11.6161 10.4222 12.0998V12.6805C10.4222 12.8069 10.5233 12.908 10.6493 12.908C10.7746 12.908 10.8767 12.8069 10.8767 12.6805V12.0998C10.8767 11.8737 11.0677 11.6827 11.3229 11.6827C11.5784 11.6827 11.7712 11.8735 11.7712 12.0998V12.6805C11.7712 12.8069 11.8723 12.908 11.9986 12.908C12.1238 12.908 12.2261 12.8069 12.2261 12.6805V12.0998C12.2261 11.6161 11.8132 11.2293 11.3229 11.2293Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(Svg01);
export default Memo;
