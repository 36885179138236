import React from "react";
import { v4 as uuid4 } from "uuid";
import { MemberItem, Wrapper } from "./styled";
import IconButton from "../../../../../../../common/components/Buttons/IconButton";
import IconDrag from "../../../../../../../common/components/icons/Fill/drag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const MemberList = ({ title, data = [], onChange, droppableId }) => {
  const handleDragEnded = React.useCallback(
    (param) => {
      if (!data.length) {
        return;
      }

      const { source, destination } = param;

      if (!destination) return;

      let cloneValues = [...data];
      const _item = cloneValues.splice(source.index, 1)[0];
      cloneValues.splice(destination.index, 0, _item);

      onChange && onChange(cloneValues);
    },
    [onChange, data]
  );

  if (!data.length) {
    return null;
  }

  return (
    <Wrapper>
      <div className="list-head">{title}</div>
      <div className="list-body">
        <DragDropContext onDragEnd={handleDragEnded}>
          <Droppable droppableId={droppableId}>
            {(droppableProvided) => {
              return (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className="scroll-wrapper"
                >
                  <>
                    {data.map((d, i) => {
                      return (
                        <Draggable
                          key={`${droppableId}${d?.id}`}
                          draggableId={`${droppableId}${d?.id}`}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            let customProvider = provided;

                            if (snapshot.isDragging) {
                              customProvider = adjustDnDPosition(provided);
                            }

                            return (
                              <div
                                ref={customProvider.innerRef}
                                {...customProvider.draggableProps}
                                {...customProvider.dragHandleProps}
                              >
                                <Item
                                  src={d?.src}
                                  name={d?.name}
                                  designation={d?.designation}
                                  //

                                  className={[
                                    snapshot.isDragging
                                      ? "draggable__item--is-dragging"
                                      : "",
                                  ]
                                    .join(" ")
                                    .trim()}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}

                    {droppableProvided.placeholder}
                  </>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </Wrapper>
  );
};

export default MemberList;

function Item({ src, name, designation, dragHandleProps, ...otherProps }) {
  return (
    <MemberItem
      data={{
        src: src,
        title: name,
        caption: designation,
      }}
      {...otherProps}
    >
      <div className="start">
        <IconButton
          className="drag-handler"
          as="div"
          icon={<IconDrag />}
          btnIconSize={12}
          btnSize={20}
          // {...dragHandleProps}
        />
        <MemberItem.Avatar size="size-36" />
      </div>

      <div className="center">
        <MemberItem.Title />
        <MemberItem.Caption />
      </div>
    </MemberItem>
  );
}

// helpers
function adjustDnDPosition(provided) {
  provided.draggableProps.style.left = provided.draggableProps.style.offsetLeft;
  provided.draggableProps.style.top = provided.draggableProps.style.offsetTop;

  return provided;
}
